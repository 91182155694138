<template>
  <div style="display: flex; flex-direction: column">
    <DeviceList />
  </div>
</template>

<script>
import DeviceList from "./device/DeviceList";
export default {
  name: "Device",
  components: { DeviceList },
};
</script>

<style scoped></style>
