<template>
  <div class="mt-5">
    <h5 class="mb-5">{{ $ml.get("setting.connection") }}</h5>
    <v-row>
      <v-col :cols="12" :md="6">
        <v-text-field
          v-model="syncServer"
          :label="$ml.get('setting.sync.syncServer')"
          outlined
          hide-details
          :disabled="syncEnabled"
        />
      </v-col>
      <v-col :cols="12" :md="6">
        <v-text-field
          v-model="syncPort"
          type="number"
          :label="$ml.get('setting.sync.syncPort')"
          outlined
          hide-details
          :disabled="syncEnabled"
        />
      </v-col>
      <v-col :cols="12">
        <v-text-field
          v-model="syncKey"
          type="password"
          :label="$ml.get('setting.sync.syncToken')"
          outlined
          autocomplete="off"
          hide-details
          :disabled="syncEnabled"
        />
      </v-col>
    </v-row>

    <v-row v-if="scudList.length">
      <v-col>
        <v-select
          v-model="syncName"
          :items="scudList"
          outlined
          hide-details
          :disabled="syncEnabled"
          @change="integrationStore"
        />
      </v-col>
    </v-row>

    <v-row v-if="syncSettings.length">
      <v-col>
        <h5 class="mb-5">{{ $ml.get("setting.sync.remoteserver") }}</h5>
        <div v-for="(sett, index) in syncSettings" :key="index">
          <v-text-field
            :disabled="syncEnabled"
            v-if="
              sett.type == 'text' ||
              sett.type == 'number' ||
              sett.type == 'password'
            "
            v-model.lazy="sett.value"
            :label="sett.title"
            outlined
            :type="sett.type"
            @change="integrationStore"
          />
          <v-select
            v-if="sett.type == 'select'"
            :disabled="syncEnabled"
            :items="sett.selectlist"
            v-model="sett.value"
            outlined
            multiple
            chips
            :label="sett.title"
            @change="integrationStore"
          />
          <v-select
            v-if="sett.type == 'multiselect'"
            :disabled="syncEnabled"
            :items="sett.selectlist"
            v-model="sett.selected"
            outlined
            multiple
            chips
            :label="sett.title"
            @change="integrationStore"
          />
          <v-switch
            :disabled="syncEnabled"
            v-if="sett.type == 'bool'"
            v-model.lazy="sett.checked"
            :label="sett.title"
            @change="integrationStore"
          />
        </div>
      </v-col>
    </v-row>

    <v-row v-if="(syncId && syncEnabled) || log">
      <v-col>
        <v-textarea rows="15" v-model="log" readonly />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="syncId && !syncEnabled" cols="auto">
        <v-btn @click="clearSync">
          {{ $ml.get("button.clear") }}
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col v-if="syncId && !syncEnabled" cols="auto">
        <v-btn color="success" @click="startSync">
          {{ $ml.get("button.start") }}
        </v-btn>
      </v-col>
      <v-col v-if="syncId && syncEnabled" cols="auto">
        <v-btn color="error" @click="stopSync">
          {{ $ml.get("button.stop") }}
        </v-btn>
      </v-col>
      <v-col v-if="!scudList.length" cols="auto">
        <v-btn color="primary" :disabled="syncEnabled" @click="integrationList">
          {{ $ml.get("setting.sync.getList") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isIntegrationsPending: false,
      isIntegrationsSuccess: false,
      isIntegrationsFailure: false,
      isIntegrationListPending: false,
      isIntegrationListSuccess: false,
      isIntegrationListFailure: false,
      isIntegrationStorePending: false,
      isIntegrationStoreSuccess: false,
      isIntegrationStoreFailure: false,

      syncId: null,
      syncServer: "http://127.0.0.1",
      syncKey: "SPBOVISION",
      syncPort: 6002,
      syncEnabled: false,
      syncSettings: [],

      syncName: "",
      scudList: [],

      log: "",
    };
  },

  methods: {
    checkExternal(item) {
      this.logLength(14);
      if (!item.success) this.log += item.message + "\n";
    },
    setLog(item) {
      this.logLength(14);
      let status = item.success ? "Success" : "Error";
      if (item.success) this.log += status + ": " + item.message + "\n";
      else this.log += "Error Add: " + item.message + "\n";
    },
    addUser(item) {
      this.logLength(14);
      if (item.success) this.log += "Success Add: " + item.message + "\n";
      else this.log += "Error Add: " + item.message + "\n";
    },
    setUser(item) {
      this.logLength(14);
      if (item.success) this.log += "Success Update: " + item.message + "\n";
      else this.log += "Error Update: " + item.message + "\n";
    },
    delUser(item) {
      this.logLength(14);
      if (item.success) this.log += "Success Delete: " + item.message + "\n";
      else this.log += "Error Delete: " + item.message + "\n";
    },
    logLength(l) {
      if (this.log) {
        let ll = this.log.split("\n");
        if (ll.length > l) {
          ll.splice(0, ll.length - l);
          this.log = ll.join("\n");
        }
      }
    },

    getIntegration() {
      this.isIntegrationsPending = true;
      this.isIntegrationsSuccess = false;
      this.isIntegrationsFailure = false;

      return this.axios("integrations")
        .then((response) => {
          this.isIntegrationsPending = false;
          this.isIntegrationsSuccess = true;
          this.isIntegrationsFailure = false;

          const servers = response.data.data;

          if (servers.length) {
            const server = servers[0];

            this.syncId = server.id;
            this.syncName = server.name;
            this.syncKey = server.key;
            this.syncServer = server.server;
            this.syncPort = server.port;
            this.syncEnabled = server.enable;
            if (server.data) {
              this.syncSettings = JSON.parse(server.data);
            }
          }
        })
        .catch(() => {
          this.isIntegrationsPending = false;
          this.isIntegrationsSuccess = false;
          this.isIntegrationsFailure = true;
        });
    },

    integrationList() {
      this.isIntegrationListPending = true;
      this.isIntegrationListSuccess = false;
      this.isIntegrationListFailure = false;

      const params = {
        host: `${this.syncServer}:${this.syncPort}`,
      };

      return this.axios("integration/list", { params })
        .then((response) => {
          this.isIntegrationListPending = false;
          this.isIntegrationListSuccess = true;
          this.isIntegrationListFailure = false;

          this.scudList = response.data.data;
        })
        .catch(() => {
          this.isIntegrationListPending = false;
          this.isIntegrationListSuccess = false;
          this.isIntegrationListFailure = true;

          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("message.errorLoading"),
          });
        });
    },

    integrationStore() {
      this.isIntegrationStorePending = true;
      this.isIntegrationStoreSuccess = false;
      this.isIntegrationStoreFailure = false;

      let url = "integration/add";
      let data = JSON.stringify(this.syncSettings);

      const values = {
        name: this.syncName,
        server: this.syncServer,
        port: parseInt(this.syncPort),
        key: this.syncKey,
        enable: this.syncEnabled,
        data,
      };

      if (this.syncId) {
        values.id = this.syncId;
        url = `integration/${this.syncId}`;
      }

      return this.axios
        .put(url, values)
        .then(() => {
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("message.saveSuccess"),
          });

          this.getIntegration();
        })
        .catch(() => {
          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("message.notServerConnect"),
          });
        });
    },

    async startSync() {
      await this.integrationStore();

      this.log = "";

      return this.axios(`integration/${this.syncId}/start`).then(() => {
        this.$notify({
          group: "info",
          type: "success",
          text: this.$ml.get("setting.sync.start"),
        });

        this.getIntegration();
      });
    },

    stopSync() {
      this.axios(`integration/${this.syncId}/stop`).then(() => {
        this.$notify({
          group: "info",
          type: "success",
          text: this.$ml.get("setting.sync.stop"),
        });

        this.getIntegration();
      });
    },

    clearSync() {
      this.log = "";

      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>` +
            this.$ml.get("message.confirmDelete"),
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(() => {
          this.axios.delete(`integration/${this.syncId}`).then(() => {
            this.$notify({
              group: "info",
              type: "success",
              text: this.$ml.get("message.deleteSuccess"),
            });

            this.syncId = 0;
            this.syncSettings = [];
            this.syncName = "";
            this.scudList = [];

            this.getIntegration();
          });
        });
    },

    wsListenerOn() {
      this.$root.$on("checkexternal", this.checkExternal);
      this.$root.$on("setlog", this.setLog);
      this.$root.$on("adduser", this.addUser);
      this.$root.$on("setuser", this.setUser);
      this.$root.$on("deluser", this.delUser);
    },

    wsListenerOff() {
      this.$root.$off("checkexternal");
      this.$root.$off("setlog");
      this.$root.$off("adduser");
      this.$root.$off("setuser");
      this.$root.$off("deluser");
    },
  },

  async created() {
    this.wsListenerOn();

    await this.getIntegration();

    if (this.syncId) {
      await this.integrationList();
    }
  },

  destroyed() {
    this.wsListenerOff();
  },
};
</script>
