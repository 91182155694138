<template>
  <v-app class="p-3">
    <div class="add-new-visitor-form-container">
      <div class="top-button-box">
        <v-btn :to="{ name: 'addGuard' }" color="primary">{{
          $ml.get("guard.addGuard")
        }}</v-btn>
      </div>
      <datatable
        :fields="fields"
        :rows="guards.data"
        :rowstyle="styleRow"
        :dblclick="showGuard"
        :searchfn="searchfn"
        :searchParams="searchParams"
        saveSearchHistory="guards"
        :searchDisabled="isPending"
        :isLoading="isPending"
      >
        {{ $ml.get("users.notUsers") }}
        <template v-slot:header></template>

        <template #[`item.avatar`]="{ item }">
          <Avatar :src="item.avatar" :type="item.type_user" />
        </template>

        <template v-slot:footer>
          <vue-pagination
            :pagination="guards.pagination"
            :offset="offset"
            @paginate="onChangePage"
            :isDisabled="isPending"
          />
        </template>
      </datatable>
    </div>
  </v-app>
</template>

<script>
import Datatable from "../components/datatable";
import VuePagination from "../components/pagination";
import Avatar from "@/components/Avatar";

export default {
  name: "Guards",
  data() {
    const { page } = this.$route.query;

    return {
      isPending: false,
      fields: [
        { name: "avatar", title: "", style: "width: 50px;", type: "img" },
        { name: "name", header: { text: this.$ml.get("users.fullname") } },
        {
          name: "username",
          header: { text: this.$ml.get("users.username") },
          style: "width: 200px",
        },
        {
          name: "type_user",
          header: { text: this.$ml.get("users.userType") },
          style: "width: 100px",
        },
        { name: "id", header: { text: "ID" }, style: "width: 50px;" },
      ],
      rows: [],
      guards: {
        pagination: {
          total: 0,
          per_page: 2,
          to: 0,
          current_page: page || 1,
        },
        data: [],
      },
      offset: 2,
      search: "",
      searchParams: [
        {
          value: "id",
          text: "ID",
        },
        {
          value: "name",
          text: this.$ml.get("users.fullname"),
        },
        {
          value: "login",
          text: this.$ml.get("users.username"),
        },
      ],
      user: false,
    };
  },
  components: { Datatable, VuePagination, Avatar },
  methods: {
    styleRow(item) {
      if (item["type_user"] == "admin") {
        return "background: rgba(165, 159, 187, 0.24); color: #000;";
      }
    },
    showGuard(item) {
      this.$router.push("/guard/" + item.id);
    },
    getGuards() {
      this.isPending = true;
      let values = {
        page: this.guards.pagination.current_page,
        search: this.search,
      };

      this.axios
        .get("/guards", { params: values })
        .then((response) => {
          this.guards.data = response.data.data ? response.data.data : [];
          this.guards.pagination = response.data["pagination"] || {};
          this.guards.data.forEach((item) => {
            if (item.photo) {
              item.avatar = "/avatar/small/" + item.photo;
            } else {
              item.avatar = "/avatar/small/avatar.png";
            }
          });
          this.isPending = false;
        })
        .catch(() => {
          this.isPending = false;
        });
    },

    onChangePage() {
      this.getGuards();
    },

    searchfn(value) {
      this.search = value;
      this.guards.pagination.current_page = 1;
      this.getGuards();
    },
  },
  mounted() {
    this.getGuards();
  },
};
</script>

<style scoped>
.nav-tabs .nav-link {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.tab-content {
  padding: 15px;
  border-left: solid 1px #dee2e6;
  border-right: solid 1px #dee2e6;
  border-bottom: solid 1px #dee2e6;
  margin-bottom: 20px;
  /*box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);*/
}
.top-button-box {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  flex-flow: row-reverse;
}
</style>
