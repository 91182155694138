<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <!-- TODO: вернуть поле в версии 1.5.2 -->
        <!-- <v-col v-if="!isEdit" cols="12" md="6">
          <v-text-field
            v-model="pass"
            :label="`${$ml.get('devices.pass')} *`"
            type="password"
            outlined
            required
            :rules="isRequiredRules"
            @change="inputValue"
          />
        </v-col> -->

        <v-col cols="12">
          <v-select
            v-model="doorsNumber"
            :items="doorsNumberOptions"
            :label="$ml.get('devices.doorsNumber')"
            outlined
            hide-details
            @change="inputValueDoor"
          />
        </v-col>

        <v-col
          v-if="isEdit"
          cols="12"
          md="6"
          class="d-flex align-center justify-center"
        >
          <v-btn @click="openNetworkSettings">
            <v-icon left> mdi-ip-network </v-icon>
            {{ $ml.get("devices.changeDeviceNetworkSettings") }}
          </v-btn>
        </v-col>

        <template v-if="!isEdit">
          <v-col cols="12" md="4">
            <v-text-field
              :value="macCode.toUpperCase()"
              :label="$ml.get('devices.macCode')"
              v-mask="'NN:NN:NN:NN:NN:NN'"
              outlined
              required
              @change="inputValue"
              @input="macCode = $event.toUpperCase()"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="mask"
              :label="`${$ml.get('devices.mask')} *`"
              outlined
              required
              :rules="isRequiredRules"
              @change="inputValue"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="gateway"
              :label="`${$ml.get('devices.gateway')} *`"
              outlined
              required
              :rules="isRequiredRules"
              @change="inputValue"
            />
          </v-col>
        </template>
        <v-col>
          <v-switch
            v-model="antipassback"
            :label="$ml.get('setting.anti-passback')"
            @change="inputValue"
          />
        </v-col>
      </v-row>

      <v-row class="mb-5">
        <v-col
          v-for="(channel, index) of channels"
          :key="channel.channel"
          cols="12"
          md="6"
        >
          <Channel
            :title="getChannelTitle(index)"
            :channel="channel"
            :relays="
              index === 0 || index === 1
                ? firstRelaysOptions
                : secondRelaysOptions
            "
            isShowName
            isController="true"
            :isShowRelays="doorsNumber === 2"
            @change="updateChannels($event, index)"
          />
        </v-col>
      </v-row>

      <v-tabs v-model="settingTab" background-color="grey lighten-3 mb-5">
        <v-tab>{{ $ml.get("devices.readerSettings") }}</v-tab>
        <v-tab>{{ $ml.get("devices.relay") }}</v-tab>
        <v-tab>{{ $ml.get("devices.alarmSettings") }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="settingTab">
        <!-- Reader -->
        <v-tab-item>
          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="passSensor"
                :label="$ml.get('devices.passSensor')"
                @change="inputValue"
              />
            </v-col>
            <v-col cols="12" :md="cardType === 'CUST' ? 3 : 6">
              <v-select
                v-model="cardType"
                :items="cardTypes"
                item-text="name"
                item-value="val"
                :label="$ml.get('card.type')"
                @change="changeWiegand"
                outlined
              />
            </v-col>
            <v-col v-show="cardType === 'CUST'" cols="12" md="9">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.number="numberOfWiegandBits"
                    :label="`${$ml.get('devices.numberOfWiegandBits')} *`"
                    type="number"
                    outlined
                    required
                    @change="changeWiegand"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.number="validWiegandBits"
                    :label="`${$ml.get('devices.validWiegandBits')} *`"
                    type="number"
                    :max="numberOfWiegandBits"
                    outlined
                    required
                    :disabled="!numberOfWiegandBits"
                    @change="changeWiegand"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.number="parityWiegandBits1"
                    :label="`${$ml.get('devices.parityWiegandBit')} 1`"
                    :max="numberOfWiegandBits"
                    type="number"
                    outlined
                    required
                    :disabled="!validWiegandBits"
                    @change="changeWiegand"
                  />
                  <v-text-field
                    v-model.number="parityWiegandBits2"
                    :label="`${$ml.get('devices.parityWiegandBit')} 2`"
                    :max="numberOfWiegandBits"
                    type="number"
                    outlined
                    required
                    :disabled="!validWiegandBits"
                    @change="changeWiegand"
                  />
                  <v-text-field
                    v-model.number="parityWiegandBits3"
                    :label="`${$ml.get('devices.parityWiegandBit')} 3`"
                    :max="numberOfWiegandBits"
                    type="number"
                    outlined
                    required
                    :disabled="!validWiegandBits"
                    @change="changeWiegand"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>

        <!-- Relay -->
        <v-tab-item>
          <v-row>
            <v-col
              v-for="(relay, index) of relays"
              :key="relay.id"
              cols="12"
              :md="4"
            >
              <Relay :relay="relay" @change="updateRelays($event, index)" />
            </v-col>
          </v-row>
        </v-tab-item>

        <!-- Alarm -->
        <v-tab-item>
          <Alarm
            :relays="relays"
            :alarmPins="alarmPins"
            :alarmPinsRelayDependence="alarmPinsRelayDependence"
            @change="updateAlarm"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-col>

    <v-col v-if="isWiegandChange" cols="12">
      <v-alert type="warning">
        {{ $ml.get("devices.isWiegandChangeAlert") }}
      </v-alert>
    </v-col>

    <v-row justify="center">
      <v-dialog
        v-if="isShowNetworkSettings"
        :value="true"
        max-width="700px"
        @click:outside="closeNetworkSettings"
      >
        <ModalNetworkSettings
          :id="id"
          :ipAddress="ipAddress"
          :macCode="macCode"
          :mask="mask"
          :gateway="gateway"
          @close="closeNetworkSettings"
          @submit="onSubmitNetworkSettings"
        />
      </v-dialog>
    </v-row>
  </v-row>
</template>

<script>
import Channel from "../Channel";
import Relay from "./Relay";
import Alarm from "./Alarm";
import ModalNetworkSettings from "./ModalNetworkSettings";

export default {
  components: {
    Channel,
    Relay,
    Alarm,
    ModalNetworkSettings,
  },

  props: {
    value: Object,

    ipAddress: {
      type: String,
      required: true,
    },

    id: {
      type: [String, Number],
      required: true,
    },

    channels: {
      type: Array,
      default: () => [],
    },

    isEdit: {
      type: Boolean,
      required: true,
    },

    zones: {
      type: Array,
      default: () => [],
    },

    zonesFrom: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    let parityWiegandBits = [];

    if (
      !this.value?.config?.parity_wiegand_bits ||
      !this.value?.config?.parity_wiegand_bits.length
    ) {
      parityWiegandBits = [0, 0, 0];
    } else {
      parityWiegandBits = this.value.config.parity_wiegand_bits;
    }

    return {
      settingTab: null,

      isShowNetworkSettings: false,

      // TODO: сделать поле пустым по дефолту в версии 1.5.2
      pass: "123456",
      macCode: this.value?.mac_code || "",
      mask: this.value?.mask || "255.255.255.0",
      gateway: this.value?.gateway || "",
      doorsNumber: this.value?.doors_number || 4,
      antipassback: this.value?.anti_passback || false,

      relays: this.value?.relays || [
        {
          id: 1,
          type_alarm_input: "open",
          sync_relay_state: true,
          alarm_relay_delay: 1,
          open_relay_delay: 3,
        },
        {
          id: 2,
          type_alarm_input: "open",
          sync_relay_state: true,
          alarm_relay_delay: 1,
          open_relay_delay: 3,
        },
        {
          id: 3,
          type_alarm_input: "open",
          sync_relay_state: true,
          alarm_relay_delay: 1,
          open_relay_delay: 3,
        },
        {
          id: 4,
          type_alarm_input: "open",
          sync_relay_state: true,
          alarm_relay_delay: 1,
          open_relay_delay: 3,
        },
        {
          id: 5,
          type_alarm_input: "open",
          sync_relay_state: true,
          alarm_relay_delay: 1,
          open_relay_delay: 3,
        },
      ],

      alarmPins: this.value?.alarm_pins || [
        {
          id: 1,
          name: "Pin 1",
        },
        {
          id: 2,
          name: "Pin 2",
        },
        {
          id: 3,
          name: "Pin 3",
        },
        {
          id: 4,
          name: "Pin 4",
        },
      ],

      alarmPinsRelayDependence: this.value?.alarm_pins_relay_dependence || [
        {
          alarm_id: 1,
          relays: [1, 2, 3, 4, 5],
        },
        {
          alarm_id: 2,
          relays: [],
        },
        {
          alarm_id: 3,
          relays: [],
        },
        {
          alarm_id: 4,
          relays: [],
        },
      ],

      // Reader
      isWiegandChange: false,
      cardType: this.value?.config?.card_type || "WDEC26",
      numberOfWiegandBits: this.value?.config?.number_of_wiegand_bits || null,
      validWiegandBits: this.value?.config?.valid_wiegand_bits || null,
      parityWiegandBits1: parityWiegandBits[0] || 0,
      parityWiegandBits2: parityWiegandBits[1] || 0,
      parityWiegandBits3: parityWiegandBits[2] || 0,
      passSensor: this.value?.config?.pass_sensor || false,

      cardTypes: [
        { name: "Wiegand26", val: "WDEC26" },
        { name: "Wiegand34", val: "WDEC34" },
        { name: this.$ml.get("devices.custom"), val: "CUST" },
      ],

      doorsNumberOptions: [
        { text: this.$ml.get("devices.twoDoors"), value: 2 },
        { text: this.$ml.get("devices.fourDoors"), value: 4 },
      ],

      // Rules
      isRequiredRules: [(v) => !!v || this.$ml.get("message.NameIsRequired")],
    };
  },

  methods: {
    changeWiegand() {
      this.isWiegandChange = true;

      this.inputValue();
    },

    inputValueDoor() {
      this.channels.forEach((_, i) => {
        console.log(this.doorsNumber);
        if (this.doorsNumber == 2) {
          this.channels[i].relay_id = Math.floor(i / 2) + 1;
        } else {
          this.channels[i].relay_id = i + 1;
        }
      });
      this.inputValue();
    },
    inputValue() {
      const {
        pass,
        macCode,
        mask,
        gateway,
        antipassback,
        cardType,
        numberOfWiegandBits,
        validWiegandBits,
        parityWiegandBits1,
        parityWiegandBits2,
        parityWiegandBits3,
        passSensor,
        relays,
        alarmPins,
        alarmPinsRelayDependence,
        doorsNumber,
      } = this;

      const data = {
        pass,
        mac_code: macCode,
        mask,
        gateway,
        device_type: "CONTROLLER",
        config: {
          card_type: cardType,
          number_of_wiegand_bits: numberOfWiegandBits,
          valid_wiegand_bits: validWiegandBits,
          parity_wiegand_bits: [
            parityWiegandBits1,
            parityWiegandBits2,
            parityWiegandBits3,
          ],
          pass_sensor: passSensor,
        },
        relays,
        alarm_pins: alarmPins,
        alarm_pins_relay_dependence: alarmPinsRelayDependence,
        doors_number: doorsNumber,
        anti_passback: antipassback,
      };

      this.$emit("input", data);
    },

    updateChannels(e, index) {
      const channels = this.channels;
      channels[index] = e;

      // Sync relay id by channel id
      switch (e.channel) {
        case 0:
        case 1:
          channels[0].relay_id = e.relay_id;
          channels[1].relay_id = e.relay_id;
          break;
        case 2:
        case 3:
          channels[2].relay_id = e.relay_id;
          channels[3].relay_id = e.relay_id;
          break;
        default:
          break;
      }

      this.$emit("change-channels", channels);
    },

    updateRelays(e, index) {
      this.relays[index] = e;

      this.inputValue();
    },

    updateAlarm({ alarmPinsRelayDependence, alarmPins }) {
      this.alarmPinsRelayDependence = alarmPinsRelayDependence;
      this.alarmPins = alarmPins;

      this.inputValue();
    },

    openNetworkSettings() {
      this.isShowNetworkSettings = true;
    },

    closeNetworkSettings() {
      this.isShowNetworkSettings = false;
    },

    onSubmitNetworkSettings({ ipAddress, macCode, mask, gateway }) {
      this.ipAddress = ipAddress;
      this.macCode = macCode;
      this.mask = mask;
      this.gateway = gateway;

      this.$emit("ip", ipAddress);
      this.closeNetworkSettings();
      this.inputValue();
    },
    getChannelTitle(index) {
      let title = `${this.$ml.get("devices.door")} ${
        index + 1
      } / ${this.$ml.get("devices.readerSettings")} ${index + 1}`;

      if (this.doorsNumber === 2) {
        let doorNumber = 1;
        //let readerNumber = 1;
        if (index === 2 || index === 3) {
          doorNumber = 2;
        }
        // if (index % 2) {
        //   readerNumber = 2;
        // }

        title = `${this.$ml.get("devices.door")} ${doorNumber} / ${this.$ml.get(
          "devices.readerSettings"
        )} ${index + 1}`;
      }

      return title;
    },

    setGateway(ip) {
      if (!this.isEdit) {
        let g = this.gateway.split(".");
        let f = false;
        if (g.length < 4) {
          for (let i = g.length; i < 4; i++) {
            g.push("0");
            f = true;
          }
        }
        ip.split(".").forEach((item, index) => {
          if (g.length > 3 && g[index] !== item && index < 4) {
            g[index] = item;
            if (index === 3) {
              g[index] = "1";
            }
            f = true;
          }
        });
        if (f) {
          this.gateway = g.join(".");
          this.inputValue();
        }
      }
    },
  },

  computed: {
    firstRelaysOptions() {
      return this.relays.filter(
        (relay) => relay.id !== this.channels[2].relay_id
      );
    },
    secondRelaysOptions() {
      return this.relays.filter(
        (relay) => relay.id !== this.channels[0].relay_id
      );
    },
  },

  watch: {
    ipAddress(ip) {
      this.setGateway(ip);
    },
  },

  created() {
    this.setGateway(this.ipAddress);
    this.inputValue();
  },
};
</script>
