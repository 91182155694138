require("./bootstrap");

import Vue from "vue";
import store from "./store";
import App from "./App.vue";

// Plugins
import vuetify from "@/plugins/vuetify";
import "@/plugins/vuetify-tel-input";
import "@/plugins/vue-notification";
import "@/plugins/vuejs-dialog";
import "@/plugins/ml";
import "@/plugins/vue-flash-message";
import "@/plugins/vue-moment";
import "@/plugins/vue-clipboard";
import "@/plugins/portal-vue";
import "@/plugins/vmask";
import "@/plugins/datetimepicker";
import "@/plugins/auth";
import "@/plugins/vue-timers";

// Router
import router from "./router";

// PWA
import "@/registerServiceWorker";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
