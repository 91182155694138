<template>
  <div class="modal-background" style="display: block" @click.self="close">
    <div class="take-photo">
      <a href="#" class="modal-close" @click="close"></a>
      <div>
        <div v-if="isLoadingStream" class="spinner">
          <img width="200" height="200" src="~@/assets/img/spinner-200.gif" />
        </div>

        <div v-else class="mjpeg">
          <img
            :src="`${camera.url}/mjpeg?ver=${this.$moment().unix()}`"
            ref="stream"
          />
        </div>
        <flash-message class="faceImageMessage"></flash-message>
        <div class="target-box"></div>
      </div>
      <div class="controls" v-if="showcontrol">
        <div class="row" v-if="cameraList.length">
          <template v-if="cameraList.length > 1">
            <div class="col-6">
              <v-select
                v-model="select"
                @change="selectStream"
                :items="cameraList"
                item-text="name"
                item-value="id"
                :label="$ml.get('imageBox.camera_for_reg')"
                return-object
                outlined
              ></v-select>
            </div>

            <div v-if="!loader" class="col-6">
              <v-btn
                large
                color="primary"
                class="setphoto"
                @click="takePhoto"
                >{{ $ml.get("button.makePhoto") }}</v-btn
              >
            </div>
          </template>
          <template v-else>
            <div v-if="!loader" class="col-12">
              <v-btn
                large
                color="primary"
                class="setphoto-one"
                @click="takePhoto"
                >{{ $ml.get("button.makePhoto") }}</v-btn
              >
            </div>
          </template>

          <!--a href="#" class="transparent-link-button retake-photo-button col-6">Переснять</a-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
require("vue-flash-message/dist/vue-flash-message.min.css");
import renderTargetBox from "../plugins/TargetBox";
import { getImage } from "@/plugins/ImageMjpeg.js";

export default {
  props: ["showing", "devices", "showcontrol"],
  data() {
    return {
      select: {},
      cameras: [],
      cameraList: [],
      loader: true,
      message: "",
      heightDiff: null,
      canvasObject: null,
      camera: {
        url: "",
      },
      isLoadingStream: false,
      isSuccessCameraImage: false,
    };
  },
  methods: {
    selectStream() {
      this.loader = true;
      let _this = this;
      this.camera = {};
      setTimeout(function () {
        _this.camera = _this.select;
        _this.loader = false;
      }, 0);
    },
    takePhoto() {
      if (!this.camera) return;
      this.axios
        .get(this.camera.url + "/v2/get_features_and_thumbnails")
        .then((response) => {
          if (response.status == 200) {
            if (response.data.image) {
              this.$emit("toggle", false);
              this.$emit(
                "image",
                "data:image/png;base64," + response.data.image
              );
            } else {
              if (response.data.error == "no face of image") {
                this.message = this.$ml.get("imageBox.nofaceofimage");
              } else {
                this.message = response.data.error;
              }
              this.flashInfo(this.message, { timeout: 1000 });
            }
          }
        })
        .catch((response) => {
          this.message = this.$ml.get("imageBox.noactive");
          this.flashError(this.message, { timeout: 1000 });
        });
    },
    close() {
      this.$emit("toggle", false);
    },
    getTargetData(ratio, data, sm) {
      //data.y1 += sm / ratio;
      data.y2 -= (sm * 2) / ratio;
      const x1 = parseInt(data.x1 * ratio);
      const x2 = parseInt(data.x2 * ratio);
      const y1 = parseInt(data.y1 * ratio);
      const y2 = parseInt(data.y2 * ratio);
      return {
        top: y1,
        left: x1,
        width: x2 - x1,
        height: y2 - y1,
      };
    },

    async getTargetBox() {
      this.isLoadingStream = true;

      if (!this.camera) return;
      this.message = "";

      await getImage(`${this.camera.url}/mjpeg?ver=${this.$moment().unix()}`);

      this.isLoadingStream = false;
      this.isSuccessCameraImage = true;

      this.axios
        .get(this.camera.url + "/get_stream_resolution")
        .then((response) => {
          if (response.status == 200) {
            if (response.data.error === "") {
              this.loader = false;
              let d = response.data;

              // d = {
              //   status: 200,
              //   error: "",
              //   width: 1280,
              //   height: 800,
              //   target_box: { x1: 128, y1: 56, x2: 1152, y2: 744 }
              // };
              let newHeight = d.height;
              let newWidth = d.width;
              let ratio = 1;

              const streamBox = $(".take-photo");
              const mjpeg = $(".mjpeg");
              const wWidth = $(window).width();
              const wHeight = $(window).height();
              const mW = 0.25 * wWidth;
              const mH = 0.1 * wHeight;
              const hD = !this.showcontrol ? -20 : 80;

              if (wHeight < newHeight + hD) {
                newHeight = wHeight - 2 * mH - hD;
                ratio = newHeight / d.height;
                newWidth = newWidth * ratio;
              } else if (wWidth < newWidth) {
                newWidth = wWidth - 2 * mW;
                ratio = newWidth / d.width;
                newHeight = newHeight * ratio;
              }
              const imgHeight = wHeight - 2 * mH - hD;

              let k = 1;
              let sm = 0;
              let ref = this.$refs.stream;
              if (newWidth < 400) {
                mjpeg.width(400).height(newHeight);
                ratio *= 400 / newWidth;
                k = newWidth / newHeight;
                newWidth = 400;
                newHeight = Math.round(newWidth / k);
                sm = (newHeight - imgHeight) / 2;

                ref.style =
                  "margin-top:-" + sm + "px; height: " + imgHeight + "px;";
                streamBox.width(newWidth).height(imgHeight + hD);
              } else {
                mjpeg.width(newWidth).height(newHeight);
                streamBox.width(newWidth).height(newHeight + hD);
              }
              ref.style.width = newWidth + "px";
              ref.style.height = newHeight + "px";
              ref.style.display = "block";
              $(".target-box")
                .show()
                .css(this.getTargetData(ratio, d.target_box, sm));
            }
          }
        });
      // .catch((error) => {
      //   console.dir(error);
      // });
    },

    checkDevice(url) {
      this.axios
        .get(url + "/health_check", { timeout: 1000 })
        .then((response) => {
          if (response.status == 200) {
            return true;
          }
        })
        .catch((response) => {
          return false;
        });
    },
  },

  created() {
    this.showcontrol = this.showcontrol != null ? this.showcontrol : true;
    this.cameraList = [];

    if (this.devices.data) {
      this.devices.data.forEach((el) => {
        if (el.purpose != "off") {
          const url = `http://${el.ip_address}:7777`;
          this.cameraList.push({
            id: el.id,
            name: el.name,
            url,
          });
        }
      });

      if (this.cameraList.length) {
        this.camera = this.cameraList[0];
        this.select = this.camera;
      }
    }

    if (this.camera.url) {
      this.getTargetBox();
    }
  },
};
</script>
<style scoped>
.select-block {
  padding-left: 25px;
  max-width: 300px;
}
.setphoto {
  height: 54px !important;
}
.setphoto-one {
  display: initial;
  float: none;
}
.mjpeg img {
  display: none;
  /* width: 640px;
  height: 480px; */
}
.mjpeg {
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.take-photo {
  z-index: 4 !important;
}
</style>
