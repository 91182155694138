<template>
  <v-app>
    <div class="row">
      <div class="col-md-4" style="float: left; min-width: 200px">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              :class="[
                'nav-link',
                { active: leftSideBarTabSelected === 'subdivisions' },
              ]"
              href="#subdivisions"
              data-toggle="tab"
              @click="leftSideBarTabSelected = 'subdivisions'"
              >{{ $ml.get("users.subdivision") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="[
                'nav-link',
                { active: leftSideBarTabSelected === 'departments' },
              ]"
              href="#departments"
              data-toggle="tab"
              @click="leftSideBarTabSelected = 'departments'"
              >{{ $ml.get("users.departments") }}</a
            >
          </li>
        </ul>

        <div class="tab-content">
          <div
            :class="[
              'tree-group-list',
              'tab-pane',
              'add-new-visitor-form-container',
              {
                'tree-group-list--active':
                  leftSideBarTabSelected === 'subdivisions',
              },
            ]"
            id="subdivisions"
          >
            <div class="panel" v-if="isSdvEdit()">
              <button
                class="btn btn-primary v-icon mdi mdi-shape-circle-plus mr-1"
                @click="addCompany"
                :title="$ml.get('subdiv.addNewSubdivision')"
              />
              <button
                v-if="companyActive.id !== null && companyActive.id > 0"
                class="btn btn-primary v-icon mdi mdi-circle-edit-outline mr-1"
                @click="editCompany"
                :title="$ml.get('subdiv.makeChanges')"
              />
              <button
                v-if="companyActive.id !== null && companyActive.id > 0"
                class="btn btn-danger v-icon mdi mdi-delete-circle-outline"
                @click="deleteCompany"
                :title="$ml.get('subdiv.deleteSelected')"
              />
            </div>
            <v-card class="tree-group-list__card" :disabled="isPending">
              <v-text-field
                v-model="searchSubdivisions"
                :label="$ml.get('datatable.search')"
                flat
                solo
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              />
              <v-divider class="m-0" />

              <div class="tree-group-list__btns">
                <a
                  :class="[
                    'tree-group-list__top-btn',
                    {
                      'tree-group-list__top-btn--active':
                        companyActive.id === null,
                    },
                  ]"
                  @click="
                    selectCompany(
                      {
                        id: null,
                        parent_id: null,
                        name: $ml.get('users.all_subdivisions'),
                      },
                      $event
                    )
                  "
                >
                  <v-icon class="mr-2">mdi-account-check</v-icon>
                  {{ $ml.get("users.all_subdivisions") }}
                </a>
                <a
                  :class="[
                    'tree-group-list__top-btn',
                    {
                      'tree-group-list__top-btn--active':
                        companyActive.id === -1,
                    },
                  ]"
                  @click="
                    selectCompany(
                      {
                        id: -1,
                        parent_id: null,
                        name: $ml.get('users.no_subdivisions'),
                      },
                      $event
                    )
                  "
                >
                  <v-icon class="mr-2">mdi-account-cancel</v-icon>
                  {{ $ml.get("users.no_subdivisions") }}
                </a>
              </div>
              <v-list v-if="treeOpen" class="tree-group-list__tree">
                <v-treeview
                  hoverable
                  :items="tree"
                  return-object
                  :search="searchSubdivisions"
                  :open-all="searchSubdivisions ? true : false"
                >
                  <template
                    slot="label"
                    item-key="id"
                    item-text="name"
                    slot-scope="{ item }"
                  >
                    <a
                      :class="'treeitem ' + setTreeActiveClass(item)"
                      @click="selectCompany(item, $event)"
                      @dblclick="editCompany"
                      style="height: 100%; width: 100%; display: block"
                      >{{ item.name }}</a
                    >
                  </template>
                  <template
                    v-if="item.is_guest"
                    slot="append"
                    item-key="id"
                    item-text="name"
                    slot-scope="{ item }"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="mr-2" small
                          >mdi-account-clock</v-icon
                        >
                      </template>
                      <span>{{ $ml.get("subdiv.guest") }}</span>
                    </v-tooltip>
                  </template>
                </v-treeview>
              </v-list>
            </v-card>
          </div>

          <div
            :class="[
              'tree-group-list',
              'tab-pane',
              'add-new-visitor-form-container',
              {
                'tree-group-list--active':
                  leftSideBarTabSelected === 'departments',
              },
            ]"
            id="departments"
          >
            <div class="panel">
              <button
                v-if="isDepAdd"
                class="btn btn-primary v-icon mdi mdi-shape-circle-plus mr-1"
                @click="addDepartment"
                :title="$ml.get('departments.addNew')"
              ></button>
              <button
                v-if="selectedDepartment?.id && isDepEdit"
                class="btn btn-primary v-icon mdi mdi-circle-edit-outline mr-1"
                @click="editDepartment"
                :title="$ml.get('departments.change')"
              />
              <button
                v-if="selectedDepartment?.id && isDepDel"
                class="btn btn-danger v-icon mdi mdi-delete-circle-outline"
                @click="deleteDepartment"
                :title="$ml.get('departments.delete')"
              />
            </div>

            <v-card class="tree-group-list__card" :disabled="isPending">
              <v-text-field
                v-model="searchDepartments"
                :label="$ml.get('datatable.search')"
                flat
                solo
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              />
              <v-divider class="m-0" />

              <v-list class="department-list tree-group-list__tree">
                <v-treeview
                  return-object
                  open-all
                  hoverable
                  :items="departmentsTree"
                  :search="searchDepartments"
                  @update:active="onSelectDepartment"
                >
                  <template
                    slot="label"
                    item-key="id"
                    item-text="name"
                    slot-scope="{ item }"
                  >
                    <a
                      :class="'treeitem ' + setDepartmentTreeActiveClass(item)"
                      @click="selectDepartment(item, $event)"
                      @dblclick="editDepartment"
                      style="width: 100%; height: 100%; display: block"
                    >
                      {{ item.name }}
                    </a>
                  </template>
                </v-treeview>
              </v-list>
            </v-card>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              :class="'nav-link' + activeBlock('all')"
              href="#users-all"
              data-toggle="tab"
              @click="setTab('all')"
              >{{ $ml.get("users.users") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="'nav-link' + activeBlock('blocks')"
              href="#users-blocked"
              data-toggle="tab"
              @click="setTab('blocks')"
              >{{ $ml.get("users.blocked") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="'nav-link' + activeBlock('blacks')"
              href="#users-blacklisted"
              data-toggle="tab"
              @click="setTab('blacks')"
              >{{ $ml.get("users.blacklist") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="'nav-link' + activeBlock('errors')"
              href="#users-errors"
              data-toggle="tab"
              @click="setTab('errors')"
              >{{ $ml.get("users.errors") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="'nav-link' + activeBlock('deleted')"
              href="#users-deleted"
              data-toggle="tab"
              @click="setTab('deleted')"
              >{{ $ml.get("users.deleted") }}</a
            >
          </li>
        </ul>
        <div class="tab-content">
          <div
            v-if="v === 'all'"
            :class="
              'tab-pane add-new-visitor-form-container' + activeBlock('all')
            "
            id="users-all"
          >
            <v-row>
              <v-col cols="12" md="auto">
                <v-row>
                  <v-col cols="auto">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on">
                          {{ $ml.get("menu.actions") }}&nbsp;
                          <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <template
                          v-if="getSelectCount('all') > 0 && isEditUser()"
                        >
                          <v-list-item link @click="multiSdv('sdv_upd', 'all')">
                            <v-list-item-title>{{
                              $ml.get("menu.setSdv")
                            }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item link @click="multiSdv('sdv_add', 'all')">
                            <v-list-item-title>{{
                              $ml.get("menu.addUserSdv")
                            }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item link @click="multiSdv('sdv_del', 'all')">
                            <v-list-item-title>{{
                              $ml.get("menu.delUserSdv")
                            }}</v-list-item-title>
                          </v-list-item>
                          <v-divider />
                          <v-list-item link @click="multiDpt('all')">
                            <v-list-item-title>{{
                              $ml.get("menu.setDpt")
                            }}</v-list-item-title>
                          </v-list-item>
                          <v-divider />
                          <v-list-item link @click="multiBlock('block', 'all')">
                            <v-list-item-title>{{
                              $ml.get("button.block")
                            }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item link @click="multiBlack('set', 'all')">
                            <v-list-item-title>{{
                              $ml.get("button.add_blacklist")
                            }}</v-list-item-title>
                          </v-list-item>
                        </template>

                        <template
                          v-if="getSelectCount('all') > 0 && isDelUser()"
                        >
                          <v-list-item link @click="multiDelete('all')">
                            <v-list-item-title>{{
                              $ml.get("button.delete")
                            }}</v-list-item-title>
                          </v-list-item>
                        </template>

                        <v-list-item link @click="reportUsers">
                          <v-list-item-title>{{
                            $ml.get("button.reportUsers")
                          }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          link
                          @click="reportUsers({ show_photo: true })"
                        >
                          <v-list-item-title>{{
                            $ml.get("button.reportUsersWithPhoto")
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-col cols="auto">
                    <v-menu offset-y :disabled="isPending">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" :disabled="isPending">
                          {{ $ml.get("users.filter") }} [{{
                            $ml.get("users.filter_" + filter)
                          }}]&nbsp;
                          <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item link @click="setFilter('all')">
                          <v-list-item-title>{{
                            $ml.get("users.all")
                          }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="setFilter('guest')">
                          <v-list-item-title>{{
                            $ml.get("users.guest")
                          }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="setFilter('employee')">
                          <v-list-item-title>{{
                            $ml.get("users.employee")
                          }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="setFilter('manager')">
                          <v-list-item-title>{{
                            $ml.get("users.manager")
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-spacer />
              <v-col v-if="isAddUser()" cols="12" md="auto">
                <v-btn color="primary" :to="{ name: 'adduser' }">
                  {{ $ml.get("users.addUser") }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <datatable
                  ref="datatable"
                  :fields="afields"
                  :rows="users['all'].data"
                  :rowstyle="styleRow"
                  :searchfn="searchfn"
                  :searchParams="searchParams"
                  :dblclick="showUser"
                  :select="true"
                  :itemCount="limit"
                  saveSearchHistory="allUsers"
                  isChangeItemCount
                  @changeItemCount="onChangeItemCount"
                  @sort="onSort($event, 'all')"
                  :searchDisabled="isPending"
                  :isLoading="isPending"
                >
                  {{ $ml.get("users.notUsers") }}
                  <template v-slot:header></template>

                  <template #[`item.avatar`]="{ item }">
                    <Avatar
                      :src="item.avatar"
                      :type="item.type_user_id"
                      :type2="item.use_personal_mode ? 'personal' : null"
                      :type3="item.invite_send_at ? 'sent' : null"
                    />
                  </template>

                  <template #[`item.updated_at`]="{ item }">
                    {{ item.updated_at | moment("DD.MM.YYYY HH:mm") }}
                  </template>

                  <template v-slot:footer>
                    <v-row>
                      <v-col cols="12" md="6">
                        <vue-pagination
                          :pagination="users['all'].pagination"
                          :isSetUrlQuery="false"
                          @paginate="getUsers('all')"
                          :offset="offset"
                          :isDisabled="isPending"
                        />
                      </v-col>

                      <v-spacer />

                      <v-col
                        cols="12"
                        md="3"
                        class="text-right"
                        style="max-width: none"
                      >
                        <small
                          v-show="totalItems"
                          class="blue-grey--text text--lighten-1 text-no-wrap"
                        >
                          {{ `${$ml.get("users.total")}: ${totalItems}` }}
                        </small>
                      </v-col>
                    </v-row>
                  </template>
                </datatable>
              </v-col>
            </v-row>

            <v-app v-if="showAccessDialog">
              <AccessAdd
                v-model="selectUsers[tabSelect]"
                @toggle="closeAccessDialog($event)"
                :action="sdvAction"
              />
            </v-app>

            <v-app v-if="showDepartmentDialog">
              <DepartmentAdd
                v-model="selectUsers[tabSelect]"
                @toggle="closeDepartmentDialog($event)"
              />
            </v-app>
          </div>
          <!--div
            :class="
              'tab-pane add-new-visitor-form-container' +
              activeBlock('employee')
            "
            id="users-employees"
          >
            <datatable
              :fields="fields"
              :rows="users['employee'].data"
              :rowstyle="styleRow"
              :dblclick="showUser"
            >
              {{ $ml.get("users.notUsers") }}
              <template v-slot:header></template>
              <template v-slot:footer>
                <vue-pagination
                  :pagination="users['employee'].pagination"
                  @paginate="getUsers('employee')"
                  :offset="offset"
                ></vue-pagination>
              </template>
            </datatable>
          </div-->
          <!--div
            :class="
              'tab-pane add-new-visitor-form-container' + activeBlock('guest')
            "
            id="users-guests"
          >
            <datatable
              :fields="fields"
              :rows="users['guest'].data"
              :rowstyle="styleRow"
              :dblclick="showUser"
            >
              {{ $ml.get("users.notUsers") }}
              <template v-slot:header></template>
              <template v-slot:footer>
                <vue-pagination
                  :pagination="users['guest'].pagination"
                  @paginate="getUsers('guest')"
                  :offset="offset"
                ></vue-pagination>
              </template>
            </datatable>
          </div-->
          <div
            v-if="v === 'blocks'"
            :class="
              'tab-pane add-new-visitor-form-container' + activeBlock('blocks')
            "
            id="users-blocked"
          >
            <v-row v-if="getSelectCount('blocks') > 0 && isEditUser()">
              <v-col cols="12">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on">
                      {{ $ml.get("menu.actions") }}&nbsp;
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      link
                      @click="multiBlock('unblock', 'blocks', false)"
                    >
                      <v-list-item-title>{{
                        $ml.get("button.unblock")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <datatable
                  ref="datatable"
                  :fields="afields"
                  :rows="users.blocks.data"
                  :dblclick="showUser"
                  :rowstyle="styleRow"
                  :searchfn="searchfn"
                  :searchParams="searchParams"
                  :select="true"
                  :itemCount="limit"
                  isChangeItemCount
                  saveSearchHistory="allUsers"
                  @changeItemCount="onChangeItemCount"
                  @sort="onSort($event, 'blocks')"
                  :searchDisabled="isPending"
                  :isLoading="isPending"
                >
                  {{ $ml.get("users.notUsers") }}
                  <template v-slot:header></template>

                  <template #[`item.avatar`]="{ item }">
                    <Avatar
                      :src="item.avatar"
                      :type="item.type_user_id"
                      :type2="item.use_personal_mode ? 'personal' : null"
                      :type3="item.invite_send_at ? 'sent' : null"
                    />
                  </template>

                  <template #[`item.updated_at`]="{ item }">
                    {{ item.updated_at | moment("DD.MM.YYYY HH:mm") }}
                  </template>

                  <template v-slot:footer>
                    <v-row>
                      <v-col cols="12" md="6">
                        <vue-pagination
                          :pagination="users.blocks.pagination"
                          :isSetUrlQuery="false"
                          @paginate="getUsers('blocks')"
                          :offset="offset"
                          :isDisabled="isPending"
                        />
                      </v-col>

                      <v-spacer />

                      <v-col cols="12" md="3" class="text-right">
                        <small
                          v-show="totalItems"
                          class="blue-grey--text text--lighten-1 text-no-wrap"
                        >
                          {{ `${$ml.get("users.total")}: ${totalItems}` }}
                        </small>
                      </v-col>
                    </v-row>
                  </template>
                </datatable>
              </v-col>
            </v-row>
          </div>
          <div
            v-if="v === 'blacks'"
            :class="
              'tab-pane add-new-visitor-form-container' + activeBlock('blacks')
            "
            id="users-blacklisted"
          >
            <v-row v-if="getSelectCount('blacks') > 0 && isEditUser()">
              <v-col cols="12">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on">
                      {{ $ml.get("menu.actions") }}&nbsp;
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item link @click="multiBlack('unblack', 'blacks')">
                      <v-list-item-title>{{
                        $ml.get("button.remove_blacklist")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <datatable
                  ref="datatable"
                  :fields="afields"
                  :rows="users.blacks.data"
                  :dblclick="showUser"
                  :rowstyle="styleRow"
                  :searchfn="searchfn"
                  :searchParams="searchParams"
                  :select="true"
                  :itemCount="limit"
                  isChangeItemCount
                  saveSearchHistory="allUsers"
                  @changeItemCount="onChangeItemCount"
                  @sort="onSort($event, 'blacks')"
                  :searchDisabled="isPending"
                  :isLoading="isPending"
                >
                  {{ $ml.get("users.notUsers") }}
                  <template v-slot:header></template>

                  <template #[`item.avatar`]="{ item }">
                    <Avatar
                      :src="item.avatar"
                      :type="item.type_user_id"
                      :type2="item.use_personal_mode ? 'personal' : null"
                      :type3="item.invite_send_at ? 'sent' : null"
                    />
                  </template>

                  <template #[`item.updated_at`]="{ item }">
                    {{ item.updated_at | moment("DD.MM.YYYY HH:mm") }}
                  </template>

                  <template v-slot:footer>
                    <v-row>
                      <v-col cols="12" md="6">
                        <vue-pagination
                          :pagination="users.blacks.pagination"
                          :isSetUrlQuery="false"
                          @paginate="getUsers('blacks')"
                          :offset="offset"
                          :isDisabled="isPending"
                        />
                      </v-col>

                      <v-spacer />

                      <v-col cols="12" md="3" class="text-right">
                        <small
                          v-show="totalItems"
                          class="blue-grey--text text--lighten-1 text-no-wrap"
                        >
                          {{ `${$ml.get("users.total")}: ${totalItems}` }}
                        </small>
                      </v-col>
                    </v-row>
                  </template>
                </datatable>
              </v-col>
            </v-row>
          </div>
          <div
            v-if="v === 'deleted'"
            :class="
              'tab-pane add-new-visitor-form-container' + activeBlock('deleted')
            "
            id="users-deleted"
          >
            <v-row>
              <v-col cols="12">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on">
                      {{ $ml.get("menu.actions") }}&nbsp;
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <template
                      v-if="getSelectCount('deleted') > 0 && isDelUser()"
                    >
                      <v-list-item link @click="multiRestore('deleted')">
                        <v-list-item-title>{{
                          $ml.get("button.restore")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="$auth.user().type_user == 'admin'"
                        link
                        @click="multiClear('deleted')"
                      >
                        <v-list-item-title>{{
                          $ml.get("button.clear")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="$auth.user().type_user == 'admin'"
                        link
                        @click="clearAll"
                      >
                        <v-list-item-title>{{
                          $ml.get("button.clearAll")
                        }}</v-list-item-title>
                      </v-list-item>
                    </template>

                    <v-list-item
                      link
                      @click="reportUsers({ mode: 'deleted' }, 'deleted')"
                    >
                      <v-list-item-title>{{
                        $ml.get("button.reportUsers")
                      }}</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      link
                      @click="
                        reportUsers(
                          { show_photo: true, mode: 'deleted' },
                          'deleted'
                        )
                      "
                    >
                      <v-list-item-title>{{
                        $ml.get("button.reportUsersWithPhoto")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <datatable
                  ref="datatable"
                  :fields="afields"
                  :rows="users['deleted'].data"
                  :rowstyle="styleRow"
                  :dblclick="showUser"
                  :select="true"
                  :searchfn="searchfn"
                  :searchParams="searchParams"
                  :itemCount="limit"
                  isChangeItemCount
                  saveSearchHistory="allUsers"
                  @changeItemCount="onChangeItemCount"
                  @sort="onSort($event, 'deleted')"
                  :searchDisabled="isPending"
                  :isLoading="isPending"
                >
                  {{ $ml.get("users.notUsers") }}
                  <template v-slot:header></template>

                  <template #[`item.avatar`]="{ item }">
                    <Avatar
                      :src="item.avatar"
                      :type="item.type_user_id"
                      :type2="item.use_personal_mode ? 'personal' : null"
                      :type3="item.invite_send_at ? 'sent' : null"
                    />
                  </template>

                  <template #[`item.updated_at`]="{ item }">
                    {{ item.updated_at | moment("DD.MM.YYYY HH:mm") }}
                  </template>

                  <template v-slot:footer>
                    <v-row>
                      <v-col cols="12" md="6">
                        <vue-pagination
                          :pagination="users['deleted'].pagination"
                          :isSetUrlQuery="false"
                          @paginate="getUsers('deleted')"
                          :offset="offset"
                          :isDisabled="isPending"
                        />
                      </v-col>

                      <v-spacer />

                      <v-col cols="12" md="3" class="text-right">
                        <small
                          v-show="totalItems"
                          class="blue-grey--text text--lighten-1 text-no-wrap"
                        >
                          {{ `${$ml.get("users.total")}: ${totalItems}` }}
                        </small>
                      </v-col>
                    </v-row>
                  </template>
                </datatable>
              </v-col>
            </v-row>
          </div>
          <div
            v-if="v === 'errors'"
            :class="
              'tab-pane add-new-visitor-form-container' + activeBlock('errors')
            "
            id="users-errors"
          >
            <v-row>
              <v-col cols="12">
                <v-menu offset-y :disabled="isPending">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="isPending"
                    >
                      {{ $ml.get("menu.actions") }}&nbsp;
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <template v-if="getSelectCount('errors') > 0">
                      <v-list-item link @click="multiSdv('sdv_upd', 'errors')">
                        <v-list-item-title>{{
                          $ml.get("menu.setSdv")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="multiSdv('sdv_add', 'errors')">
                        <v-list-item-title>{{
                          $ml.get("menu.addUserSdv")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="multiSdv('sdv_del', 'errors')">
                        <v-list-item-title>{{
                          $ml.get("menu.delUserSdv")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="multiBlock('block', 'errors')">
                        <v-list-item-title>{{
                          $ml.get("button.block")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="multiBlock('unblock', 'errors', false)"
                      >
                        <v-list-item-title>{{
                          $ml.get("button.unblock")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="multiDelete('errors')">
                        <v-list-item-title>{{
                          $ml.get("button.delete")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="multiRefeature('errors')">
                        <v-list-item-title>{{
                          $ml.get("button.refeature")
                        }}</v-list-item-title>
                      </v-list-item>
                    </template>

                    <v-list-item link @click="reportErrors">
                      <v-list-item-title>{{
                        $ml.get("button.reportErrors")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <datatable
                  ref="datatable"
                  :fields="afields"
                  :rows="users['errors'].data"
                  :rowstyle="styleRow"
                  :dblclick="showUser"
                  :searchfn="searchfn"
                  :searchParams="searchParams"
                  :select="true"
                  :itemCount="limit"
                  isChangeItemCount
                  saveSearchHistory="allUsers"
                  @changeItemCount="onChangeItemCount"
                  @sort="onSort($event, 'errors')"
                  :searchDisabled="isPending"
                  :isLoading="isPending"
                >
                  {{ $ml.get("users.notUsers") }}
                  <template v-slot:header></template>

                  <template #[`item.avatar`]="{ item }">
                    <Avatar
                      :src="item.avatar"
                      :type="item.type_user_id"
                      :type2="item.use_personal_mode ? 'personal' : null"
                      :type3="item.invite_send_at ? 'sent' : null"
                    />
                  </template>

                  <template #[`item.updated_at`]="{ item }">
                    {{ item.updated_at | moment("DD.MM.YYYY HH:mm") }}
                  </template>

                  <template v-slot:footer>
                    <v-row>
                      <v-col cols="12" md="6">
                        <vue-pagination
                          :pagination="users['errors'].pagination"
                          :isSetUrlQuery="false"
                          @paginate="getUsers('errors')"
                          :offset="offset"
                          :isDisabled="isPending"
                        />
                      </v-col>

                      <v-spacer />

                      <v-col cols="12" md="3" class="text-right">
                        <small
                          v-show="totalItems"
                          class="blue-grey--text text--lighten-1 text-no-wrap"
                        >
                          {{ `${$ml.get("users.total")}: ${totalItems}` }}
                        </small>
                      </v-col>
                    </v-row>
                  </template>
                </datatable>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <div v-if="companydialog">
        <ModalSubdivisionEdit
          @toggle="dialogClose($event)"
          :companyId="editCompanyItem.id"
          :subdivisions="subdivisions"
          :parent="companyActive"
          :multi="multidialog"
          :ids="ids"
        />
      </div>
      <v-row justify="center">
        <v-dialog
          v-if="isShowModalDepartment"
          persistent
          :value="true"
          max-width="700px"
        >
          <FormDepartment
            :singledata="selectedDepartment"
            :data="treeDepartmentSelect"
            :departments="departments"
            @close="isShowModalDepartment = false"
            @store="onChangeDepartment"
          />
        </v-dialog>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import Datatable from "../components/datatable";
import VuePagination from "../components/pagination";
import ModalSubdivisionEdit from "./users/ModalSubdivisionEdit";
import FormDepartment from "./users/FormDepartment";
import AccessAdd from "./users/ModalAddUsersAccess";
import DepartmentAdd from "./users/ModalAddUsersDepartments";
import moment from "moment-timezone";
import Avatar from "@/components/Avatar";
import { mapState } from "vuex";

const LS_USER_LIMIT = "usersLimit";

export default {
  name: "Users",
  data() {
    const afields = [
      {
        name: "avatar",
        title: "",
        style: "width: 50px; margin-right: 20px;",
        type: "img",
        click: this.showUser,
        header: { style: "width: 65px" },
      },
      {
        group: [
          {
            name: "full_name",
            header: { text: this.$ml.get("users.fullname") },
            click: this.showUser,
            sortBy: {
              name: "full_name",
            },
          },
        ],
        header: { text: this.$ml.get("users.visitor") },
      },
      {
        name: "department",
        header: {
          text: this.$ml.get("users.department"),
          style: "width: 200px",
        },
      },
      {
        name: "type_user",
        header: { text: this.$ml.get("users.userType"), style: "width: 150px" },
      },
      {
        name: "updated_at",
        header: { text: this.$ml.get("users.updatedAt") },
        sortBy: {
          name: "updated_at",
        },
      },
      {
        name: "id",
        header: { text: "ID", style: "width: 60px" },
        sortBy: {
          name: "id",
        },
      },
      {
        name: "ff",
        style: "color: red; font-size: 20px;",
        header: { style: "width: 2px" },
      },
    ];

    if (
      this.$auth.user().type_user == "admin" ||
      this.$auth.user().roles.includes("useredit") ||
      this.$auth.user().roles.includes("userdel")
    ) {
      afields.unshift({
        name: "_select",
        type: "checkbox",
        class: "check-select",
        style: "width: 10px;",
        click: this.select,
        header: {
          class: "check-select",
          type: "checkbox",
          click: this.headerSelect,
          value: "all",
        },
      });
    }

    const limit = localStorage.getItem(LS_USER_LIMIT) || 30;

    return {
      searchSubdivisions: "",
      searchDepartments: "",
      isPending: false,

      sortName: "",
      sortOrder: "",

      ids: [],
      treeOpen: false,
      v: "all",
      mode: { val: "all", title: "Все" },
      totalItems: 0,
      afields,
      fields: [
        {
          name: "avatar",
          title: "",
          style: "width: 50px; margin-left: 33px;",
          type: "img",
          header: { style: "width: 115px" },
        },
        {
          name: "fio",
          header: {
            text: this.$ml.get("users.fullname"),
          },
        },
        {
          name: "type_user",
          header: {
            text: this.$ml.get("users.userType"),
            style: "width: 150px",
          },
        },
        // {
        //   name: "companyName",
        //   header: { text: this.$ml.get("users.subdivision") },
        //   style: "width: 150px;"
        // },
        {
          name: "id",
          header: { text: "ID", style: "width: 70px" },
        },
      ],
      multidialog: false,
      selectUsers: [],
      showAccessDialog: false,
      showDepartmentDialog: false,
      sdvAction: "",
      rows: [],
      users: {
        all: {
          pagination: {
            total: 0,
            per_page: 2,
            from: 1,
            to: 10,
            current_page: 1,
          },
          data: [],
        },
        // employee: {
        //   pagination: {
        //     total: 0,
        //     per_page: 2,
        //     from: 1,
        //     to: 10,
        //     current_page: 1,
        //   },
        //   data: [],
        // },
        // guest: {
        //   pagination: {
        //     total: 0,
        //     per_page: 2,
        //     from: 1,
        //     to: 10,
        //     current_page: 1,
        //   },
        //   data: [],
        // },
        deleted: {
          pagination: {
            total: 0,
            per_page: 2,
            from: 1,
            to: 10,
            current_page: 1,
          },
          data: [],
        },
        blacks: {
          pagination: {
            total: 0,
            per_page: 2,
            from: 1,
            to: 10,
            current_page: 1,
          },
          data: [],
        },
        blocks: {
          pagination: {
            total: 0,
            per_page: 2,
            from: 1,
            to: 10,
            current_page: 1,
          },
          data: [],
        },
        errors: {
          pagination: {
            total: 0,
            per_page: 2,
            from: 1,
            to: 10,
            current_page: 1,
          },
          data: [],
        },
      },
      offset: 2,
      filter: "all",
      search: "",
      limit: parseInt(limit),
      user: false,
      companyActive: {},
      tree: [],
      departmentsTree: [],
      sdv: [],
      subdivisions: [],
      editCompanyItem: {},
      companydialog: false,
      sdivSelect: [],
      treeSelect: [],
      treeDepartmentSelect: {},
      tabSelect: "all",
      leftSideBarTabSelected: "subdivisions",

      departments: [],
      selectedDepartment: {},
      isShowModalDepartment: false,

      searchParams: [
        {
          value: "id",
          text: "ID",
        },
        {
          value: "name",
          text: this.$ml.get("user.name"),
        },
        {
          value: "tel",
          text: this.$ml.get("user.phone"),
        },
        {
          value: "tab",
          text: this.$ml.get("user.tabNum"),
        },
        {
          value: "card",
          text: this.$ml.get("card.numberOrHex"),
        },
      ],
    };
  },
  components: {
    Datatable,
    VuePagination,
    ModalSubdivisionEdit,
    AccessAdd,
    DepartmentAdd,
    FormDepartment,
    Avatar,
  },
  methods: {
    selectMode(item) {
      this.mode = item;
    },
    closeAccessDialog(event) {
      this.showAccessDialog = event;
      this.deselect();
      this.getUsers(this.v);
    },
    closeDepartmentDialog(event) {
      this.showDepartmentDialog = event;
      this.deselect();
      this.getUsers(this.v);
    },
    activeBlock(name) {
      return this.v == name ? " active" : "";
    },
    setTab(tab) {
      this.filter = "all";
      this.search = "";

      localStorage.setItem("terminal.users.tabs", tab);
      localStorage.removeItem("terminal.users." + tab + ".current_page");
      this.users[tab].pagination.current_page = 1;
      if (tab == "all") this.filter = tab;
      this.getUsers(tab);
      this.v = tab;

      this.$refs.datatable.clearSearch();
    },
    select(item, e) {
      item["_select"] = !item["_select"];
      e.target.checked = item["_select"];
    },
    headerSelect(val, e, items) {
      items = items || [];
      items.forEach((item) => {
        item["_select"] = e;
      });
    },
    deselect() {
      this.users[this.v].data.forEach((item) => {
        item["_select"] = false;
      });
    },
    multiSdv(action, tab) {
      this.sdvAction = action;
      this.tabSelect = tab;
      this.showAccessDialog = true;
    },
    multiDpt(tab) {
      this.tabSelect = tab;
      this.showDepartmentDialog = true;
    },
    multiBlock(act, us) {
      let url = "/user/blocked";
      if (act == "unblock") {
        url = "/user/unblocked";
      }
      this.axios.put(url, { ids: this.selectUsers[us] }).then((response) => {
        if (response.status == 200) {
          this.getUsers(this.v);
          this.deselect();

          if (act == "block") {
            this.$notify({
              group: "info",
              type: "success",
              text:
                this.selectUsers[us].length >= 2
                  ? this.$ml.get("message.usersBlockSuccess")
                  : this.$ml.get("message.userBlockSuccess"),
            });
          } else {
            this.$notify({
              group: "info",
              type: "success",
              text:
                this.selectUsers[us].length >= 2
                  ? this.$ml.get("message.usersUnblockSuccess")
                  : this.$ml.get("message.userUnblockSuccess"),
            });
          }
        }
      });
    },
    multiBlack(act, us) {
      let url = "/user/blacklisted";
      if (act == "unblack") {
        url = "/user/unblacklisted";
      }
      this.axios.put(url, { ids: this.selectUsers[us] }).then((response) => {
        if (response.status == 200) {
          this.getUsers(this.v);
          this.deselect();

          if (act === "set") {
            this.$notify({
              group: "info",
              type: "success",
              text:
                this.selectUsers[us].length >= 2
                  ? this.$ml.get("message.usersBlacklistSuccess")
                  : this.$ml.get("message.userBlacklistSuccess"),
            });
          } else {
            this.$notify({
              group: "info",
              type: "success",
              text:
                this.selectUsers[us].length >= 2
                  ? this.$ml.get("message.usersUnblacklistSuccess")
                  : this.$ml.get("message.userUnblacklistSuccess"),
            });
          }
        }
      });
    },
    multiDelete(us) {
      let url = "/user/delete";
      let _this = this;
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>` +
            this.$ml.get("message.confirmDelete"),
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(() => {
          this.axios
            .put(url, { ids: _this.selectUsers[us] })
            .then((response) => {
              if (response.status == 200) {
                _this.getUsers(_this.v);
                _this.deselect();

                this.$notify({
                  group: "info",
                  type: "success",
                  text:
                    _this.selectUsers[us].length >= 2
                      ? this.$ml.get("message.usersDeleteSuccess")
                      : this.$ml.get("message.userDeleteSuccess"),
                });
              }
            });
        });
    },
    multiRefeature(us) {
      let i = 0;
      this.selectUsers[us].forEach((it) => {
        this.axios
          .put("/user/" + it + "/refeature")
          .then((response) => {
            i++;
            if (i >= this.selectUsers[us].length) {
              this.getUsers(this.v);
              this.deselect();
              this.$notify({
                group: "info",
                type: "success",
                text: this.$ml.get("message.refeature_success"),
              });
            }
          })
          .catch((err) => {
            i++;
            if (i >= this.selectUsers[us].length) {
              this.getUsers(this.v);
              this.deselect();
              this.$notify({
                group: "info",
                type: "error",
                text: err.response.data.message,
              });
            }
          });
        // if (i >= this.selectUsers[us].length) {
        //   this.getUsers(this.v);
        //   this.deselect();
        // }
      });
    },
    clearAll() {
      let url = "/users/trash";
      let _this = this;
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>` +
            this.$ml.get("message.confirmDelete"),
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(() => {
          this.axios.delete(url).then((response) => {
            if (response.status == 200) {
              _this.getUsers("deleted");

              this.$notify({
                group: "info",
                type: "success",
                text: this.$ml.get("message.usersClearSuccess"),
              });
            }
          });
        });
    },
    multiRestore(us) {
      let url = "/user/restore";
      this.axios.put(url, { ids: this.selectUsers[us] }).then((response) => {
        if (response.status == 200) {
          this.getUsers("deleted");
        }
      });
    },
    multiClear(us) {
      let url = "/user/clear";
      let _this = this;
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>` +
            this.$ml.get("message.confirmDelete"),
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(() => {
          this.axios
            .put(url, { ids: _this.selectUsers[us] })
            .then((response) => {
              if (response.status == 200) {
                //_this.getUsers("all");
                _this.getUsers("deleted");

                this.$notify({
                  group: "info",
                  type: "success",
                  text:
                    _this.selectUsers[us].length >= 2
                      ? this.$ml.get("message.usersClearSuccess")
                      : this.$ml.get("message.userClearSuccess"),
                });
              }
            });
        });
    },
    dialogClose(val) {
      this.companydialog = val;
      this.getSubdivisions();
    },
    selectCompany(item, e) {
      this.companyActive = item;
      if (e.shiftKey) {
        if (!this.treeSelect[item.id]) {
          this.treeSelect[item.id] = true;
        }
        return;
      } else {
        this.treeSelect = [];
      }
      this.users[this.v].pagination.current_page = 1;
      //this.companyActive = item;
      if (!this.treeSelect[item.id]) {
        this.treeSelect[item.id] = true;
      }
      localStorage.setItem("terminal.users.subdiv", JSON.stringify(item.id));
      this.getUsers(this.v);
    },
    selectDepartment(item, e) {
      this.selectedDepartment = item;
      if (e.shiftKey) {
        if (!this.treeDepartmentSelect[item.id]) {
          this.$set(this.treeDepartmentSelect, item.id, true);
        }
        return;
      } else {
        this.treeDepartmentSelect = {};
      }
      this.users[this.v].pagination.current_page = 1;

      if (!this.treeDepartmentSelect[item.id]) {
        this.$set(this.treeDepartmentSelect, item.id, true);
      }
      this.getUsers(this.v);
    },
    isAddUser() {
      return (
        !this.l.cloud_connect &&
        (this.$auth.user().type_user == "admin" ||
          this.$auth.user().roles.includes("useradd") ||
          this.$auth.user().roles.includes("useredit") ||
          this.$auth.user().roles.includes("userdel"))
      );
    },
    isEditUser() {
      return (
        !this.l.cloud_connect &&
        (this.$auth.user().type_user == "admin" ||
          this.$auth.user().roles.includes("useredit") ||
          this.$auth.user().roles.includes("userdel"))
      );
    },
    isDelUser() {
      return (
        !this.l.cloud_connect &&
        (this.$auth.user().type_user == "admin" ||
          this.$auth.user().roles.includes("userdel"))
      );
    },
    isSdvEdit() {
      return (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().roles.includes("sdvedit")
      );
    },
    addCompany() {
      if (!this.isSdvEdit()) return;
      this.editCompanyItem = {
        id: 0,
        parent_id: this.companyActive.id || null,
        zones_id: null,
        name: "",
        is_guest: false,
      };
      this.companydialog = true;
      this.multidialog = false;
      this.ids = [];
    },
    editCompany() {
      if (!this.isSdvEdit() || this.companyActive.id < 1) return;
      this.ids = [];
      this.treeSelect.forEach((v, id) => {
        this.ids.push(id);
      });

      if (this.ids.length > 1) {
        this.companydialog = true;
        this.multidialog = true;
        this.editCompanyItem = {};
      } else {
        if (!this.companyActive.id) return;
        this.editCompanyItem = this.companyActive;
        this.companydialog = true;
        this.multidialog = false;
      }
    },
    deleteCompany() {
      if (!this.isSdvEdit()) return;
      let options = {
        loader: false,
        okText: this.$ml.get("button.delete"),
        cancelText: this.$ml.get("button.cancel"),
        backdropClose: true,
      };
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>
          <p><strong>${this.$ml.get(
            "message.confirmDeleteNestedWarning"
          )}</strong></p>
          <p>${this.$ml.get("message.confirmDelete")}</p>`,
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(() => {
          if (!this.treeSelect.length) {
            this.axios
              .put("/subdivision/delete", { ids: [this.companyActive.id] })
              .then(() => {
                this.getSubdivisions();
              })
              .catch((err) => {
                this.errorMessage = err.response.data.status.message;
              });
          } else {
            let ids = [];
            this.treeSelect.forEach((v, id) => {
              ids.push(id);
            });

            this.axios
              .put("/subdivision/delete", { ids: ids })
              .then(() => {
                this.getSubdivisions();
              })
              .catch((err) => {
                this.errorMessage = err.response.data.status.message;
              });
          }
        });
    },
    setTreeActiveClass(item) {
      if (item.id == this.companyActive.id || this.treeSelect[item.id]) {
        return "active";
      }
    },
    setDepartmentTreeActiveClass(item) {
      if (
        item.id == this.selectedDepartment.id ||
        this.treeDepartmentSelect[item.id]
      ) {
        return "active";
      }
    },
    styleRow(item) {
      if (item["blacklisted"]) {
        return "background: #272D3D; color: #fff;";
      }
      if (item["blocked"]) {
        return "background: #8ec2f7; color: #000;";
      }
      if (item["_select"]) {
        return "background: #dddddd;";
      }
    },
    showUser(item) {
      this.$router.push("/user/" + item.id);
    },
    searchfn(value) {
      this.search = value;
      this.users[this.v].pagination.current_page = 1;
      this.getUsers(this.v);
      this.user = false;
    },

    setFilter(filter) {
      this.filter = filter;
      this.users[this.v].pagination.current_page = 1;
      this.getUsers(this.v);
    },

    onChangeItemCount(value) {
      this.limit = value;
      localStorage.setItem(LS_USER_LIMIT, value);
      this.users[this.v].pagination.current_page = 1;
      this.getUsers(this.v);
    },

    getUsers(type) {
      this.isPending = true;
      let values = {
        search: this.search,
        subdivision: this.getSubvisionsIds(),
        department: this.getDepartmentIds(),
        limit: this.limit,
      };
      if (this.leftSideBarTabSelected != "subdivisions") {
        values.subdivision = null;
      } else {
        values.department = null;
      }

      if (this.filter && this.filter !== "all") {
        values.typeuser = this.filter;
      }

      if (type && type !== "all") {
        values.mode = type;
      }

      values.page = this.users[type].pagination.current_page;

      if (this.sortName) {
        values.sortName = this.sortName;
        values.sortOrder = this.sortOrder || "asc";
      }

      let url = "/users";
      this.axios
        .get(url, { params: values })
        .then((response) => {
          this.isPending = false;
          if (response.data.status.code == 0) {
            localStorage.setItem(
              "terminal.users." + type + ".current_page",
              values.page
            );
            this.users[type] = this.normalizeData(response);

            this.totalItems = response.data.meta.pagination.total_items;
          }
        })
        .catch(() => (this.isPending = false));
    },

    onSort({ name, order }, type) {
      this.sortName = name;
      this.sortOrder = order;

      this.getUsers(type);
    },

    getSelectCount(us) {
      if (us != this.v) return 0;
      let count = 0;
      this.selectUsers[us] = [];
      this.users[us].data.forEach((item) => {
        if (item["_select"]) {
          this.selectUsers[us].push(item.id);
          count++;
        }
      });

      return count;
    },
    normalizeData(response) {
      let users = {};
      users.data = response.data.data ? response.data.data : [];
      users.pagination = response.data.meta.pagination || {};

      users.data.forEach((item) => {
        item._select = false;
        if (item.photo) {
          item.avatar = "/avatar/small/" + item.photo;
        } else {
          item.avatar = "/avatar/small/avatar.png";
        }
        item.use_personal_mode = item.use_personal_mode
          ? this.$ml.get("users.personal")
          : null;
        item.type_user_id = item.type_user;
        item.type_user = this.$ml.get("users." + item.type_user);
        item.ff = item.ff ? "!" : "";
      });
      return users;
    },
    searchblfn(value) {
      this.search = value;
      this.blacks.pagination.current_page = 1;
      this.getBlack();
      this.user = false;
    },

    getParents(id, arrays = []) {
      let out = [];
      if (arrays) {
        arrays.forEach((item) => {
          if (item.parent_id == id) {
            out.push(item.id);
            let ids = this.getParents(item.id, arrays);
            if (ids.length) out.push(ids);
          }
        });
      }
      return out;
    },
    getDepartmentIds() {
      if (!this.selectedDepartment?.id) return;
      let out = [];
      out.push(this.selectedDepartment.id);
      let ids = this.getParents(this.selectedDepartment.id, this.departments);
      if (ids.length) out.push(ids);
      if (out.length) {
        return out.join(",");
      }
    },

    getSubvisionsIds() {
      if (!this.companyActive.id) return;
      let out = [];
      out.push(this.companyActive.id);
      let ids = this.getParents(this.companyActive.id, this.subdivisions);
      if (ids.length) out.push(ids);
      if (out.length) {
        return out.join(",");
      }
    },

    getSubdivisions() {
      this.axios.get("subdivisions").then((response) => {
        let parents = [];
        this.subdivisions = response.data.data;
        this.subdivisions.forEach((el) => {
          el.__select = false;
          this.sdv[el.id] = el.name;
          parents[el.id] = el.parent_id;
        });
        this.getSubdivisionsTree();

        let subdiv = localStorage.getItem("terminal.users.subdiv");
        if (subdiv) {
          let id = JSON.parse(subdiv);
          this.subdivisions.forEach((el) => {
            if (el.id == id) {
              this.companyActive = el;
              return;
            }
          });
        }
        let npage = localStorage.getItem(
          "terminal.users." + this.v + ".current_page"
        );
        if (npage == null) npage = 1;
        this.users[this.v].pagination.current_page = parseInt(npage);
        this.treeOpen = true;
        this.getUsers(this.v);
      });
    },

    getSubdivisionsTree() {
      this.axios.get("subdivisions/tree").then((response) => {
        this.tree = response.data.data;
      });
    },

    setActiveStyle() {
      let e = $(".v-treeview-node__root a.active");
      if (e !== null) {
        $(".v-treeview-node__root").css("background-color", "#fff");
        let el = $(e).closest(".v-treeview-node__root");
        el.css("background-color", "#e2e2e2");
      }
    },
    reportErrors() {
      if (this.users["errors"].data.length > 0) {
        // Получаем отчет о пользователях и ошибках
        let params = {
          page: this.users.errors.current_page,
          lang: this.l.lang,
        };
        let requestParams = {
          method: "get",
          params,
          url: "/report/usererrors",
          responseType: "blob",
        };

        this.axios.request(requestParams).then((response) => {
          if (response.status == 200) {
            let filename =
              "errors-" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
            let url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/xlsx",
              })
            );
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
          } else {
            this.$dialog
              .alert(this.$ml.get("users.notUsers"), {
                loader: false,
                okText: "OK",
                backdropClose: true,
              })
              .then(function (dialog) {});
          }
        });
      }
    },
    reportUsers(paramsArg = {}, type = "all") {
      if (this.users[type].data.length > 0) {
        // Получаем отчет о пользователях и ошибках
        let params = {
          page: this.users.errors.current_page,
          lang: this.l.lang,
          limit: -1,
          subdivision: this.getSubvisionsIds(),
          department: this.getDepartmentIds(),
          usertype: this.filter != "all" ? this.filter : "",
          search: this.search,
          ...paramsArg,
        };
        if (this.leftSideBarTabSelected != "subdivisions") {
          params.subdivision = null;
        } else {
          params.department = null;
        }
        let requestParams = {
          method: "get",
          params,
          url: "/report/users",
          responseType: "blob",
        };

        this.axios.request(requestParams).then((response) => {
          if (response.status == 200) {
            let filename =
              "users-" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
            let url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/xlsx",
              })
            );
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
          } else {
            this.$dialog
              .alert(this.$ml.get("users.notUsers"), {
                loader: false,
                okText: "OK",
                backdropClose: true,
              })
              .then(function (dialog) {});
          }
        });
      }
    },

    getDepartments() {
      this.axios("departments").then((response) => {
        this.departments = response.data.data;

        this.getDepartmentsTree();
      });
    },

    getDepartmentsTree() {
      this.axios.get("departments/tree").then((response) => {
        this.departmentsTree = response.data.data;
      });
    },

    onChangeDepartment(data) {
      this.getDepartments();
      this.selectedDepartment.name = data.name;
      this.selectedDepartment.parentId =
        data.parent_id > 0 ? data.parent_id : null;
      this.isShowModalDepartment = false;
    },

    onSelectDepartment([department]) {
      this.users[this.v].pagination.current_page = 1;
      this.selectedDepartment = department;
      this.getUsers(this.v);
    },

    addDepartment() {
      this.selectedDepartment = {};
      this.treeDepartmentSelect = {};
      this.isShowModalDepartment = true;
    },

    editDepartment() {
      this.isShowModalDepartment = true;
    },

    deleteDepartment() {
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>
          ${this.$ml.get("message.confirmDelete")}<br/><br/>
          ${this.$ml.get("departments.warningDeleteDepartment")}`,
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(() => {
          const idArr = Object.keys(this.treeDepartmentSelect);
          idArr.map((item) => {
            return this.axios
              .delete(`department/${item}`)
              .then(() => {
                this.selectedDepartment = {};
                this.treeDepartmentSelect = {};

                this.getDepartments();
                this.getUsers(this.v);

                this.$notify({
                  group: "info",
                  type: "success",
                  text: this.$ml.get("message.deleteSuccess"),
                });
              })
              .catch((error) => {
                let message = error.response.data.status.message;

                switch (message) {
                  case "no rights to delete":
                    message = this.$ml.get(
                      "departments.noRightsToDeleteMessage"
                    );
                    break;
                  case "group associated with departments":
                    message = this.$ml.get(
                      "departments.groupAssociatedWithDepartmentMessage"
                    );
                    break;
                  case "group associated with users":
                    message = this.$ml.get(
                      "departments.groupAssociatedWithUsersMessage"
                    );
                    break;
                  default:
                    break;
                }

                this.$notify({
                  group: "info",
                  type: "error",
                  text: message,
                });
              });
          });
        });
    },
  },

  computed: {
    ...mapState({
      l: (state) => state.settings.data,
    }),

    isDepEdit() {
      return (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().roles.includes("depedit") ||
        this.$auth.user().roles.includes("depdel")
      );
    },
    isDepAdd() {
      return (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().roles.includes("depadd") ||
        this.$auth.user().roles.includes("depedit") ||
        this.$auth.user().roles.includes("depdel")
      );
    },
    isDepDel() {
      return (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().roles.includes("depdel")
      );
    },
  },

  mounted() {
    localStorage.setItem("terminal.target.page", "/users");
    if (localStorage.getItem("terminal.users.tabs") !== null) {
      this.v = localStorage.getItem("terminal.users.tabs");
    } else {
      localStorage.removeItem("terminal.users.all.current_page");
      localStorage.setItem("terminal.users.tabs", "all");
      this.users["all"].pagination.current_page = 1;
    }

    if (this.l.cloud_connect) {
      this.afields[0].type = "text";
      this.afields[0].name = "";
      this.afields[0].header.type = "text";
    }
    this.getSubdivisions();
  },

  created() {
    this.getDepartments();
  },

  updated() {
    this.setActiveStyle();
  },
};
</script>

<style>
.check-select {
  margin-right: 0px;
}

.search-visitors-dashboard tbody tr td:first-of-type {
  width: auto;
}
.v-application ol,
.v-application ul {
  padding-left: 0px;
}
.v-treeview-node__root {
  cursor: default;
}
</style>
<style scoped>
.nav-tabs .nav-link {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.top-button-box {
  display: flex;
  width: 100%;
  margin-bottom: 0px;
  margin-left: 10px;
  /* flex-flow: row-reverse; */
}
.tab-content {
  padding: 15px;
  border-left: solid 1px #dee2e6;
  border-right: solid 1px #dee2e6;
  border-bottom: solid 1px #dee2e6;
  margin-bottom: 10px;

  /*box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);*/
}
.theme--light.v-application {
  background: none;
}
.btn.btn-primary {
  color: #fff;
}
/* .v-treeview-node__root! a.active {
  background: #666;
} */
.v-treeview-node__root a {
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
</style>
