<template>
  <div
    :class="[
      'device-monitoring',
      { 'device-monitoring--min-menu': isMenuMinimize },
      { 'device-monitoring--hide': isHide },
    ]"
  >
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <button
          v-bind="attrs"
          v-on="!isMobile ? on : null"
          class="device-monitoring__hide-button"
          @click="toggleMenu"
        >
          <v-icon v-if="isHide">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
        </button>
      </template>
      <span v-if="isHide">{{ $ml.get("devices.showMenu") }}</span>
      <span v-else>{{ $ml.get("devices.hideMenu") }}</span>
    </v-tooltip>

    <v-alert v-if="isFailure && errorMessage" type="error">
      {{ errorMessage }}
    </v-alert>

    <template v-if="isSuccess">
      <div class="device-monitoring__wrapper">
        <Device
          v-for="device in devices"
          :key="device.id"
          :data="device"
          :syncData="getDeviceSyncData(syncData, device)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Device from "./Device.vue";
import { mapState } from "vuex";

export default {
  name: "DeviceMonitoring",

  components: {
    Device,
  },

  data() {
    const isHide = localStorage.getItem("isHideDeviceMenu") === "true";

    return {
      isPending: false,
      isSuccess: false,
      isFailure: false,
      errorMessage: "",

      devices: [],
      syncData: [],

      isHide,
      isMobile: false,

      syncDeviceInterval: null,
    };
  },

  methods: {
    getDevices() {
      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;
      this.errorMessage = "";

      this.axios("devices")
        .then((response) => {
          this.isPending = false;
          this.isSuccess = true;

          this.devices = response.data.data;
        })
        .catch((error) => {
          this.isPending = false;
          this.isFailure = true;
          this.errorMessage = error.response.data.status.errors;
        });
    },

    toggleMenu() {
      this.isHide = !this.isHide;
      localStorage.setItem("isHideDeviceMenu", this.isHide);

      if (!this.isHide) {
        this.$timer.start("getMonitoringDevices");
      } else {
        this.$timer.stop("getMonitoringDevices");
      }
    },

    getMonitoringDevices() {
      this.axios("devices/monitoring").then((response) => {
        this.syncData = response.data.data;
      });
    },

    getDeviceSyncData(syncData, device) {
      return syncData.find((item) => item.id === device.id) || {};
    },

    checkDevice() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.isMobile = true;
      }
    },
  },

  computed: {
    ...mapState({
      isMenuMinimize: (state) => state.leftMenu.isMenuMinimize,
    }),
  },

  timers: {
    getMonitoringDevices: { time: 10000, repeat: true },
  },

  created() {
    this.getDevices();

    this.checkDevice();

    if (!this.isHide) {
      this.$timer.start("getMonitoringDevices");
    }
  },
};
</script>
