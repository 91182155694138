<template>
  <v-badge
    avatar
    bordered
    overlap
    :bottom="false"
    :left="true"
    offset-y="calc(100% + 8px)"
    color="success"
    :icon="icon3"
    :value="icon3"
  >
    <v-badge
      avatar
      bordered
      overlap
      bottom
      offset-y="calc(100% + 8px)"
      color="success"
      :icon="icon2"
      :value="icon2"
    >
      <v-badge
        avatar
        bordered
        overlap
        bottom
        color="primary"
        :icon="icon"
        :value="icon"
      >
        <v-avatar :size="size" @click="download">
          <v-img :src="src" v-on="$listeners" />
        </v-avatar>
      </v-badge>
    </v-badge>
  </v-badge>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      require: true,
    },

    type: {
      type: String,
      default: undefined,
    },

    type2: {
      type: String,
      default: undefined,
    },

    type3: {
      type: String,
      default: undefined,
    },

    size: {
      type: Number,
      default: 50,
    },

    downloadable: {
      type: Boolean,
      default: false,
    },

    downloadName: {
      type: String,
      default: "avatar.png",
    },
  },

  methods: {
    download() {
      if (this.downloadable) {
        const link = document.createElement("a");
        link.href = this.src;
        link.download = this.downloadName;
        link.click();
      }
    },
  },

  computed: {
    icon() {
      let icon = "";

      switch (this.type) {
        case "admin":
          icon = "mdi-crown";
          break;
        case "guard":
          icon = "mdi-shield";
          break;
        case "manager":
          icon = "mdi-account-group";
          break;
        case "guest":
          icon = "mdi-clock";
          break;
        case "employee":
          icon = "mdi-briefcase";
          break;
        default:
          break;
      }

      return icon;
    },
    icon2() {
      let icon2 = "";

      switch (this.type2) {
        case "personal":
          icon2 = "mdi-tag-text";
          break;
        default:
          break;
      }

      return icon2;
    },
    icon3() {
      let icon3 = "";

      switch (this.type3) {
        case "sent":
          icon3 = "mdi-email-fast-outline";
          break;
        default:
          break;
      }

      return icon3;
    },
  },
};
</script>
