<template>
  <div
    class="radial-progress"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
      background: `conic-gradient(#38c172 ${progress}%, 0, white ${
        100 - progress
      }%)`,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "RadialProgress",

  props: {
    progress: {
      type: Number,
      default: 0,
    },

    size: {
      type: Number,
      default: 100,
    },
  },
};
</script>
