<template>
  <div class="mt-5">
    <v-row>
      <v-col>
        <v-text-field
          v-model="companyName"
          :label="`${$ml.get('setting.companyName')}`"
          outlined
          @change="store"
        />
      </v-col>
    </v-row>

    <hr />

    <v-row>
      <v-col cols="12">
        <h5>{{ $ml.get("setting.invitations") }}</h5>
        <v-text-field
          v-model="url"
          :label="`${$ml.get('setting.url')}`"
          outlined
        />

        <v-text-field
          v-model="emailInviteTitle"
          :label="`${$ml.get('setting.emailTitle')}`"
          outlined
        />

        <v-textarea
          v-model="emailInviteMessage"
          :label="`${$ml.get('setting.emailMessage')}`"
          outlined
        />

        <v-textarea
          v-model="smsInviteMessage"
          :label="`${$ml.get('setting.smsMessage')}`"
          outlined
        />

        <div class="text-right">
          <v-btn color="primary" @click="store">
            {{ $ml.get("button.save") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <hr />

    <v-row>
      <v-col cols="12">
        <h5>{{ $ml.get("setting.KioskRegistration") }}</h5>
        <v-text-field
          v-model="emailKioskTitle"
          :label="`${$ml.get('setting.emailTitle')}`"
          outlined
        />

        <v-textarea
          v-model="emailKioskMessage"
          :label="`${$ml.get('setting.emailMessage')}`"
          outlined
        />

        <v-textarea
          v-model="smsKioskMessage"
          :label="`${$ml.get('setting.smsMessage')}`"
          outlined
        />

        <div class="text-right">
          <v-btn color="primary" @click="store">
            {{ $ml.get("button.save") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <hr />

    <v-row>
      <v-col cols="12">
        <h4>{{ $ml.get("setting.changeAccess") }}</h4>
        <v-text-field
          v-model="emailTitle"
          :label="`${$ml.get('setting.emailTitle')}`"
          outlined
          @change="store"
        />

        <v-textarea
          v-model="emailMessage"
          :label="`${$ml.get('setting.emailMessage')}`"
          outlined
        />

        <v-textarea
          v-model="smsMessage"
          :label="`${$ml.get('setting.smsMessage')}`"
          outlined
        />

        <div class="text-right">
          <v-btn color="primary" @click="store">
            {{ $ml.get("button.save") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    const url = this.value.LANDING_URL || "";
    const companyName = this.value.COMPANY_NAME || "OVISION";
    const emailInviteMessage =
      this.value.EMAIL_INVITE_MESSAGE ||
      "Перейдите по ссылке приглашения: [LINK]";
    const emailInviteTitle =
      this.value.EMAIL_INVITE_TITLE || "Подтвердите приглашение";
    const smsInviteMessage =
      this.value.SMS_INVITE_MESSAGE ||
      "Перейдите по ссылке приглашения: [LINK]";
    const emailKioskMessage =
      this.value.EMAIL_KIOSK_MESSAGE ||
      "Введите код [PIN] в приложение киоска и пройдите регистрацию";
    const emailKioskTitle =
      this.value.EMAIL_KIOSK_TITLE || "Код для регистрации через киоск";
    const smsKioskMessage =
      this.value.SMS_KIOSK_MESSAGE ||
      "Введите код [PIN] в приложение киоска и пройдите регистрацию";

    const emailMessage =
      this.value.EMAIL_MESSAGE ||
      "Вам предоставлен доступ в личный кабинет<br>\nВаш Логин: [USERNAME]<br>\nВаш пароль: [PASSWORD]";
    const emailTitle =
      this.value.EMAIL_TITLE || "Предоставление доступа в личный кабинет";
    const smsMessage =
      this.value.SMS_MESSAGE ||
      "Вам предоставлен доступ в личный кабинет\nВаш Логин: [USERNAME]\nВаш пароль: [PASSWORD]";

    return {
      url,
      emailMessage,
      emailTitle,
      emailInviteMessage,
      emailInviteTitle,
      smsInviteMessage,
      emailKioskMessage,
      emailKioskTitle,
      smsKioskMessage,
      smsMessage,
      companyName,
    };
  },

  methods: {
    store() {
      const data = [
        {
          key: "LANDING_URL",
          value: this.url,
        },
        {
          key: "EMAIL_INVITE_MESSAGE",
          value: this.emailInviteMessage,
        },
        {
          key: "EMAIL_INVITE_TITLE",
          value: this.emailInviteTitle,
        },
        {
          key: "SMS_INVITE_MESSAGE",
          value: this.smsInviteMessage,
        },
        {
          key: "COMPANY_NAME",
          value: this.companyName,
        },
        {
          key: "EMAIL_MESSAGE",
          value: this.emailMessage,
        },
        {
          key: "EMAIL_TITLE",
          value: this.emailTitle,
        },
        {
          key: "SMS_MESSAGE",
          value: this.smsMessage,
        },
      ];

      this.axios.post("settings", data).then((response) => {
        if (response.data.status.code == 0) {
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("message.saveSuccess"),
          });
        } else {
          this.$notify({
            group: "info",
            type: "error",
            text: response.data.status.message,
          });
        }
      });
    },
  },
};
</script>
