<template>
  <v-app>
    <!-- <ScheduleDialog /> -->
    <div class="col-md-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link active"
            href="#schedule"
            data-toggle="tab"
            @click="setTab('all')"
            >Расписание</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="#calendar"
            data-toggle="tab"
            @click="setTab('employee')"
            >Календарь</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="#targets"
            data-toggle="tab"
            @click="setTab('guest')"
            >Нормы</a
          >
        </li>
      </ul>
      <div class="tab-content">
        <div
          class="tab-pane add-new-visitor-form-container active"
          id="schedule"
        >
          <div v-if="isEdit()" class="top-button-box">
            <router-link
              :to="{ name: 'scheduleNew' }"
              class="btn btn-primary add"
              :title="$ml.get('schedule.addNewSchedule')"
              >{{ $ml.get("schedule.addSchedule") }}</router-link
            >
          </div>
          <datatable :fields="fields" :rows="schedules" :dblclick="edit">{{
            $ml.get("schedule.notSchedules")
          }}</datatable>
        </div>
        <div class="tab-pane add-new-visitor-form-container" id="calendar">
          <Calendar></Calendar>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Calendar from "./schedule/Calendar";
import datatable from "../components/datatable";
import router from "../router";
export default {
  data() {
    return {
      fields: [
        {
          group: [
            { name: "name", style: "font-size: 0.9rem; font-weight: bold;" },
            {
              name: "schedule",
              style: "font-size: 10px; float: left; margin-right: 3px;",
            },
          ],
          header: { text: "Расписание" },
          style: "width: 40%",
        },
        {
          name: "description",
          header: {
            text: "Детали",
            style: "width: 100%",
          },
        },
        {
          type: "button",
          title: this.$ml.get("schedule.editSchedule"),
          class: "btn btn-primary v-icon mdi mdi-cog",
          click: this.edit,
          style: "width: 40px; margin-right: 2px;",
        },
      ],
      schedules: [],
      days: [
        "Week_1",
        "Week_2",
        "Week_3",
        "Week_4",
        "Week_5",
        "Week_6",
        "Week_0",
      ],
    };
  },
  components: {
    datatable,
    Calendar,
  },
  methods: {
    activeBlock(tab) {},
    setTab(tab) {},
    isEdit() {
      return (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().roles.includes("schedule")
      );
    },
    edit(item) {
      if (this.isEdit()) {
        router.push({ path: `/schedule/${item.id}` });
      }
    },
    getSchedules() {
      let zone = "";
      if (this.zoneActive) {
        zone = "?zone=" + this.zoneActive.id;
      }
      this.axios.get("/schedules").then((response) => {
        if (response.status == 200) {
          this.schedules = response.data.data ? response.data.data : [];
          this.schedules.forEach((item) => {
            let schedule = {};
            if (item.data) {
              schedule = JSON.parse(item.data);
              item.schedule = this.getWeekString(schedule.weekdays);
              if (schedule.date_from) {
                item.schedule += " / " + schedule.date_from;
              }
              if (schedule.date_to) {
                item.schedule += " - " + schedule.date_to;
              }
              if (schedule.time_from) {
                item.schedule += " / " + schedule.time_from;
              }
              if (schedule.time_to) {
                item.schedule += " - " + schedule.time_to;
              }
            }
          });
        }
      });
    },
    getWeekString(weekdays) {
      let r = [];
      let val = weekdays.toString().split("");
      val.forEach((it, i) => {
        if (it == 1) {
          r.push(this.$ml.get("dtpicker." + this.days[i]));
        }
      });
      return r.join(",");
    },
  },
  mounted() {
    this.getSchedules();
  },
};
</script>
<style scoped>
.top-button-box {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  flex-flow: row-reverse;
  color: #ffffff;
}
.top-button-box a {
  color: #fff;
}
</style>
