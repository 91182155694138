import Vue from "vue";
import Vuex from "vuex";
import { createStore } from "vuex-extensions";
import modules from "./modules";

Vue.use(Vuex);

const store = createStore(Vuex.Store, {
  modules,
  strict: process.env.NODE_ENV !== "production",
  mixins: {
    mutations: {
      setLoading(state, payload) {
        switch (payload) {
          case "IS_PENDING":
            state.isPending = true;
            state.isSuccess = false;
            state.isFailure = false;
            break;
          case "IS_SUCCESS":
            state.isPending = false;
            state.isSuccess = true;
            state.isFailure = false;
            break;
          case "IS_FAILURE":
            state.isPending = false;
            state.isSuccess = false;
            state.isFailure = true;
            break;
          case "IS_PENDING_STORE":
            state.isPendingStore = true;
            state.isSuccessStore = false;
            state.isFailureStore = false;
            break;
          case "IS_SUCCESS_STORE":
            state.isPendingStore = false;
            state.isSuccessStore = true;
            state.isFailureStore = false;
            break;
          case "IS_FAILURE_STORE":
            state.isPendingStore = false;
            state.isSuccessStore = false;
            state.isFailureStore = true;
            break;
          case "IS_PENDING_EDIT":
            state.isPendingEdit = true;
            state.isSuccessEdit = false;
            state.isFailureEdit = false;
            break;
          case "IS_SUCCESS_EDIT":
            state.isPendingEdit = false;
            state.isSuccessEdit = true;
            state.isFailureEdit = false;
            break;
          case "IS_FAILURE_EDIT":
            state.isPendingEdit = false;
            state.isSuccessEdit = false;
            state.isFailureEdit = true;
            break;
          case "IS_PENDING_REMOVE":
            state.isPendingRemove = true;
            state.isSuccessRemove = false;
            state.isFailureRemove = false;
            break;
          case "IS_SUCCESS_REMOVE":
            state.isPendingRemove = false;
            state.isSuccessRemove = true;
            state.isFailureRemove = false;
            break;
          case "IS_FAILURE_REMOVE":
            state.isPendingRemove = false;
            state.isSuccessRemove = false;
            state.isFailureRemove = true;
            break;
          case "IS_PENDING_PAGINATION":
            state.isPendingPagination = true;
            state.isSuccessPagination = false;
            state.isFailurePagination = false;
            break;
          case "IS_SUCCESS_PAGINATION":
            state.isPendingPagination = false;
            state.isSuccessPagination = true;
            state.isFailurePagination = false;
            break;
          case "IS_FAILURE_PAGINATION":
            state.isPendingPagination = false;
            state.isSuccessPagination = false;
            state.isFailurePagination = true;
            break;
          default:
            state.isPending = false;
            state.isSuccess = false;
            state.isFailure = false;
            break;
        }
      },
    },
  },
});

// Автоматически запустить действие `init` для каждого существующего модуля
for (const moduleName of Object.keys(modules)) {
  if (modules[moduleName].actions.init) {
    store.dispatch(`${moduleName}/init`);
  }
}

export default store;
