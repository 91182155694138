import Vue from "vue";
import { instance, authHttp } from "./axios";
import router from "../router";

import auth from "@websanova/vue-auth/dist/v2/vue-auth.esm.js";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js";

Vue.use(auth, {
  plugins: {
    http: authHttp,
    router,
  },
  drivers: {
    auth: {
      request: function (req, token) {
        if (req.url === this.options.refreshData.url) {
          this.drivers.http.setHeaders.call(this, req, {
            Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
          });
        } else {
          this.drivers.http.setHeaders.call(this, req, {
            Authorization: `Bearer ${token}`,
          });

          instance.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
          };
        }
      },
      response: function (res) {
        var headers = this.drivers.http.getHeaders.call(this, res),
          token = headers.Authorization || headers.authorization;

        let refresh_token = undefined;
        if (res.data.refresh_token) {
          refresh_token = res.data.refresh_token;
        }

        if (token) {
          token = token.split(/Bearer:?\s?/i)[token.length > 1 ? 1 : 0].trim();
          if (refresh_token) {
            localStorage.setItem("refresh_token", refresh_token);
          }

          instance.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
          };

          return token;
        }
      },
    },
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    refreshData: {
      url: "/auth/refresh",
      enabled: true,
      method: "GET",
      interval: 10,
    },
  },
  loginData: { redirect: "" },
});
