<template>
  <DataTable
    :fields="fields"
    :rows="data"
    :click="onClick"
    :dblclick="onDblClick"
    :rowstyle="rowstyle"
    :isLoading="isLoading"
  >
    {{ $ml.get("users.no_passes_registered") }}
    <template v-slot:header></template>
    <template v-slot:footer>
      <slot name="footer" />
    </template>

    <template #[`item.avatar`]="{ item }">
      <img
        :src="item.avatar"
        style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover"
        @mouseenter="item.isHasPhoto ? onHoverImg(item.photo) : null"
        @mouseleave="onLeaveHoverImg"
      />
    </template>

    <template #[`item.meta`]="{ item }">
      <span>
        <v-tooltip v-if="item?.event?.escort_id" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="primary"
              >mdi-account-supervisor-circle-outline</v-icon
            >
          </template>
          <span>
            {{ $ml.get("user.escort") }} <br />
            {{ item.event.escort_name }} <br v-if="item.event.escort_name" />
            {{ $ml.get(`users.${item.event.escort_identifier}`) }}
          </span>
        </v-tooltip>
      </span>
    </template>

    <template #[`item.identity_types`]="{ item }">
      <span>
        <v-tooltip
          v-if="
            item.purposeType === 'entrance' || item.purposeType === 'duplex'
          "
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="success"
              >mdi-location-enter</v-icon
            >
          </template>
          <span>{{ $ml.get("device.purpose_entrance") }}</span>
        </v-tooltip>
        <v-tooltip v-if="item.purposeType === 'camera'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mirror-x"
              v-bind="attrs"
              v-on="on"
              small
              color="primary"
              >mdi-cctv</v-icon
            >
          </template>
          <span>{{ $ml.get("device.purpose_camera") }}</span>
        </v-tooltip>
        <v-tooltip v-if="item.purposeType === 'exit'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              color="error"
              class="rotate-180"
              >mdi-location-exit</v-icon
            >
          </template>
          <span>{{ $ml.get("device.purpose_exit") }}</span>
        </v-tooltip>
        <v-tooltip v-if="item.purposeType === 'basiclock'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="warning"
              >mdi-key</v-icon
            >
          </template>
          <span>{{ $ml.get("device.purpose_basicLock") }}</span>
        </v-tooltip>
      </span>

      <template v-if="item.identity_types">
        <span v-for="type in item.identity_types" :key="type">
          <v-tooltip v-if="type === 'face'" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                :color="item.blacklisted ? 'white' : ''"
              >
                mdi-emoticon-happy-outline
              </v-icon>
            </template>
            <span>{{ $ml.get("users.face") }}</span>
          </v-tooltip>

          <v-tooltip v-if="type === 'qr'" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                :color="item.blacklisted ? 'white' : ''"
              >
                mdi-qrcode-scan
              </v-icon>
            </template>
            <span>
              {{ $ml.get("users.qr") }}
              <template
                v-if="
                  item.identity_value &&
                  getIdentityValueType(item.identity_value) === 'qr'
                "
              >
                ({{ getIdentityValue(item.identity_value) }})
              </template>
            </span>
          </v-tooltip>

          <v-tooltip v-if="type === 'card'" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                v-clipboard:copy="getIdentityValue(item.identity_value)"
                v-clipboard:success="onCopyIdentityValue"
                small
                :color="item.blacklisted ? 'white' : ''"
              >
                mdi-card-bulleted-outline
              </v-icon>
            </template>
            <span>
              {{ $ml.get("users.card") }}<br />
              <template
                v-if="
                  item.identity_value &&
                  getIdentityValueType(item.identity_value) === 'card'
                "
              >
                HEX - {{ getIdentityValue(item.identity_value) }} <br />
                DEC - {{ parseInt(getIdentityValue(item.identity_value), 16) }}
              </template>
            </span>
          </v-tooltip>
        </span>
      </template>
    </template>

    <template #[`item.pass_type`]="{ item }">
      <template v-if="item.eventType !== 'deviceAlarm'">
        <div v-show="item.pass_type === 'before'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                :color="item.blacklisted ? 'white' : ''"
              >
                mdi-check
              </v-icon>
            </template>
            <span>{{ $ml.get("users.recognized") }}</span>
          </v-tooltip>
        </div>

        <div v-show="item.pass_type === 'pass'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                :color="item.blacklisted ? 'white' : ''"
              >
                mdi-check-all
              </v-icon>
            </template>
            <span>{{ $ml.get("users.recognizedAndPass") }}</span>
          </v-tooltip>
        </div>

        <div v-show="item.pass_type === 'alarm'">
          <v-tooltip top max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                :color="item.blacklisted ? 'white' : ''"
              >
                mdi-account-remove-outline
              </v-icon>
            </template>
            <span>
              {{ $ml.get("users.notRecognized") }}<br />
              <template v-if="item.message">
                {{ item.message }}
              </template>
            </span>
          </v-tooltip>
        </div>

        <div v-show="item.pass_type === 'error'">
          <v-tooltip top max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                :color="item.blacklisted ? 'white' : ''"
              >
                mdi-alert-circle-outline
              </v-icon>
            </template>
            <span>
              {{ $ml.get("users.error") }}<br />
              <template v-if="item.message">
                {{ item.message }}
              </template>
            </span>
          </v-tooltip>
        </div>
      </template>
      <div
        v-show="
          item.pass_type === 'alarm' &&
          item.identity_types &&
          item.identity_types.includes('face')
        "
      >
        <v-tooltip top max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              outlined
              color="red"
              x-small
              @click="guestReg(item)"
            >
              <v-icon x-small>mdi-account-clock</v-icon>
            </v-btn>
          </template>
          <span>
            {{ $ml.get("users.guestPass") }}
          </span>
        </v-tooltip>
      </div>
    </template>
  </DataTable>
</template>

<script>
import DataTable from "@/components/datatable";

export default {
  components: { DataTable },

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    rowstyle: Function,

    lang: {
      type: String,
      default: "ru",
    },
  },

  data() {
    return {
      fields: [
        {
          name: "meta",
          style: "width: 16px",
        },
        {
          name: "pass_type",
          style: "width: 16px",
        },
        {
          name: "identity_types",
          style: "width: 16px",
        },
        {
          name: "avatar",
          style: "width: 50px;",
        },
        {
          group: [
            {
              name: "full_name",
              title: this.$ml.get("users.fullname"),
              btag: "",
              atag: "",
            },
            {
              name: "purpose",
              title: this.$ml.get("users.device"),
              style: "font-size: 10px; float: left; margin-right: 3px;",
            },
            {
              name: "temperature",
              title: this.$ml.get("users.temperature"),
              btag: "",
              style: "font-size: 10px; float: left; margin-left: 3px;",
            },
          ],
          header: { text: this.$ml.get("users.visitor") },
        },
        {
          name: "visits",
          header: { text: this.$ml.get("users.visit_time") },
          style: "width: 50px;",
        },
      ],
    };
  },

  methods: {
    onClick(item) {
      this.$emit("click", item);
    },

    onDblClick(item) {
      this.$emit("dblclick", item);
    },

    guestReg(visitor) {
      const user = {
        last_name: `${this.$ml.get("users.guest")}-${this.$moment().format(
          "DDMMYYHHmm"
        )}`,
        type_user: "guest",
        photo_encrypted: visitor.photo,
        onepass: true,
        expire_date: this.$moment().add(24, "hour").toISOString(),
        identityValue:
          this.getIdentityValueType(visitor.identity_value) === "card"
            ? visitor.identity_value
            : null,
        identityType: this.getIdentityValueType(visitor.identity_value) || null,
      };

      this.$emit("guestReg", user);
    },

    getIdentityValueType(identityValue) {
      const separator = identityValue.indexOf(":");
      const type = identityValue.substring(0, separator);
      return type;
    },

    getIdentityValue(identityValue) {
      const separator = identityValue.indexOf(":");
      return identityValue.substring(identityValue.length, separator + 1);
    },

    onHoverImg(img) {
      this.$emit("hoverImg", img);
    },

    onLeaveHoverImg(img) {
      this.$emit("leaveHoverImg", img);
    },

    onCopyIdentityValue({ text }) {
      this.$notify({
        group: "info",
        type: "success",
        text: `${this.$ml.get("message.successCopy")} - ${text}`,
      });
    },
  },
};
</script>
