<template>
  <nav
    v-if="pagination.total > 1"
    :style="isPending ? 'pointerEvents: none !important' : ''"
  >
    <ul class="pagination">
      <li class="page-item" v-if="pagination.current_page > 1">
        <a
          class="page-link"
          href="javascript:void(0)"
          @click.prevent="changePage(1)"
          >{{ $ml.get("pagination.first") }}</a
        >
      </li>
      <li class="page-item" v-if="pagination.current_page > 1">
        <a
          class="page-link"
          href="javascript:void(0)"
          aria-label="Previous"
          v-on:click.prevent="changePage(pagination.current_page - 1)"
        >
          <span aria-hidden="true">«</span>
        </a>
      </li>
      <li
        class="page-item"
        v-for="(page, i) in pagesNumber"
        :class="{ active: page == pagination.current_page }"
        :key="i"
      >
        <a
          class="page-link"
          href="javascript:void(0)"
          @click.prevent="changePage(page)"
          >{{ page }}</a
        >
      </li>
      <li class="page-item" v-if="pagination.current_page < pagination.total">
        <a
          class="page-link"
          href="javascript:void(0)"
          aria-label="Next"
          v-on:click.prevent="changePage(pagination.current_page + 1)"
        >
          <span aria-hidden="true">»</span>
        </a>
      </li>
      <li class="page-item" v-if="pagination.current_page < pagination.total">
        <a
          class="page-link"
          href="#"
          @click.prevent="changePage(pagination.total)"
          >{{ $ml.get("pagination.last") }}</a
        >
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  props: {
    pagination: {
      total: 0,
      type: Object,
      required: true,
    },

    offset: {
      type: Number,
      default: 4,
    },

    isSetUrlQuery: {
      type: Boolean,
      default: true,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isPending: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    pagesNumber() {
      let from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      let to = from + this.offset * 2;
      if (to >= this.pagination.total) {
        to = this.pagination.total;
      }
      let pagesArray = [];
      for (let page = from; page <= to; page++) {
        pagesArray.push(page);
      }

      return pagesArray;
    },
  },

  watch: {
    "$route.query.page": {
      handler: function (page) {
        if (page && this.pagination.current_page != page) {
          this.changePage(page, false);
        }
      },
      deep: true,
    },
  },

  methods: {
    setPageToQuery(page) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page,
        },
      });
    },

    changePage(page, isSetUrlQuery = this.isSetUrlQuery) {
      if (isSetUrlQuery) {
        this.setPageToQuery(page);
      }

      this.pagination.current_page = page;
      this.$emit("paginate");
    },
  },
};
</script>
