import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import ru from "vuetify/src/locale/ru.ts";
import en from "vuetify/src/locale/en.ts";

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: { ru, en },
    current: "ru",
  },
  theme: {
    themes: {
      light: {
        primary: "#7165FF",
        accent: "#7165FF",
      },
    },
  },
};

export default new Vuetify(opts);
