<template>
  <div class="mt-5">
    <h5>{{ $ml.get("setting.migration_title") }}</h5>
    <v-row>
      <v-col cols="auto">
        <v-btn color="primary" @click="migration">
          {{ $ml.get("setting.migrationCheck") }}
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="migrationForce">
          {{ $ml.get("setting.migrationForce") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-textarea
          style="max-width: 1105px"
          v-if="log"
          :label="$ml.get('setting.errorLog')"
          :value="log"
          outlined
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
//import vuetify from "../../plugins/vuetify.js";
export default {
  props: ["version"],
  data() {
    return {
      updaterlist: [],
      updateritem: "1.0.0",
      btnloader: "",
      log: "",
    };
  },
  methods: {
    updateList(e) {
      this.updaterlist = [];
      this.axios.get("/updaterlist").then((response) => {
        if (response.status == 200) {
          //this.version = response.data.version;
          if (response.data.data) {
            response.data.data.forEach((element) => {
              this.updaterlist.push({ name: element.name, tag: element.tag });
            });
            if (this.updaterlist.length) {
              this.updateritem = this.updaterlist[0].tag;
            }
          }
        }
      });
    },
    updateVersion(e) {
      let values = {};
      values = {
        tag: this.updateritem,
      };

      let _this = this;
      this.btnloader = "button_loader";
      this.axios.post("/updaterversion", values, {
        header: {
          "Content-Type": "application/json",
        },
        timeout: 60000,
      });
      setTimeout(function () {
        _this.btnloader = "";
        this.axios.get("/migration").then((response) => {
          if (response.status == 200) {
            window.document.location.reload();
          }
        });
      }, 40000);
    },
    migration(e) {
      this.log = "";
      this.axios.get("/migration").then((response) => {
        this.log = response.data.status.message;
      });
    },
    migrationForce(e) {
      this.log = "";
      this.axios.get("/migration?force=true").then((response) => {
        this.log = response.data.status.message;
      });
    },
  },
  created() {
    this.updateList();
  },
};
</script>
<style scoped>
button.update {
  height: 44px;
}
.button_loader {
  /* background-color: transparent;
  border: 4px solid #f3f3f3;
  border-radius: 50%; */
  /* border-top: 4px solid #969696;
  border-bottom: 4px solid #969696;
  width: 40px;
  height: 40px; */
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  99% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  99% {
    transform: rotate(360deg);
  }
}
</style>
