import Vue from "vue";

export default {
  get({ commit }) {
    commit("setLoading", "IS_PENDING");

    return Vue.axios("setting/default")
      .then((response) => {
        commit("set", response.data.data);
        commit("setLoading", "IS_SUCCESS");
      })
      .catch(() => {
        commit("setLoading", "IS_FAILURE");

        Vue.$notify.open({
          group: "info",
          type: "error",
          text: Vue.$ml.get("message.saveError"),
        });
      });
  },
};
