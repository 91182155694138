import Vue from "vue";
import Dialog from "vuejs-dialog";
import "vuejs-dialog/dist/vuejs-dialog.min.css";

Vue.use(Dialog, {
  html: true,
  loader: true,
  okText: "Proceed",
  cancelText: "Cancel",
  animation: "bounce",
});
