<template>
  <v-row>
    <v-col :cols="12" md="auto" :lg="2">
      <v-row>
        <v-col cols="auto" :md="12">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                small
                link
                @click="goToFullscreen"
              >
                <v-icon dark> mdi-monitor-screenshot </v-icon>
              </v-btn>
            </template>
            <span>{{ $ml.get("users.monitoringFullScreen") }}</span>
          </v-tooltip>
        </v-col>

        <v-col cols="auto" :md="12">
          <div class="view-switcher">
            <v-btn-toggle v-model="viewType">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">
                    <v-icon>mdi-card-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $ml.get("users.combinedMode") }}</span>
              </v-tooltip>

              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">
                    <v-icon>mdi-card-multiple-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $ml.get("users.separateMode") }}</span>
              </v-tooltip>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col :cols="12" :md="12" :lg="10">
      <div v-if="viewType === 0" class="user-card-wrapper">
        <div
          v-if="lastVisit"
          :class="[
            'user-card',
            'user-card--once',
            {
              'user-card--alarm': isTempAlarm(lastVisit.temperatureValue),
            },
          ]"
        >
          <div
            class="column image-column"
            :style="{ backgroundImage: `url(${lastVisit.photo})` }"
          />

          <div class="user-card__content">
            <div
              v-if="lastVisit.blocked"
              style="text-align: center; margin-bottom: 10px"
            >
              <button
                class="btn btn-danger unblock"
                @click="guestUnblock(lastVisit)"
              >
                {{ $ml.get("users.unblock") }}
              </button>
            </div>

            <div
              v-if="
                !lastVisit.user_id &&
                lastVisit.identity_types &&
                lastVisit.identity_types.includes('face')
              "
              style="text-align: center; margin-bottom: 10px"
            >
              <button
                class="btn btn-danger unblock"
                @click="guestReg(lastVisit)"
              >
                {{ $ml.get("users.guestPass") }}
              </button>
            </div>

            <div class="mb-2 text-center">
              <span
                v-if="
                  lastVisit.purposeType === 'entrance' ||
                  lastVisit.purposeType === 'duplex'
                "
                class="mr-1 ml-1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small color="success"
                      >mdi-location-enter</v-icon
                    >
                  </template>
                  <span>{{ $ml.get("device.purpose_entrance") }}</span>
                </v-tooltip>
              </span>
              <span v-if="lastVisit.purposeType === 'exit'" class="mr-1 ml-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      color="error"
                      class="rotate-180"
                      >mdi-location-exit</v-icon
                    >
                  </template>
                  <span>{{ $ml.get("device.purpose_exit") }}</span>
                </v-tooltip>
              </span>
              <span v-if="lastVisit.purposeType === 'camera'" class="mr-1 ml-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      color="primary"
                      class="mirror-x mr-1"
                      >mdi-cctv</v-icon
                    >
                  </template>
                  <span>{{ $ml.get("device.purpose_camera") }}</span>
                </v-tooltip>
              </span>
              <span
                v-if="lastVisit.purposeType === 'basiclock'"
                class="mr-1 ml-1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small color="warning"
                      >mdi-key</v-icon
                    >
                  </template>
                  <span>{{ $ml.get("device.purpose_basicLock") }}</span>
                </v-tooltip>
              </span>
              <template v-if="lastVisit.eventType">
                <span
                  v-for="type in lastVisit.identity_types"
                  :key="type"
                  class="mr-2 ml-2"
                >
                  <v-tooltip v-if="type === 'face'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-emoticon-happy-outline
                      </v-icon>
                    </template>
                    <span>{{ $ml.get("users.face") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="type === 'qr'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-qrcode-scan
                      </v-icon>
                    </template>
                    <span>{{ $ml.get("users.qr") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="type === 'card'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-card-bulleted-outline
                      </v-icon>
                    </template>
                    <span>{{ $ml.get("users.card") }}</span>
                  </v-tooltip>
                </span>
              </template>
            </div>

            <div class="title">{{ lastVisit.full_name }}</div>
            <div v-if="lastVisit.type_user" class="title-small">
              {{ $ml.get("users." + lastVisit.type_user) }}
              <span
                v-if="lastVisit.temperature && !temperatureRise"
                :style="
                  isTempAlarm(lastVisit.temperatureValue)
                    ? 'color: red; font-size: 22px; font-weight: 600;'
                    : 'font-size: 22px; color: green;'
                "
                >{{ lastVisit.temperature.replace("/", "") }}</span
              >
            </div>
            <div
              v-for="org in lastVisit.organization"
              :key="org.name"
              class="title-company"
            >
              {{ org.name }}
            </div>
            <div v-if="lastVisit.info" class="title-small">
              {{ lastVisit.info }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="viewType === 1" class="user-card-wrapper">
        <div
          v-if="entrance"
          :class="[
            'user-card',
            {
              'user-card--alarm': isTempAlarm(entrance.temperatureValue),
            },
          ]"
        >
          <div v-if="entrance.purpose" class="user-card__badge">
            <span
              v-if="entrance.purposeType === 'camera'"
              class="user-card__badge-title"
            >
              {{ $ml.get("device.purpose_camera") }}
            </span>
            <span v-else class="user-card__badge-title">
              {{ $ml.get("device.purpose_entrance") }}
            </span>
            ({{ entrance.purpose }})
          </div>

          <div
            class="column image-column"
            :style="{ backgroundImage: `url(${entrance.photo})` }"
          />

          <div class="user-card__content">
            <div
              v-if="entrance.blocked"
              style="text-align: center; margin-bottom: 10px"
            >
              <button
                class="btn btn-danger unblock"
                @click="guestUnblock(entrance)"
              >
                {{ $ml.get("users.unblock") }}
              </button>
            </div>
            <div
              v-if="
                !entrance.user_id &&
                entrance.identity_types &&
                entrance.identity_types.includes('face')
              "
              style="text-align: center; margin-bottom: 10px"
            >
              <button
                class="btn btn-danger unblock"
                @click="guestReg(entrance)"
              >
                {{ $ml.get("users.guestPass") }}
              </button>
            </div>

            <div class="mb-2 text-center">
              <span v-if="entrance.purposeType === 'camera'" class="mr-1 ml-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      color="primary"
                      class="mirror-x"
                      >mdi-cctv</v-icon
                    >
                  </template>
                  <span>{{ $ml.get("device.purpose_camera") }}</span>
                </v-tooltip>
              </span>
              <span
                v-if="
                  entrance.purposeType === 'entrance' ||
                  entrance.purposeType === 'duplex'
                "
                class="mr-1 ml-1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small color="success"
                      >mdi-location-enter</v-icon
                    >
                  </template>
                  <span>{{ $ml.get("device.purpose_entrance") }}</span>
                </v-tooltip>
              </span>
              <span
                v-if="entrance.purposeType === 'basiclock'"
                class="mr-1 ml-1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small color="warning"
                      >mdi-key</v-icon
                    >
                  </template>
                  <span>{{ $ml.get("device.purpose_basicLock") }}</span>
                </v-tooltip>
              </span>

              <template v-if="entrance.eventType">
                <span
                  v-for="type in entrance.identity_types"
                  :key="type"
                  class="mr-1 ml-1"
                >
                  <v-tooltip v-if="type === 'face'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-emoticon-happy-outline
                      </v-icon>
                    </template>
                    <span>{{ $ml.get("users.face") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="type === 'qr'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-qrcode-scan
                      </v-icon>
                    </template>
                    <span>{{ $ml.get("users.qr") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="type === 'card'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-card-bulleted-outline
                      </v-icon>
                    </template>
                    <span>{{ $ml.get("users.card") }}</span>
                  </v-tooltip>
                </span>
              </template>
            </div>

            <div class="title">{{ entrance.full_name }}</div>
            <div v-if="entrance.type_user" class="title-small">
              {{ $ml.get("users." + entrance.type_user) }}
              <span
                v-if="entrance.temperature && !temperatureRise"
                :style="
                  isTempAlarm(entrance.temperatureValue)
                    ? 'color: red; font-size: 22px; font-weight: 600;'
                    : 'font-size: 22px; color: green;'
                "
                >{{ entrance.temperature.replace("/", "") }}</span
              >
            </div>
            <div
              v-for="org in entrance.organization"
              :key="org.name"
              class="title-company"
            >
              {{ org.name }}
            </div>
            <div v-if="entrance.info" class="title-small">
              {{ entrance.info }}
            </div>
            <div class="title-small">
              {{ entrance.visits }}
            </div>
          </div>
        </div>

        <div
          v-if="exit"
          :class="[
            'user-card',
            {
              'user-card--alarm': isTempAlarm(exit.temperatureValue),
            },
          ]"
        >
          <div v-if="exit.purpose" class="user-card__badge">
            <span class="user-card__badge-title">
              {{ $ml.get("device.purpose_exit") }}
            </span>
            ({{ exit.purpose }})
          </div>

          <div
            class="column image-column"
            :style="{ backgroundImage: `url(${exit.photo})` }"
          />

          <div class="user-card__content">
            <div
              v-if="exit.blocked"
              style="text-align: center; margin-bottom: 10px"
            >
              <button
                class="btn btn-danger unblock"
                @click="guestUnblock(exit)"
              >
                {{ $ml.get("users.unblock") }}
              </button>
            </div>
            <div
              v-if="
                !exit.user_id &&
                exit.identity_types &&
                exit.identity_types.includes('face')
              "
              style="text-align: center; margin-bottom: 10px"
            >
              <button class="btn btn-danger unblock" @click="guestReg(exit)">
                {{ $ml.get("users.guestPass") }}
              </button>
            </div>

            <div class="mb-2 text-center">
              <span class="mr-1 ml-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      color="error"
                      class="rotate-180"
                      >mdi-location-exit</v-icon
                    >
                  </template>
                  <span>{{ $ml.get("device.purpose_exit") }}</span>
                </v-tooltip>
              </span>

              <template v-if="exit.eventType">
                <span
                  v-for="type in exit.identity_types"
                  :key="type"
                  class="mr-1 ml-1"
                >
                  <v-tooltip v-if="type === 'face'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-emoticon-happy-outline
                      </v-icon>
                    </template>
                    <span>{{ $ml.get("users.face") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="type === 'qr'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-qrcode-scan
                      </v-icon>
                    </template>
                    <span>{{ $ml.get("users.qr") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="type === 'card'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-card-bulleted-outline
                      </v-icon>
                    </template>
                    <span>{{ $ml.get("users.card") }}</span>
                  </v-tooltip>
                </span>
              </template>
            </div>

            <div class="title">{{ exit.full_name }}</div>
            <div v-if="exit.type_user" class="title-small">
              {{ $ml.get("users." + exit.type_user) }}
              <span
                v-if="exit.temperature && !temperatureRise"
                :style="
                  isTempAlarm(exit.temperatureValue)
                    ? 'color: red; font-size: 22px; font-weight: 600;'
                    : 'font-size: 22px; color: green;'
                "
                >{{ exit.temperature.replace("/", "") }}</span
              >
            </div>
            <div
              v-for="org in exit.organization"
              :key="org.name"
              class="title-company"
            >
              {{ org.name }}
            </div>
            <div v-if="exit.info" class="title-small">
              {{ exit.info }}
            </div>
            <div class="title-small">
              {{ exit.visits }}
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import faceValidate from "@/mixins/faceValidate";

export default {
  mixins: [faceValidate],

  props: {
    entrance: {
      type: Object,
      default: () => null,
    },

    exit: {
      type: Object,
      default: () => null,
    },

    temperatureRange: {
      type: Array,
      default: () => [],
    },

    temperatureRise: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      viewType: parseInt(localStorage.getItem("viewType")) || 0,
    };
  },

  watch: {
    viewType(type) {
      localStorage.setItem("viewType", type);
    },
  },

  methods: {
    isTempAlarm(temp) {
      if (
        (temp && temp < parseFloat(this.temperatureRange[0])) ||
        temp > parseFloat(this.temperatureRange[1])
      ) {
        return true;
      }

      return false;
    },

    guestUnblock(visitor) {
      if (visitor && visitor.blocked && visitor.user_id) {
        this.axios
          .put("/user/unblocked", { ids: [parseInt(visitor.user_id)] })
          .then(() => {
            this.$emit("unblock", visitor.user_id);
          });
      }
    },

    guestReg(visitor) {
      const user = {
        last_name: `${this.$ml.get("users.guest")}-${this.$moment().format(
          "DDMMYYHHmm"
        )}`,
        type_user: "guest",
        photo_encrypted: visitor.photo,
        onepass: true,
        expire_date: this.$moment().add(24, "hour").toISOString(),
      };

      this.$emit("guestReg", user);
    },

    goToFullscreen() {
      const routeData = this.$router.resolve({ name: "fullscreenMonitoring" });
      window.open(routeData.href, "_blank");
    },
  },

  computed: {
    lastVisit() {
      let result = null;

      if (this.entrance || this.exit) {
        if (this.entrance && !this.exit) {
          result = this.entrance;
        } else if (!this.entrance && this.exit) {
          result = this.exit;
        } else {
          const isAfter = this.$moment(this.entrance.created_at).isAfter(
            this.exit.created_at
          );

          if (isAfter) {
            result = this.entrance;
          } else {
            result = this.exit;
          }
        }
      }

      return result;
    },
  },
};
</script>
