var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'zoom-img',
    {
      'zoom-img--top': _vm.bottom ? false : _vm.top,
      'zoom-img--bottom': _vm.bottom,
      'zoom-img--left': _vm.right ? false : _vm.left,
      'zoom-img--right': _vm.right,
    },
  ]},[_c('img',{staticClass:"zoom-img__img",attrs:{"src":_vm.url}})])
}
var staticRenderFns = []

export { render, staticRenderFns }