<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $ml.get("auth.enterPassword") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :label="$ml.get('auth.password') + '*'"
                  v-model="password"
                  :rules="passwordRules"
                  type="password"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">{{ $ml.get("button.cancel") }}</v-btn>
          <v-btn v-if="password" color="primary" text @click="confirm">{{
            $ml.get("button.apply")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      dialog: true,
      password: "",
    };
  },
  props: ["value"],
  methods: {
    close() {
      this.$emit("input", "");
      this.$emit("toggle", false);
    },
    confirm() {
      this.$emit("input", this.password);
      this.$emit("toggle", true);
    },
  },
  mounted() {
    this.dialog = true;
  },
};
</script>
