<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $ml.get("departments.department") }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-if="!isMultiple"
          :label="`${$ml.get('departments.name')} *`"
          v-model="name"
          :rules="[
            (v) => !!v || this.$ml.get('message.NameIsRequired'),
            (v) => v.length <= 70 || this.$ml.get('user.70maxSizeField'),
          ]"
          required
        />
        <v-select
          item-text="name"
          item-value="id"
          :items="departmentsOptions"
          v-model="parent"
          :label="$ml.get('departments.parent')"
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="close">{{ $ml.get("button.close") }}</v-btn>
      <v-btn color="primary" text @click="store">{{
        $ml.get("button.save")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    singledata: {
      tyoe: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
    departments: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    let firstParent = -1;

    if (this.$auth.user().type_user === "manager") {
      firstParent = this.departments[0].id;
    }

    const name = this.singledata?.name || "";
    const parent = this.singledata?.parent_id || firstParent;

    return {
      valid: true,
      name,
      parent,

      isPending: false,
      isSuccess: false,
      isFailure: false,
    };
  },

  methods: {
    close() {
      this.name = "";
      this.parent = null;
      this.$refs.form.reset();
      this.$emit("close", false);
    },

    store() {
      if (this.$refs.form.validate()) {
        if (this.isEdit) {
          this.edit();
        } else {
          this.add();
        }
      }
    },

    add() {
      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;

      const { name, parent: parent_id } = this;

      const data = {
        name,
        parent_id,
      };

      this.axios
        .post("department/add", data)
        .then(() => {
          this.isPending = false;
          this.isSuccess = true;
          this.isFailure = false;

          this.close();

          this.$emit("store");
        })
        .catch((error) => {
          this.isPending = false;
          this.isSuccess = false;
          this.isFailure = true;

          this.$notify({
            group: "info",
            type: "error",
            text: error.response.data.status.message,
          });
        });
    },

    edit() {
      if (this.isMultiple) {
        this.multiEdit();
      } else {
        this.singleEdit();
      }
    },
    singleEdit() {
      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;

      const depart_id = this.singledata.id;

      const params = {
        id: depart_id,
        name: this.name,
        parent_id: this.parent,
      };

      this.axios
        .put(`department/${depart_id}`, params)
        .then(() => {
          this.isPending = false;
          this.isSuccess = true;
          this.isFailure = false;

          this.close();

          this.$emit("store", params);
        })
        .catch((error) => {
          this.isPending = false;
          this.isSuccess = false;
          this.isFailure = true;

          this.$notify({
            group: "info",
            type: "error",
            text: error.response.data.status.message,
          });
        });
    },
    multiEdit() {
      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;

      const idArr = Object.keys(this.data);
      Promise.all(
        idArr.map((item) => {
          const params = {
            id: item,
            parent_id: this.parent,
          };

          return this.axios
            .put(`department/${item}`, params)
            .then(() => this.$emit("store", params));
        })
      )
        .then(() => {
          this.isPending = false;
          this.isSuccess = true;
          this.isFailure = false;
        })
        .catch((error) => {
          this.isPending = false;
          this.isSuccess = false;
          this.isFailure = true;
          this.$notify({
            group: "info",
            type: "error",
            text: error?.response?.data?.status?.message || "",
          });
        });
    },
  },

  computed: {
    isMultiple() {
      return Object.keys(this.data).length > 1;
    },
    departmentsOptions() {
      if (this.isMultiple) {
        const idArr = Object.keys(this.data);
        const departments = this.departments.filter((department) => {
          return !idArr.includes(String(department.id));
        });
        const options = [
          {
            id: -1,
            name: this.$ml.get("departments.withoutParent"),
          },
          ...departments,
        ];
        return options;
      }

      const departments = this.departments.filter(
        (department) => department.id !== this.singledata?.id
      );

      let options = [...departments];

      if (this.$auth.user().type_user !== "manager") {
        options = [
          {
            id: -1,
            name: this.$ml.get("departments.withoutParent"),
          },

          ...options,
        ];
      }

      return options;
    },

    isEdit() {
      if (this.singledata?.id) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
