<template>
  <v-card
    :color="
      'enable' in channelState && !channelState.enable ? '#F3F2FF' : '#DCD9FD'
    "
    elevation="0"
  >
    <v-card-title class="text-h5">
      <v-row align-content="center">
        <v-col>{{ title }}</v-col>
        <v-col v-if="'enable' in channelState" cols="auto">
          <v-switch v-model="channelState.enable" hide-details class="mt-0" />
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col :cols="12" :md="6">
          <v-text-field
            v-model="channelState.name"
            :label="`${$ml.get('devices.nameDevice')} *`"
            :disabled="isDisabledForm"
            :rules="nameRules"
            outlined
            required
          />
        </v-col>
        <v-col :cols="12" :md="6">
          <v-text-field
            v-model="uid"
            :label="`${$ml.get('devices.deviceUid')} *`"
            :disabled="isDisabledForm"
            :rules="cameraUidRules"
            outlined
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            ref="zones"
            v-model="channelState.zones_id"
            :items="zonesTo"
            item-text="name"
            item-value="id"
            :label="
              channelState.purpose === 'exit'
                ? `${$ml.get('devices.zoneFrom')} *`
                : `${$ml.get('devices.zoneTo')} *`
            "
            :disabled="isDisabledForm"
            outlined
          />
        </v-col>
      </v-row>
      <v-row
        ><v-col cols="12" md="6">
          <v-subheader>{{ $ml.get("devices.face_recognition") }} </v-subheader>
          <v-slider
            v-model="faceRecognition"
            class="align-center"
            :max="faceRecognitionMax"
            :min="1"
            step="1"
            :disabled="isDisabledForm"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="faceRecognition"
                class="mt-0 pt-0"
                hide-details
                :max="faceRecognitionMax"
                :min="1"
                step="1"
                type="number"
                style="width: 60px"
                :disabled="isDisabledForm"
                outlined
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
        <v-col cols="12" md="6">
          <v-subheader>{{ $ml.get("devices.recogniion_delay") }} </v-subheader>
          <v-slider
            v-model="recognitionTimeOut"
            class="align-center"
            :max="recognitionTimeOutMax"
            :min="1"
            step="1"
            :disabled="isDisabledForm"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="recognitionTimeOut"
                class="mt-0 pt-0"
                hide-details
                :max="recognitionTimeOutMax"
                :min="1"
                step="1"
                type="number"
                style="width: 60px"
                :disabled="isDisabledForm"
                outlined
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>

        <v-col cols="12">
          <v-btn
            v-if="isShowRemoveBtn"
            @click="removeChannel()"
            class="mt-0"
            color="error"
          >
            {{ $ml.get("devices.deleteCamera") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { isUUID } from "validator";
export default {
  name: "SmartCameras",
  props: {
    channel: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isShowRemoveBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      valid: true,
      channelState: this.channel,
      zones: [],
      faceRecognition: 90,
      faceRecognitionMax: 100,
      recognitionTimeOut: 2,
      recognitionTimeOutMax: 60,
      title: "",
      uid: "",
      cameraUidRules: [
        (v) => !!v || this.$ml.get("message.NameIsRequired"),
        (v) => isUUID(v) || this.$ml.get("message.errorUID"),
      ],
      nameRules: [
        (v) => !!v || this.$ml.get("message.NameIsRequired"),
        (v) => v?.length <= 20 || "Name must be less than 20 characters",
      ],
    };
  },
  methods: {
    getZones() {
      this.axios("zones").then((response) => {
        this.zones = [
          { id: null, name: this.$ml.get("devices.zoneEmpty") },
          ...response.data.data,
        ];
      });
    },
    store() {
      const data = {
        face_recognition: this.faceRecognition,
        recognition_timeout: this.recognitionTimeOut,
        uid: this.uid,
      };
      this.channelState.data = JSON.stringify(data);
    },
    removeChannel() {
      this.$emit("remove-channel", this.index);
    },
    getStore() {
      if (this.channelState?.data?.length) {
        const data = JSON.parse(this.channelState.data);
        this.uid = data.uid;
        this.faceRecognition = data.face_recognition;
        this.recognitionTimeOut = data.recognition_timeout;
      }
    },
  },
  watch: {
    faceRecognition(val) {
      this.store();
    },
    recognitionTimeOut(val) {
      this.store();
    },
    uid() {
      this.store();
    },
  },
  computed: {
    zonesTo() {
      return this.zones.filter(
        (zone) => zone.id !== this.channelState.source_id && zone.id !== null
      );
    },
    isDisabledForm() {
      return "enable" in this.channelState ? !this.channelState.enable : false;
    },
  },
  created() {
    this.getZones();
    this.getStore();
    this.store();
    console.log(this.channel);
  },
};
</script>
