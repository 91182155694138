<template>
  <div class="btn-group" v-if="value">
    <span
      v-for="(day, i) in days"
      :key="day"
      class="btn btn-sm btn-default"
      :class="{ 'btn-primary': ch(i) }"
      @click.prevent="click(i)"
      >{{ $ml.get("dtpicker." + day) }}</span
    >
  </div>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      days: [
        "Week_1",
        "Week_2",
        "Week_3",
        "Week_4",
        "Week_5",
        "Week_6",
        "Week_0",
      ],
      val: ["0", "0", "0", "0", "0", "0", "0"],
    };
  },
  methods: {
    click(i) {
      this.val[i] = this.val[i] === "0" ? "1" : "0";
      this.value = this.val.join("");
      let a = this.days;
      this.days = [];
      this.days = a;
      this.$emit("input", this.value);
    },
    ch(i) {
      return parseInt(this.val[i]);
    },
  },
  mounted() {
    if (!this.value) this.value = "0000000";
    this.val = this.value.toString().split("");
  },
};
</script>
