export var getImage = function (url) {
  return new Promise(function (resolve, reject) {
    var img = new Image();
    img.onload = function () {
      resolve(url);
    };
    img.onerror = function () {
      reject(url);
    };
    img.src = url;
  });
};

var createImage = function (url, container, repeat = false) {
  var sequence = Promise.resolve();
  container.innerHTML = "";
  //var container = this.$refs.mjpeg;
  var imgs = [url];
  var imgPromises = imgs.map(getImage);
  imgPromises.forEach(function (curPromise) {
    sequence = sequence
      .then(function () {
        return curPromise;
      })
      .then(function (url) {
        var img = document.createElement("img");
        img.src = url;
        img.style = "width: 100%";
        container.innerHTML = "";
        container.appendChild(img);
      })
      .catch(function (err) {
        if (repeat) {
          setTimeout(function () {
            createImage(url, container, true);
          }, 2000);
        }
      });
  });
};
export default createImage;
