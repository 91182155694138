<template>
  <v-form ref="form" v-model="valid" lazy-validation class="mt-5">
    <v-row>
      <v-col>
        <h5>{{ $ml.get("setting.import_export_title") }} (.uovb)</h5>
        <v-row align="center">
          <v-col cols="12" md="auto">
            <v-row no-gutters>
              <v-col cols="auto">
                <input
                  :title="$ml.get('setting.pageNumberInfo')"
                  type="number"
                  min="1"
                  max="100000"
                  class="form-control"
                  style="width: 60px"
                  v-model="pageExport"
                />
              </v-col>
              <v-col>
                <v-btn
                  color="primary"
                  :disabled="isExport"
                  @click="exportUsers"
                  >{{ $ml.get("setting.exportButton") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="auto">
            <div
              for="import-uovb"
              class="btn-file v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary"
            >
              {{ $ml.get("setting.importButton") }}
              <input
                id="import-uovb"
                name="ifiles"
                type="file"
                multiple
                accept=".uovb"
                @change="importUsers"
              />
            </div>
          </v-col>
        </v-row>

        <v-row v-if="progress1">
          <v-col>
            <v-progress-linear :value="progress1" />
          </v-col>
        </v-row>

        <v-row v-if="progress">
          <v-col>
            <v-progress-linear :value="progress" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <hr />

    <v-row>
      <v-col>
        <h5>{{ $ml.get("setting.importPhoto") }}</h5>
        <v-row align="center">
          <v-col>
            <treeselect
              v-model="company"
              :multiple="true"
              :options="companies"
              :placeholder="$ml.get('setting.import-in-company')"
              :searchable="true"
              :show-count="true"
              flat
            />
          </v-col>
          <v-col cols="auto">
            <div v-if="company.length">
              <div
                for="import-photos"
                class="btn-file v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary"
              >
                {{ $ml.get("setting.importPhotoButton") }}
                <input
                  id="import-uovb"
                  name="photos"
                  type="file"
                  multiple
                  accept=".bmp, .png, .jpg, .jpeg"
                  @change="importPhotos"
                />
              </div>
            </div>
          </v-col>
        </v-row>

        <template v-if="progress2">
          <v-progress-linear
            :value="progress2"
            class="mt-2"
          ></v-progress-linear>
          <hr v-if="listError" />
          <v-textarea
            v-if="listError"
            :label="$ml.get('setting.errorlog')"
            :value="listError"
            outlined
          />
        </template>
      </v-col>
    </v-row>

    <hr />

    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h5>{{ $ml.get("setting.backUps") }}</h5>
          </v-col>
          <v-spacer />
          <v-col>
            <v-switch
              v-model="autobackup.enabled"
              :label="$ml.get('setting.backup.autobackup')"
              hide-details
              class="mt-0 pt-0"
              @change="store"
            />
          </v-col>
        </v-row>

        <v-row v-if="autobackup.enabled">
          <v-col>
            <v-text-field
              v-if="!isAutoBackupDayOfWeek"
              v-model="autobackup.time_day"
              :label="$ml.get('setting.backup.timeDay')"
              type="number"
              min="0"
              max="31"
              outlined
              :rules="timeDayRules"
              @change="store"
              @keypress="isNumber($event)"
            />

            <v-btn-toggle
              v-if="isAutoBackupDayOfWeek"
              v-model="autobackup.time_day_of_week"
              color="primary"
              multiple
              @change="store"
            >
              <v-btn value="1">
                {{ $ml.get("dtpicker.Week_1") }}
              </v-btn>
              <v-btn value="2">
                {{ $ml.get("dtpicker.Week_2") }}
              </v-btn>
              <v-btn value="3">
                {{ $ml.get("dtpicker.Week_3") }}
              </v-btn>
              <v-btn value="4">
                {{ $ml.get("dtpicker.Week_4") }}
              </v-btn>
              <v-btn value="5">
                {{ $ml.get("dtpicker.Week_5") }}
              </v-btn>
              <v-btn value="6">
                {{ $ml.get("dtpicker.Week_6") }}
              </v-btn>
              <v-btn value="0">
                {{ $ml.get("dtpicker.Week_0") }}
              </v-btn>
            </v-btn-toggle>

            <v-checkbox
              v-model="isAutoBackupDayOfWeek"
              :label="$ml.get('setting.backup.byDayOfWeek')"
              @change="store"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="autobackup.time_hour"
              :label="$ml.get('setting.backup.timeHour')"
              type="number"
              min="0"
              max="23"
              outlined
              :rules="timeHourRules"
              @change="store"
              @keypress="isNumber($event)"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="autobackup.time_minute"
              :label="$ml.get('setting.backup.timeMinute')"
              type="number"
              min="0"
              max="59"
              outlined
              :rules="timeMinuteRules"
              @change="store"
              @keypress="isNumber($event)"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="autobackup.max_count"
              :label="$ml.get('setting.backup.maxCount')"
              min="1"
              max="30"
              type="number"
              outlined
              :rules="timeCountRules"
              @change="store"
              @keypress="isNumber($event)"
            />
          </v-col>
        </v-row>

        <v-row align="center" justify="space-between">
          <v-col cols="12" md="6">
            <v-btn
              v-if="isBackuping"
              color="error"
              :disabled="isRestoring"
              @click="cancelBackup"
            >
              {{ $ml.get("setting.cancelBackupButton") }}
            </v-btn>
            <v-btn
              v-else
              color="primary"
              :disabled="isRestoring"
              @click="createBackup"
            >
              {{ $ml.get("setting.backupButton") }}
            </v-btn>
          </v-col>

          <v-col cols="12" md="6">
            <v-file-input
              truncate-length="50"
              accept=".ovtb"
              chips
              show-size
              :label="$ml.get('setting.restoreButton')"
              :disabled="isBackuping || isRestoring"
              :loading="isUpload"
              @change="restore"
            />
          </v-col>
        </v-row>

        <v-row v-if="backupProgress">
          <v-col cols="12">
            <template>
              <v-progress-linear
                color="blue-grey lighten-1"
                :value="backupProgress"
                height="25"
                rounded
                >{{ $ml.get("setting.backuping") }}
                <strong>&nbsp;{{ Math.ceil(backupProgress) }}%</strong>
              </v-progress-linear>
            </template>
          </v-col>
        </v-row>

        <v-row v-if="restoreProgress">
          <v-col cols="12">
            <template>
              <v-progress-linear
                color="blue-grey lighten-1"
                :value="restoreProgress"
                height="25"
                rounded
                >{{ $ml.get("setting.restoring") }}
                <strong>&nbsp;{{ Math.ceil(restoreProgress) }}%</strong>
              </v-progress-linear>
            </template>
          </v-col>
        </v-row>

        <v-row v-show="isFailureBackupList && backupListErrorMessage">
          <v-col cols="12">
            <v-alert color="red" type="error">
              {{ backupListErrorMessage }}
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-alert v-show="backupingErrorMessage" color="red" type="error">
              {{ backupingErrorMessage }}
            </v-alert>

            <v-data-table
              :items="backupList"
              :headers="backupListHeaders"
              :loading="isPendingBackupList"
              dense
            >
              <template v-slot:[`item.size`]="{ item }">
                {{ getSize(item.size) }}
              </template>

              <template v-slot:[`item.date_create`]="{ item }">
                {{ item.date_create | moment("DD.MM.YYYY HH:mm:ss") }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-progress-circular
                  v-if="downloadingBackupName === item.name"
                  :size="16"
                  indeterminate
                  color="blue-grey"
                  class="mr-2"
                />
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="mr-2"
                      @click="downloadBackup(item.name)"
                    >
                      mdi-download
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("button.download") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="mr-2"
                      :disabled="isBackuping || isRestoring"
                      @click="restoreBackup(item.name)"
                    >
                      mdi-backup-restore
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("button.restore") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      :disabled="isBackuping || isRestoring"
                      @click="deleteBackup(item.name)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("button.delete") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import moment from "moment-timezone";
import faceValidate from "@/mixins/faceValidate";

export default {
  mixins: [faceValidate],

  props: ["value"],
  data() {
    return {
      valid: true,

      isAutoBackupDayOfWeek: false,
      autobackup: {
        enabled: false,
        time_day: 0,
        time_day_of_week: [],
        time_hour: 0,
        time_minute: 0,
        max_count: 20,
      },
      company: [],
      companies: [],
      lockimport: true,
      file: {},
      progress: 0,
      progress1: 0,
      progress2: 0,
      pageExport: 1,
      listError: "",

      backupListHeaders: [
        { text: this.$ml.get("setting.backupName"), value: "name" },
        { text: this.$ml.get("setting.backupSize"), value: "size" },
        { text: this.$ml.get("setting.backupDate"), value: "date_create" },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],
      backupList: [],
      isPendingBackupList: false,
      isSuccessBackupList: false,
      isFailureBackupList: false,
      backupListErrorMessage: "",

      backupProgress: 0,
      isBackuping: false,
      backupingErrorMessage: "",
      restoreProgress: 0,
      isRestoring: false,
      isUpload: false,

      downloadingBackupName: "",

      isExport: false,

      timeDayRules: [
        (v) =>
          v.toString().length <= 2 ||
          this.$ml.with("length", 2).get("message.lengthIsLarge"),
        (v) =>
          (parseInt(v) >= 0 && parseInt(v) <= 31) ||
          this.$ml.with("start", 0).with("end", 31).get("message.numberRange"),
      ],
      timeHourRules: [
        (v) =>
          v.toString().length <= 2 ||
          this.$ml.with("length", 2).get("message.lengthIsLarge"),
        (v) =>
          (parseInt(v) >= 0 && parseInt(v) <= 23) ||
          this.$ml.with("start", 0).with("end", 23).get("message.numberRange"),
      ],
      timeMinuteRules: [
        (v) =>
          v.toString().length <= 2 ||
          this.$ml.with("length", 2).get("message.lengthIsLarge"),
        (v) =>
          (parseInt(v) >= 0 && parseInt(v) <= 59) ||
          this.$ml.with("start", 0).with("end", 59).get("message.numberRange"),
      ],
      timeCountRules: [
        (v) =>
          v.toString().length <= 2 ||
          this.$ml.with("length", 2).get("message.lengthIsLarge"),
        (v) =>
          (parseInt(v) >= 1 && parseInt(v) <= 30) ||
          this.$ml.with("start", 1).with("end", 30).get("message.numberRange"),
      ],
    };
  },
  components: {
    Treeselect,
  },
  methods: {
    isNumber(evt) {
      if (!/\d/.test(evt.key)) return evt.preventDefault();
    },

    importusers: function (item) {
      this.progress = 0;
      if (item.total) {
        this.progress = Math.ceil((item.item / item.total) * 100);
        if (item.item == item.total) {
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("message.import_success"),
          });
        }
      }
    },
    deleteAllUsers() {
      let _this = this;
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>` +
            `<span style="color: red;">` +
            this.$ml.get("message.confirmDeleteAllUsers") +
            `</span>`,
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(() => {
          this.axios.delete("/users/all").then((response) => {
            _this.$notify({
              group: "info",
              type: "success",
              text: this.$ml.get("message.delete_success"),
            });
          });
        });
    },
    importPhotos(e) {
      this.listError = "";
      this.progress2 = 0;
      let photos = [];
      photos = $(e.target).prop("files");
      let self = this;
      if (photos) {
        let files = Array.from(photos);
        this.nextPostPhoto(files, 0);
      }
    },
    nextPostPhoto(files, i) {
      let isCallNext = true;
      let count = files.length;
      if (i >= count) return;
      let file = files[i];
      let formData = new FormData();
      formData.append("file", file);
      this.axios
        .post("/user/import/photo", formData, {
          header: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.success == false) {
            this.listError = this.listError + response.data.message + "\n";
          }
        })
        .catch((error) => {
          const { message } = error.response.data.status;

          if (message === "Not found device") {
            isCallNext = false;
          }

          this.$notify({
            group: "info",
            type: "error",
            text: this.getLangMessage(message),
          });
        })
        .finally(() => {
          i++;
          this.progress2 = Math.ceil((i / count) * 100);
          if (i == count) {
            this.$notify({
              group: "info",
              type: "success",
              text: this.$ml.get("message.import_success"),
            });
          } else {
            if (isCallNext) {
              this.nextPostPhoto(files, i);
            } else {
              this.progress2 = 0;
            }
          }
        });
    },
    nextPostUovb(files, i) {
      let count = files.length;
      if (i >= count) return;
      let file = files[i];
      let formData = new FormData();
      formData.append("file", file);
      this.axios
        .post("/user/import", formData, {
          header: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            i++;
            this.progress1 = Math.ceil((i / count) * 100);
            if (i < count) {
              this.nextPostUovb(files, i);
            }
            //  else {
            //   this.$notify({
            //     group: "info",
            //     type: "success",
            //     text: this.$ml.get("message.import_success"),
            //   });
            // }
          }
        });
    },
    importUsers(e) {
      this.progress1 = 0;
      let ifiles = [];
      ifiles = $(e.target).prop("files");
      let self = this;
      if (ifiles) {
        let files = Array.from(ifiles);
        this.nextPostUovb(files, 0);
      }
    },
    exportUsers() {
      this.isExport = true;

      let requestParams = {
        method: "get",
        url: "/user/export?page=" + this.pageExport,
        responseType: "blob",
      };

      this.axios.request(requestParams).then((response) => {
        this.isExport = false;

        let filename =
          "terminal-" +
          moment().format("YYYYMMDDHHmmss") +
          "-p" +
          this.pageExport +
          ".uovb";
        let url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/zip",
          })
        );
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.$notify({
          group: "info",
          type: "success",
          text: this.$ml.get("message.export_success"),
        });
      });
    },

    findTreeGroupId(id, tree) {
      let fl = false;
      tree.forEach((it) => {
        if (it.id == id) {
          fl = true;
          return;
        } else if (it.children !== undefined) {
          if (this.findTreeGroupId(id, it.children)) {
            fl = true;
            return;
          }
        }
      });
      return fl;
    },
    getCompanies() {
      this.axios.get("/subdivisions/tree").then((response) => {
        if (response.status == 200) {
          this.companies = response.data.data ? response.data.data : [];
          this.company.forEach((it, i) => {
            if (!this.findTreeGroupId(it, this.companies)) {
              this.company.splice(i, 1);
            }
          });
        }
      });
    },
    load() {
      this.lockimport = true;
      if (this.value["AUTOBACKUP"]) {
        this.autobackup = JSON.parse(this.value["AUTOBACKUP"]);

        if (this.autobackup.time_day_of_week) {
          this.isAutoBackupDayOfWeek = true;
        } else {
          this.isAutoBackupDayOfWeek = false;
        }
      }
      if (this.value["IMPORT_SUBDIVISIONS"]) {
        this.company = this.value["IMPORT_SUBDIVISIONS"]
          .split(",")
          .map(function (val) {
            return parseInt(val.trim(), 10);
          });
      } else {
        this.company = [];
      }
    },
    store() {
      if (this.$refs.form.validate()) {
        let values = [];

        const autoBackup = {
          enabled: this.autobackup.enabled,
          time_hour: parseInt(this.autobackup.time_hour),
          time_minute: parseInt(this.autobackup.time_minute),
          max_count: parseInt(this.autobackup.max_count),
        };

        if (this.isAutoBackupDayOfWeek) {
          autoBackup.time_day_of_week = this.autobackup.time_day_of_week;
        } else {
          autoBackup.time_day = parseInt(this.autobackup.time_day);
        }

        values.push({
          key: "AUTOBACKUP",
          value: JSON.stringify(autoBackup),
        });

        values.push({
          key: "IMPORT_SUBDIVISIONS",
          value: this.company.join(","),
        });

        this.axios.post("/settings", values).then(() => {
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("message.saveSuccess"),
          });
        });
      }
    },

    createBackup() {
      this.backupingErrorMessage = "";

      this.axios
        .post("backup")
        .then(() => {
          this.isBackuping = true;
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            text: error.response.data.status.message,
          });
        });
    },

    restore(file) {
      if (file) {
        this.isUpload = true;

        const formData = new FormData();
        formData.append("file", file);

        const config = {
          header: {
            "Content-Type": "multipart/form-data",
          },
        };

        this.axios
          .post("/restore", formData, config)
          .then(() => {
            this.isUpload = false;
            this.isRestoring = true;
          })
          .catch((error) => {
            this.isUpload = false;

            this.$notify({
              type: "error",
              text: error.response.data.status.message,
            });
          });
      }
    },

    restoreBackup(name) {
      this.isRestoring = true;

      this.axios.post(`restore/${name}`);
    },

    deleteBackup(name) {
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>` +
            this.$ml.get("message.confirmDelete"),
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(() => {
          this.axios.delete(`backup/${name}`).then(() => {
            this.getBackupList();
          });
        });
    },

    cancelBackup() {
      this.axios.put("backup/stop").then(() => {
        this.isBackuping = false;
        this.backupProgress = 0;
      });
    },

    getBackupList() {
      this.isPendingBackupList = true;
      this.isSuccessBackupList = false;
      this.isFailureBackupList = false;
      this.backupListErrorMessage = "";

      this.axios("backup/list")
        .then((response) => {
          this.isPendingBackupList = false;
          this.isSuccessBackupList = true;
          this.isFailureBackupList = false;

          if (response.data.data) {
            this.backupList = response.data.data;
          }
        })
        .catch((error) => {
          this.isPendingBackupList = false;
          this.isSuccessBackupList = false;
          this.isFailureBackupList = true;
          this.backupListErrorMessage = error.response.data.status.message;
        });
    },

    downloadBackup(name) {
      this.downloadingBackupName = name;

      this.axios({
        url: `backup/${name}`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();

        this.downloadingBackupName = "";
      });
    },

    getBackupStatus() {
      this.axios("backup/status");
    },

    onBackupEvent(event) {
      if (event.proc >= 100) {
        this.isBackuping = false;
        this.backupProgress = 0;
        this.getBackupList();

        this.$notify({
          group: "info",
          type: "success",
          text: this.$ml.get("setting.backupSuccessMessage"),
        });
      } else if (event.proc <= 0) {
        this.isBackuping = false;
        this.backupProgress = 0;
      } else {
        this.isBackuping = true;
        this.backupProgress = event.proc;
      }
    },

    onRestoreEvent(event) {
      if (event.proc >= 100) {
        this.isRestoring = false;
        this.restoreProgress = 0;

        this.logout();
        this.$notify({
          group: "info",
          type: "success",
          text: this.$ml.get("setting.restoreSuccessMessage"),
        });
      } else if (event.proc <= 0) {
        this.isRestoring = false;
        this.restoreProgress = 0;
      } else {
        this.isRestoring = true;
        this.restoreProgress = event.proc;
      }
    },

    logout() {
      this.$auth.logout();
    },

    onBackupLogEvent(event) {
      if (event.status === "error") {
        this.backupingErrorMessage = event.message;
        this.cancelBackup();
      }
    },

    getSize(bytes) {
      let Kbytes, Mbytes, Gbytes;

      let size = `${bytes} B`;

      if (bytes >= 1024) {
        Kbytes = parseFloat(bytes / 1024).toFixed(1);
        size = `${Kbytes} KB`;
      }

      if (Kbytes && Kbytes >= 1024) {
        Mbytes = parseFloat(Kbytes / 1024).toFixed(1);
        size = `${Mbytes} MB`;
      }

      if (Mbytes && Mbytes >= 1024) {
        Gbytes = parseFloat(Mbytes / 1024).toFixed(1);
        size = `${Gbytes} GB`;
      }

      return size;
    },
  },

  watch: {
    company(val) {
      if (!this.lockimport) {
        this.store();
      }
      //this.company = val;
    },
  },

  created() {
    this.load();

    this.$root.$on("importusers", (item) => {
      this.importusers(item);
    });
    this.$root.$on("restore", (item) => {
      this.onRestoreEvent(item);
    });
    this.$root.$on("backup", (item) => {
      this.onBackupEvent(item);
    });
    this.$root.$on("backupLog", (item) => {
      this.onBackupLogEvent(item);
    });

    this.getCompanies();
    this.getBackupList();
    this.getBackupStatus();
  },

  destroyed() {
    this.$root.$off("importusers");
    this.$root.$off("restore");
    this.$root.$off("backup");
    this.$root.$off("backupLog");
  },

  updated() {
    this.lockimport = false;
    // this.company.forEach((it, i) => {
    //   if (!this.findTreeGroupId(it, this.companies)) {
    //     this.company.splice(i, 1);
    //   }
    // });
  },
};
</script>
<style scoped>
.btn-file > input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  height: 100%;
  z-index: 5;
  cursor: pointer;
}
.btn-file > input[type="file" i]::-webkit-file-upload-button {
  display: none;
}
</style>
