<template>
  <div>
    <h4>{{ $ml.get("devices.alarmPinsTriggers") }}</h4>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th></th>
            <th v-for="pin in pins" :key="pin.id" class="text-left">
              <v-text-field
                v-model="pin.name"
                outlined
                dense
                hide-details
                @change="onChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="relay in relays" :key="relay.name">
            <td class="text-no-wrap">
              <strong>{{ $ml.get("devices.relay") }} {{ relay.id }}</strong>
            </td>
            <td
              v-for="item in alarmPinsRelayDependenceState"
              :key="item.alarm_id"
            >
              <v-checkbox
                v-model="item.relays"
                :value="relay.id"
                @change="onChange"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "AlarmPins",

  props: {
    relays: {
      type: Array,
      default: () => [],
    },

    alarmPins: {
      type: Array,
      default: () => [],
    },

    alarmPinsRelayDependence: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      pins: this.alarmPins,
      alarmPinsRelayDependenceState: this.alarmPinsRelayDependence,
    };
  },

  methods: {
    onChange() {
      const { alarmPinsRelayDependenceState, pins } = this;

      this.$emit("change", {
        alarmPinsRelayDependence: alarmPinsRelayDependenceState,
        alarmPins: pins,
      });
    },
  },
};
</script>
