var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-background",staticStyle:{"display":"block"},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.close.apply(null, arguments)}}},[_c('div',{staticClass:"take-photo",style:({ minHeight: _vm.isStreamLoaded ? '90vh' : 'auto' })},[_c('a',{staticClass:"modal-close",attrs:{"href":"#"},on:{"click":_vm.close}}),_c('div',{staticClass:"camera-box"},[(
          _vm.selectedCamera &&
          (_vm.selectedCamera.device_type === '' ||
            _vm.selectedCamera.device_type === 'OGATE')
        )?_c('div',{staticClass:"camera-box__stream"},[(_vm.isLoadingStream)?_c('v-progress-circular',{attrs:{"size":100,"color":"primary","indeterminate":""}}):_c('div',{staticClass:"camera-box__stream-image",style:({
            backgroundImage: `url(http://${
              this.selectedCamera.ip_address
            }:7777/mjpeg?ver=${this.$moment().unix()})`,
          })})],1):_c('div',{staticClass:"camera-box__message"},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isAwaitingPhoto),expression:"!isAwaitingPhoto"}],attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$ml.get("imageBox.chooseDeviceMessage"))+" ")]),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAwaitingPhoto),expression:"isAwaitingPhoto"}],attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.$ml.get("imageBox.goToDeviceMessage"))+" ")]),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFailure),expression:"isFailure"}],attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.$ml.get("imageBox.noactive")))])],1),_c('div',{staticClass:"camera-box__controls"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.cameraList,"item-text":"name","item-value":"id","label":_vm.$ml.get('imageBox.camera_for_reg'),"return-object":"","outlined":"","hide-details":"","disabled":_vm.isAwaitingPhoto},on:{"change":_vm.onSelectedCamera},model:{value:(_vm.selectedCamera),callback:function ($$v) {_vm.selectedCamera=$$v},expression:"selectedCamera"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"primary","disabled":!_vm.selectedCamera || _vm.isAwaitingPhoto || _vm.isLoadingStream},on:{"click":_vm.takePhoto}},[_vm._v(" "+_vm._s(_vm.$ml.get("button.makePhoto"))+" ")])],1)],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }