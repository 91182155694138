<template>
  <v-app>
    <v-card elevation="2">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <div v-if="isZoneEdit()" class="panel mb-5">
              <button
                class="btn btn-primary v-icon mdi mdi-shape-circle-plus mr-1"
                @click="addZone"
                :title="$ml.get('zone.addNewDeviceZone')"
              />
              <button
                v-if="zoneActive.id !== undefined && zoneActive.id !== null"
                class="btn btn-primary v-icon mdi mdi-circle-edit-outline mr-1"
                @click="editZone"
                :title="$ml.get('zone.makeChanges')"
              />
              <button
                v-if="zoneActive.id !== undefined && zoneActive.id !== null"
                class="btn btn-danger v-icon mdi mdi-delete-circle-outline"
                @click="deleteZone"
                :title="$ml.get('zone.deleteSelectedZone')"
              />
            </div>
            <v-card class="mx-auto" :disabled="isPending">
              <v-list>
                <v-treeview hoverable :items="tree" return-object activatable>
                  <template slot="label" slot-scope="{ item }">
                    <a
                      :class="'treeitem ' + setTreeActiveClass(item)"
                      @click="selectZone(item)"
                      @dblclick="editZone"
                      style="
                        height: 100%;
                        width: 100%;
                        display: block;
                        float: left;
                      "
                      >{{ item.name }}</a
                    >
                  </template>
                </v-treeview>
              </v-list>
            </v-card>
          </v-col>

          <v-col cols="12" md="8">
            <v-row v-if="isDeviceControl()">
              <v-col cols="12" sm="6">
                <div v-show="getSelectCount() > 0">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on">
                        {{ $ml.get("menu.actions") }}&nbsp;
                        <v-icon right> mdi-menu-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item link @click="emergency('open')">
                        <v-list-item-title>{{
                          $ml.get("devices.emergency.open")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="emergency('close')">
                        <v-list-item-title>{{
                          $ml.get("devices.emergency.default")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-divider />
                      <v-list-item link @click="ogateStatus(['off'])">
                        <v-list-item-title>{{
                          $ml.get("devices.ogatestate.off")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="ogateStatus(['on'])">
                        <v-list-item-title>{{
                          $ml.get("devices.ogatestate.on")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-divider />
                      <v-list-item link @click="biometricDisable(true)">
                        <v-list-item-title>{{
                          $ml.get("devices.biometric.off")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="biometricDisable(false)">
                        <v-list-item-title>{{
                          $ml.get("devices.biometric.on")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-divider />
                      <v-list-item link @click="ogateReboot('on')">
                        <v-list-item-title>{{
                          $ml.get("devices.reboot")
                        }}</v-list-item-title>
                      </v-list-item>

                      <v-divider />

                      <v-list-item link @click="onDelete">
                        <v-list-item-title>{{
                          $ml.get("devices.delete")
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="text-right">
                <div v-if="isDeviceEdit()">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on">
                        {{ $ml.get("devices.addDevice") }}&nbsp;
                        <v-icon right> mdi-menu-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item
                        link
                        :to="{
                          name: 'addDevice',
                          query: { device: 'OGATE' },
                          params: {
                            query: {
                              page: this.devices.pagination.current_page,
                              zoneActiveId: this.zoneActiveId,
                            },
                          },
                        }"
                      >
                        <v-list-item-title>{{
                          $ml.get("devices.addNewDeviceOgate")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        :to="{
                          name: 'addDevice',
                          query: { device: 'OMINI' },
                          params: {
                            query: {
                              page: this.devices.pagination.current_page,
                              zoneActiveId: this.zoneActiveId,
                            },
                          },
                        }"
                      >
                        <v-list-item-title>{{
                          $ml.get("devices.addNewDeviceOmini")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        :to="{
                          name: 'addDevice',
                          query: { device: 'CONTRL' },
                          params: {
                            query: {
                              page: this.devices.pagination.current_page,
                              zoneActiveId: this.zoneActiveId,
                            },
                          },
                        }"
                      >
                        <v-list-item-title>{{
                          $ml.get("devices.addNewDeviceController")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        :to="{
                          name: 'addDevice',
                          query: { device: 'ROBOSTRAZH' },
                          params: {
                            query: {
                              page: this.devices.pagination.current_page,
                              zoneActiveId: this.zoneActiveId,
                            },
                          },
                        }"
                      >
                        <v-list-item-title>{{
                          $ml.get("devices.addNewDeviceRobostrazh")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        :to="{
                          name: 'addDevice',
                          query: { device: 'UFACE' },
                          params: {
                            query: {
                              page: this.devices.pagination.current_page,
                              zoneActiveId: this.zoneActiveId,
                            },
                          },
                        }"
                      >
                        <v-list-item-title>{{
                          $ml.get("devices.addNewDeviceUface")
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <DataTable
                  :fields="fields"
                  :rows="devices.data"
                  :rowstyle="styleRow"
                  :dblclick="edit"
                  :select="true"
                  :itemCount="limit"
                  isChangeItemCount
                  @changeItemCount="onChangeItemCount"
                  :isItemCountListDisabled="isPending"
                  :isLoading="isPending"
                  >{{ $ml.get("devices.notDevices") }}
                  <template v-slot:footer>
                    <vue-pagination
                      :pagination="devices.pagination"
                      :offset="2"
                      @paginate="onChangePage"
                      :isDisabled="isPending"
                    />
                  </template>

                  <!--Radial Progress-->

                  <template #[`item.avatar`]="{ item, index }">
                    <RadialProgress
                      :size="78"
                      :progress="styleRound(devices.data[index])"
                      class="mt-3 mb-3"
                    >
                      <v-badge
                        :value="
                          devices.data[index]
                            ? devices.data[index].monitoring.person_num
                            : false
                        "
                        :content="
                          devices.data[index]
                            ? devices.data[index].monitoring.person_num
                            : 0
                        "
                        bordered
                        color="primary"
                        class="pt-2 pb-2"
                        offset-x="100% - 5px"
                        offset-y="30"
                        left
                      >
                        <v-avatar size="70">
                          <v-img :src="item.deviceIcon" :alt="item.name" />
                        </v-avatar>
                      </v-badge>
                    </RadialProgress>
                  </template>

                  <template #[`item.error_logs`]="{ item }">
                    <v-btn
                      v-if="item.is_error_logs"
                      icon
                      color="red"
                      @click="openDeviceErrorLogs(item)"
                    >
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-btn>
                  </template>

                  <template #[`item.person_num`]="{ item }">
                    <template v-if="item.monitoring.person_num">
                      {{ item.monitoring.person_num }}
                    </template>
                  </template>

                  <template #[`item.gate_command`]="{ item }">
                    <template v-if="item.device_type != 'ROBOSTRAZH'">
                      <div
                        v-for="(gateStatus, index) in item.monitoring
                          .gate_command"
                        :key="index"
                        class="text-no-wrap"
                      >
                        <template
                          v-if="item.monitoring.gate_command.length > 1"
                        >
                          {{ $ml.get("devices.door") }} {{ index + 1 }}:
                        </template>

                        <template v-if="gateStatus === 'close'">
                          {{ $ml.get("devices.doorClose") }}
                          <v-btn
                            color="primary"
                            x-small
                            depressed
                            outlined
                            @click="
                              changeGateCommand(
                                item.id,
                                getArrayGateCommand(
                                  item.monitoring.gate_command,
                                  index,
                                  'open'
                                )
                              )
                            "
                          >
                            {{ $ml.get("devices.doorOpening") }}
                          </v-btn>
                        </template>
                        <template v-else>
                          {{ $ml.get("devices.doorOpen") }}
                          <v-btn
                            color="primary"
                            x-small
                            depressed
                            outlined
                            @click="
                              changeGateCommand(
                                item.id,
                                getArrayGateCommand(
                                  item.monitoring.gate_command,
                                  index,
                                  'close'
                                )
                              )
                            "
                          >
                            {{ $ml.get("devices.doorClosing") }}
                          </v-btn>
                        </template>
                      </div>
                    </template>
                  </template>
                </DataTable>

                <template v-if="isDeviceEdit()">
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    class="mr-2"
                    @click="scan"
                    >{{ $ml.get("devices.scan") }}
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                  <v-btn
                    v-if="selectedDevices.length"
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    class="mr-2"
                    @click="addSelectDev"
                    >{{ $ml.get("devices.addscandevices") }}
                  </v-btn>

                  <template v-if="addresses.length">
                    <hr />
                    <v-chip-group v-model="selectedDevices" column multiple>
                      <v-chip
                        v-for="device in addresses"
                        :key="device.ip"
                        :value="device"
                        :filter="!allDevices.includes(device.ip)"
                        :color="allDevices.includes(device.ip) ? 'success' : ''"
                        :disabled="isDisabledScanDevice(device)"
                        outlined
                      >
                        <strong>{{ device.title }}</strong
                        >: {{ device.ip }}
                        <v-tooltip v-if="device.stream" top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              :color="
                                allDevices.includes(device.ip) ? 'success' : ''
                              "
                              @click.prevent.stop="showIp(device.ip)"
                            >
                              <v-icon>mdi-video-account</v-icon>
                            </v-btn>
                          </template>
                          {{ $ml.get("devices.viewStream") }}
                        </v-tooltip>
                      </v-chip>
                    </v-chip-group>

                    <CameraBox
                      v-if="isCameraShow"
                      @toggle="isCameraShow = $event"
                      :devices="netips"
                      :showing="isCameraShow"
                      :showcontrol="false"
                    />
                  </template>
                </template>
              </v-col>
            </v-row>
          </v-col>
          <v-app v-if="zonedialog">
            <ModalZoneEdit
              @toggle="zoneDialogClose($event)"
              v-model="editZoneItem"
              :zones="zones"
              :parent="zoneActive"
            />
          </v-app>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row justify="center">
      <v-dialog
        v-if="selectedDeviceErrorLogs"
        :value="true"
        max-width="700px"
        @click:outside="closeDeviceErrorLogs"
      >
        <ModalViewErrorLogs
          :id="selectedDeviceErrorLogs"
          @close="closeDeviceErrorLogs"
        />
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import ModalZoneEdit from "./ModalZoneEdit";
import ModalViewErrorLogs from "./ModalViewErrorLogs";
import DataTable from "@/components/datatable";
import CameraBox from "@/components/CameraBoxOld";
import VuePagination from "@/components/pagination";
import router from "@/router";
import moment from "moment-timezone";
import RadialProgress from "@/components/RadialProgress";

// Constants
const DISABLED_ALL = "DISABLED_ALL";
const DISABLED_UFACE = "DISABLED_UFACE";
const DISABLED_CONTRL = "DISABLED_CONTRL";
const UFACE = "UFACE";
const OGATE = "OGATE";
const CONTRL = "CONTRL";
const OGATEV8 = "OGATEV8";
const OGATEV9 = "OGATEV9";
const OGATES = "OGATES";
const OMINI = "OMINI";
const ROBOSTRAZH = "ROBOSTRAZH";
const UFACEX = "UFACEX";
const OGATEX = "OGATEX";
const CONTROLLER = "CONTROLLER";
const DATE_FORMAT = "DD.MM.YY HH:mm:ss";
const SYNC_DEVICE_EVENT = "syncdevice";
const LOAD_FEATURES_EVENT = "loadfeatures";

const LS_DEVICES_LIMIT = "devicesLimit";

export default {
  name: "DeviceList",

  components: {
    DataTable,
    ModalZoneEdit,
    ModalViewErrorLogs,
    CameraBox,
    VuePagination,
    RadialProgress,
  },

  data() {
    const { page, zoneActiveId } = this.$route.query;
    const limit = localStorage.getItem(LS_DEVICES_LIMIT) || 10;

    let fields = [
      {
        name: "avatar",
        style: "width: 80px; margin-right: 10px;",
        type: "img",
        title: "deviceType",
        header: { style: "width: 100px" },
      },
      {
        group: [
          { name: "name", style: "font-size: 12px; font-weight: bold;" },
          {
            name: "zone_name",
            style: "display: block; font-size: 10px; margin-right: 3px;",
          },
          { name: "deviceType", style: "font-size: 12px;" },
        ],
        header: { text: this.$ml.get("devices.nameDevice") },
      },
      {
        name: "gate_command",
        header: {
          text: this.$ml.get("devices.gateCommand"),
        },
      },
      {
        group: [
          { name: "purposeText", style: "padding-left:10px;" },
          {
            name: "controlMode",
            style:
              "font-size: 10px; float: left; margin-right: 3px; padding-left:10px;",
          },
        ],
        header: { text: this.$ml.get("devices.purpose") },
        style: "width: 100px; padding-left:10px;",
      },
      {
        group: [
          {
            name: "ip_address",
            style: "width: 120px; padding-left:8px;",
          },
          {
            name: "pipeline_time",
            text: this.$ml.get("devices.timeDevice"),
            title: this.$ml.get("devices.timeDevice"),
            style:
              "font-size: 10px; float: left; margin-right: 3px; padding-left:8px;",
          },
        ],
        header: { text: this.$ml.get("devices.ipDevice") },
        style: "width: 140px; padding-left:8px;",
      },
    ];

    if (this.isDeviceControl()) {
      fields = [
        {
          name: "_select",
          type: "checkbox",
          class: "check-select",
          style: "width: 50px;",
          click: this.select,
          header: {
            class: "check-select",
            type: "checkbox",
            click: this.headerSelect,
            value: "",
          },
        },
        ...fields,
      ];
    }

    if (this.isDeviceEdit()) {
      fields = [
        ...fields,
        {
          type:
            this.$auth.user().type_user == "admin" ||
            this.$auth.user().roles.includes("deviceedit")
              ? "button"
              : "none",
          title: this.$ml.get("devices.configureDevice"),
          class: "btn btn-primary v-icon mdi mdi-cog",
          click: this.edit,
          style: "width: 40px; margin-right: 2px;",
        },
      ];
    }

    // Sync
    fields = [
      ...fields,
      {
        type: "button",
        title: this.$ml.get("devices.syncroDevice"),
        class: "btn btn-default v-icon mdi mdi-reload",
        click: this.syncro,
        style: "width: 40px; margin-left: 2px;",
      },
    ];

    // Error logs
    fields = [
      ...fields,
      {
        name: "error_logs",
      },
    ];

    return {
      isPending: false,
      limit: parseInt(limit),
      selectedDevices: [],
      devlist: [
        OGATEV8,
        OGATEV9,
        OGATES,
        UFACE,
        UFACEX,
        OMINI,
        OGATEX,
        CONTROLLER,
        ROBOSTRAZH,
      ],
      loading: false,
      zoneActive: {},
      zoneActiveId: zoneActiveId || null,
      editZoneItem: {},
      selectDevices: [],
      fields,
      sync: {},
      zonedialog: false,
      devices: {
        pagination: {
          total: 0,
          per_page: 2,
          from: 1,
          to: 10,
          current_page: page || 1,
        },
        data: [],
      },
      allDevices: [],
      syncDeviceData: {},
      zones: [],
      zons: [],
      stat: {},
      tree: [],
      addresses: [],
      isCameraShow: false,
      netips: { data: [{ ip_address: "", purpose: "all", name: "", id: 1 }] },
      selectedDeviceErrorLogs: null,

      syncDeviceInterval: null,
    };
  },

  methods: {
    headerSelect(val, e, items) {
      //val = e.target.checked;
      items = items || [];
      items.forEach((item) => {
        item["_select"] = e;
      });
    },
    select(item, e) {
      item["_select"] = !item["_select"];
      e.target.checked = item["_select"];
    },
    deselect() {
      this.devices.data.forEach((item) => {
        item["_select"] = false;
      });
    },
    getSelectCount() {
      let count = 0;
      let selectDevices = [];
      this.devices.data.forEach((item) => {
        if (item["_select"]) {
          selectDevices.push(item.id);
          count++;
        }
      });
      this.fields[0].header.type = this.devices.data.length ? "checkbox" : null;
      this.fields[0].header.value = this.devices.data.length == count;
      this.selectDevices = selectDevices;
      return count;
    },

    emergency(command) {
      if (this.selectDevices.length) {
        this.selectDevices.forEach((id) => {
          const deviceDataIndex = this.devices.data.findIndex(
            (item) => item.id == id
          );
          const deviceGateCommand =
            this.devices.data[deviceDataIndex].monitoring.gate_command;

          if (deviceGateCommand && deviceGateCommand.length) {
            const newCommand = deviceGateCommand.map(() => command);
            this.changeGateCommand(id, newCommand);
          }
        });
      }
    },

    changeGateCommand(id, command) {
      const params = {
        command,
      };

      this.axios
        .put(`device/${id}/control`, params)
        .then(() => {
          this.devices.data.forEach((item) => {
            if (item.id == id) {
              item.monitoring.gate_command = command;
              item._select = false;
              this.$notify({
                group: "info",
                type: "success",
                text:
                  this.$ml.get("devices.setNewContolMode") + " " + item.name,
              });
            }
          });
        })
        .catch((error) => {
          this.devices.data.forEach((item) => {
            if (item.id == id) {
              item._select = false;
              this.$notify({
                group: "info",
                type: "error",
                text: error.response.data.status.message + " - " + item.name,
              });
            }
          });
        });
    },

    getArrayGateCommand(arr, index, status) {
      const newCommand = arr.map((item, i) => {
        if (i == index) {
          return status;
        }
        return item;
      });

      return newCommand;
    },

    ogateReboot() {
      if (this.selectDevices.length) {
        this.selectDevices.forEach((id) => {
          this.axios
            .put(`device/${id}/reboot`, {})
            .then(() => {
              this.devices.data.forEach((item) => {
                if (item.id == id) {
                  item.monitoring.system_state = status;
                  item._select = false;
                  this.$notify({
                    group: "info",
                    type: "success",
                    text:
                      this.$ml.get("devices.setNewOgateReboot") +
                      " " +
                      item.name,
                  });
                }
              });
            })
            .catch((error) => {
              this.devices.data.forEach((item) => {
                if (item.id == id) {
                  item._select = false;
                  this.$notify({
                    group: "info",
                    type: "error",
                    text: `${error.response.data.status.message} - ${item.name}`,
                  });
                }
              });
            });
        });
      }
    },
    ogateStatus(status) {
      if (this.selectDevices.length) {
        const params = {
          state: status,
        };

        this.selectDevices.forEach((id) => {
          this.axios
            .put(`device/${id}/state`, params)
            .then(() => {
              this.devices.data.forEach((item) => {
                if (item.id == id) {
                  item.monitoring.system_state = status;
                  item._select = false;
                  this.$notify({
                    group: "info",
                    type: "success",
                    text:
                      this.$ml.get("devices.setNewOgateStatus") +
                      " " +
                      item.name,
                  });
                }
              });
            })
            .catch((error) => {
              this.devices.data.forEach((item) => {
                if (item.id == id) {
                  item._select = false;
                  this.$notify({
                    group: "info",
                    type: "error",
                    text: `${error.response.data.status.message} - ${item.name}`,
                  });
                }
              });
            });
        });
      }
    },

    biometricDisable(isDisable) {
      if (this.selectDevices.length) {
        const params = {
          disable: isDisable,
        };

        this.selectDevices.forEach((id) => {
          this.axios
            .put(`device/${id}/facestate`, params)
            .then(() => {
              this.devices.data.forEach((item) => {
                if (item.id == id) {
                  item._select = false;
                  this.$notify({
                    group: "info",
                    type: "success",
                    text:
                      this.$ml.get("devices.setNewOgateStatus") +
                      " " +
                      item.name,
                  });
                }
              });
            })
            .catch((error) => {
              this.devices.data.forEach((item) => {
                if (item.id == id) {
                  item._select = false;
                  this.$notify({
                    group: "info",
                    type: "error",
                    text: `${error.response.data.status.message} - ${item.name}`,
                  });
                }
              });
            });
        });
      }
    },

    syncDevice(val) {
      let devs = this.devices.data;
      this.devices.data = [];
      devs.forEach((item) => {
        if (val.id == item.id) {
          if (val.status != undefined) {
            item.monitoring.status = val.status;
          }
          if (val.pipeline_time) {
            item.pipeline_time = this.$moment(val.pipeline_time).format(
              DATE_FORMAT
            );
          }
          if (val.person_num && val.progress == 100) {
            item.monitoring.person_num = val.person_num;
          }
          if (val.gate_command) {
            item.monitoring.gate_command = val.gate_command;
          }
          if (val.system_state) {
            item.monitoring.system_state = val.system_state;
          }
        }
        if (
          item.device_type.includes("UFACE") ||
          item.device_type.includes("CONTRL") ||
          item.device_type == "ROBOSTRAZH"
        ) {
          item.controlMode = null;
        }
        this.devices.data.push(item);
      });
    },
    loadFeatures: function (val) {
      this.setProgress(val);
      let devs = this.devices.data;
      this.devices.data = [];
      devs.forEach((item) => {
        this.devices.data.push(item);
      });
    },

    // Добавить устройства из списка
    async addSelectDev() {
      if (this.selectedDevices[0]) {
        let promises = [];

        switch (this.selectedDevices[0].type) {
          case UFACE:
            this.$router.push({
              name: "addDevice",
              query: { device: UFACE },
              params: { device: this.selectedDevices[0] },
            });
            break;

          case CONTRL:
            this.$router.push({
              name: "addDevice",
              query: { device: CONTRL },
              params: { device: this.selectedDevices[0] },
            });
            break;

          default:
            promises = this.selectedDevices.map((device) =>
              this.addOneDevice(device.ip)
            );
            await Promise.all(promises);
            this.selectedDevices = [];
            this.getDevices();
            this.getAllDevices();
            break;
        }
      }
    },

    addOneDevice(ip) {
      const params = {
        timestamp: moment().unix(),
        name: ip,
        purpose: "entrance",
        ip_address: ip,
        enabled: true,
        data: JSON.stringify({
          temperature_enable: "off",
          recognition_range: 2,
          control_mode: "only_led",
          card_type: "",
          pass_timeout: 2,
          system_mode: "default",
          recognition_face: 0.7,
          recognition_mask: 0.62,
          mask_enabled: false,
          show_name: "short",
          show_themp_info: false,
          show_access: false,
          antispoofing_mode: "print",
          display_mode: "static",
          autoregistration_mode: false,
        }),
        channels: [
          {
            channel: 0,
            purpose: "entrance",
            zones_id: this.zoneActiveId || 1,
            source_id: null,
            enable: true,
          },
        ],
      };

      return this.axios
        .post("device/add", params)
        .then((response) => {
          if (response.data.success == false) {
            this.errorMessage = response.data.message;
          }
        })
        .catch(() => {
          this.errorMessage = this.$ml.get("devices.deviceExist");
        });
    },
    scan() {
      this.addresses = [];
      this.loading = true;
      this.axios("device/scan").then((response) => {
        this.addresses = response.data.data ? response.data.data : [];
        localStorage.setItem(
          "terminal.devices.addr",
          JSON.stringify(this.addresses)
        );
        this.loading = false;
      });
    },
    showIp(ip) {
      this.netips.data[0].ip_address = ip;
      this.netips.data[0].name = ip;
      this.isCameraShow = true;
    },
    isZoneEdit() {
      return (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().roles.includes("zoneedit")
      );
    },
    isDeviceEdit() {
      return (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().roles.includes("deviceedit")
      );
    },
    isDeviceControl() {
      return (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().roles.includes("devicecontrol")
      );
    },
    zoneDialogClose(val) {
      this.zonedialog = val;
      this.updateZone();
      this.getZones();
    },
    selectZone(item) {
      this.zoneActive = item;
      this.zoneActiveId = item.id;
      this.devices.pagination.current_page = 1;
      this.setQuery();
      this.getDevices();
    },
    addZone() {
      if (!this.isZoneEdit()) return;
      this.editZoneItem = {
        id: 0,
        parent_id: this.zoneActive.id || null,
        name: "",
      };
      this.zonedialog = true;
    },
    editZone() {
      if (!this.isZoneEdit() || this.zoneActive.id === null) return;
      this.editZoneItem = this.zoneActive;
      this.zonedialog = true;
    },
    deleteZone() {
      if (!this.isZoneEdit()) return;
      let options = {
        loader: false,
        okText: this.$ml.get("button.delete"),
        cancelText: this.$ml.get("button.cancel"),
        backdropClose: true,
      };
      let _this = this;
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>` +
            this.$ml.get("message.confirmDelete"),
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(() => {
          this.axios.delete("/zone/" + _this.zoneActive.id).then((response) => {
            if (response.status == 200) {
              if (response.data.success == false) {
                _this.errorMessage = response.data.message;
              } else {
                _this.getZones();
              }
            }
          });
        });
    },
    setProgress(val) {
      this.devices.data.forEach((el) => {
        if (el.id == val.id) {
          this.sync[el.id] = Math.round(val.proc * val.total);
          if (val.person_num) {
            el.monitoring.person_num = val.person_num;
          }

          this.sync[el.id]++;
          el.progress = Math.round((this.sync[el.id] / val.total) * 100);

          if (el.progress > 100) el.progress = 100;
        }
      });
    },
    syncro(item, event) {
      if (item["purpose"] == "off") return;
      const params = {
        timestamp: moment().unix(),
      };
      $(event.target).attr("disabled", true);
      this.sync[item.id] = 0;

      this.axios
        .post(`device/${item.id}/sync`, params)
        .then((response) => {
          if (response.status == 200) {
            $(event.target).attr(
              "class",
              "btn btn-success v-icon mdi mdi-reload"
            );
            $(event.target).attr("disabled", false);
          }
        })
        .catch((err) => {
          item.status = false;
          $(event.target).attr("disabled", false);
        });
    },
    styleRound(item) {
      if (item["purpose"] == "off" || !item.enabled) {
        return 0;
      }
      if (item.progress) {
        return item.progress;
      }
      if (item.monitoring.status) {
        return 100;
      } else {
        return 0;
      }
    },

    styleRow(item) {
      if (item["purpose"] == "off" || !item.enabled) {
        return "background: #EFEFEF; color: #989898;";
      }
      if (item.progress) {
        return (
          "background: linear-gradient(90deg, rgba(166, 234, 166,1) " +
          item.progress +
          "%, rgba(166, 234, 166,1) 0%, rgba(225,225,225,0.2) " +
          item.progress +
          "%);"
        );
      }
      if (item.monitoring.status) {
        return "background: #a6eaa6; color: #000000;";
      } else {
        return "background: #EFEFEF; color: #989898;";
      }
    },
    edit(item) {
      if (!this.isDeviceEdit()) return;
      router.push({
        name: "editDevice",
        params: {
          id: item.id,
          query: {
            page: this.devices.pagination.current_page,
            zoneActiveId: this.zoneActiveId,
          },
        },
      });
    },
    getAllDevices() {
      this.allDevices = [];
      this.axios("devices/ip").then((response) => {
        this.allDevices = response.data.data || [];
      });
    },

    onChangeItemCount(value) {
      this.limit = value;
      localStorage.setItem(LS_DEVICES_LIMIT, value);
      this.devices.pagination.current_page = 1;
      this.getDevices();
    },

    getDevices() {
      this.isPending = true;
      const params = {
        page: this.devices.pagination.current_page,
        limit: this.limit,
      };
      if (this.zoneActiveId) {
        params.zone = this.zoneActiveId;
      }

      this.devices.data = [];

      return this.axios("devices", { params })
        .then((response) => {
          // если текущая страница больше общего количества страниц, то переходим на последнюю
          if (
            response.data.meta.pagination.current_page >
            response.data.meta.pagination.total
          ) {
            this.devices.pagination.current_page =
              response.data.meta.pagination.total;
            this.getDevices();
          } else {
            let devices = response.data.data ? response.data.data : [];
            this.devices.data = [];
            this.devices.pagination = response.data.meta.pagination;
            devices.forEach((item) => {
              item._select = false;
              item.zone_name = this.zons[item.zones_id];
              if (item.device_type != "ROBOSTRAZH") {
                const purposeArr = item.purpose
                  .split(",")
                  .map((purpose) =>
                    purpose ? this.$ml.get("button." + purpose) : null
                  );
                item.purposeText = purposeArr.join(", ");
              } else {
                item.purpose = null;
              }

              item.controlMode = "";
              item.progress = 0;
              if (item.data) {
                let data = JSON.parse(item.data);
                item.controlMode = data["control_mode"] || "no";
                item.deviceType = data["device_type"]
                  ? data["device_type"].toUpperCase()
                  : OGATEV9;
                let dt = item.deviceType.split(".");
                let deviceType = dt[0] || "OGATE8";

                if (
                  item.device_type.includes("UFACE") ||
                  item.device_type.includes("CONTRL") ||
                  item.device_type == "ROBOSTRAZH"
                ) {
                  item.controlMode = null;
                }

                item.temperatureEnable = data["temperature_enable"] == "on";
                if (!deviceType || !this.devlist.includes(deviceType)) {
                  item.deviceIcon = require("@/assets/img/devices/unknown.png");
                } else if (
                  (deviceType == OGATEV8 || deviceType == OGATEV9) &&
                  item.temperatureEnable
                ) {
                  item.deviceIcon = require("@/assets/img/devices/" +
                    deviceType.toLowerCase() +
                    "-t.png");
                  item.deviceType += " Thermo";
                } else {
                  item.deviceIcon = require("@/assets/img/devices/" +
                    deviceType.toLowerCase() +
                    ".png");
                }
              }
              this.devices.data.push(item);
            });
          }
          this.isPending = false;
        })
        .catch(() => (this.isPending = false));
    },

    openDeviceErrorLogs(e) {
      this.selectedDeviceErrorLogs = e.id;
    },

    closeDeviceErrorLogs() {
      this.selectedDeviceErrorLogs = null;
    },

    getIcon() {
      return '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M16 8a5 5 0 1 0 5 5a5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3a3.003 3.003 0 0 1-3 3z" fill="#626262"/><path d="M16 2a14 14 0 1 0 14 14A14.016 14.016 0 0 0 16 2zm-6 24.377V25a3.003 3.003 0 0 1 3-3h6a3.003 3.003 0 0 1 3 3v1.377a11.899 11.899 0 0 1-12 0zm13.992-1.451A5.002 5.002 0 0 0 19 20h-6a5.002 5.002 0 0 0-4.992 4.926a12 12 0 1 1 15.985 0z" fill="#626262"/></svg>';
    },
    getTree(items, parent) {
      let result = [];
      items.forEach((el) => {
        if (parent == el.parent_id) {
          let item = {
            id: el.id,
            name: el.name,
            parent_id: el.parent_id,
            data: el.data,
          };
          let children = this.getTree(items, el.id);
          if (children) {
            item.children = children;
          }
          result.push(item);
        }
      });
      return result;
    },
    getZones() {
      return this.axios("zones").then((response) => {
        if (response.status == 200) {
          let parents = [];
          this.zones = response.data.data;
          this.zones.forEach((el) => {
            if (!el.name) el.name = "empty";
            this.zons[el.id] = el.name;
            parents[el.id] = el.parent_id;
          });
          this.tree = this.getTree(this.zones, null);
          const allZones = {
            id: null,
            parent_id: null,
            name: this.$ml.get("devices.all_zones"),
            data: null,
          };

          this.tree.splice(0, 0, allZones);
          // if (this.devices.pagination.current_page > 1) {
          //   this.$router.push({ path: `/devices` });
          //   this.devices.pagination.current_page = 1;
          // }
        }
      });
    },
    updateZone() {
      return this.axios(`zone/${this.zoneActiveId}`).then((response) => {
        this.selectZone(response.data.data);
      });
    },
    setActiveStyle() {
      let e = $(".v-treeview-node__root a.active");
      if (e !== null) {
        $(".v-treeview-node__root").css("background-color", "#fff");
        let el = $(e).closest(".v-treeview-node__root");
        el.css("background-color", "#e2e2e2");
      }
    },
    setTreeActiveClass(item) {
      if (item.id == this.zoneActiveId) {
        return "active";
      }
    },

    onChangePage() {
      this.getDevices();
    },

    isDisabledScanDevice(device) {
      if (!this.selectedDevices.includes(device)) {
        if (this.allDevices.includes(device.ip)) {
          return true;
        }

        if (this.disabledScanDeviceBySelected.includes(DISABLED_ALL)) {
          return true;
        }

        if (
          this.disabledScanDeviceBySelected.includes(DISABLED_UFACE) &&
          device.type === UFACE
        )
          return true;
      }

      if (
        this.disabledScanDeviceBySelected.includes(DISABLED_CONTRL) &&
        device.type === CONTRL
      ) {
        return true;
      }

      return false;
    },

    getMonitoringDevices() {
      this.axios("devices/monitoring").then((response) => {
        response.data.data.forEach((item) => {
          this.syncDevice(item);
        });
      });
    },

    connectWebSockets() {
      this.syncDeviceInterval = setInterval(() => {
        this.getMonitoringDevices();
      }, 10000);

      this.$root.$on(LOAD_FEATURES_EVENT, (item) => {
        this.loadFeatures(item);
      });
    },

    disconnectedWebSockets() {
      clearInterval(this.syncDeviceInterval);
      this.$root.$off(LOAD_FEATURES_EVENT);
    },

    onDelete() {
      if (this.selectDevices.length) {
        const ids = this.selectDevices.map((id) => id);

        this.axios
          .post("devices/delete", { ids })
          .then(() => {
            this.$notify({
              group: "info",
              type: "success",
              text:
                ids.length > 1
                  ? this.$ml.get("message.devicesDeleteSuccess")
                  : this.$ml.get("message.deviceDeleteSuccess"),
            });

            this.getDevices();
            this.getAllDevices();
            this.scan();
          })
          .catch(() => {
            this.$notify({
              group: "info",
              type: "error",
              text: this.$ml.get("message.deleteError"),
            });
          });
      }
    },

    setQuery() {
      const { zoneActiveId } = this;

      this.$router.replace({
        name: "devices",
        query: {
          zoneActiveId,
        },
      });
    },
  },

  computed: {
    disabledScanDeviceBySelected() {
      let result = "";

      if (this.selectedDevices[0]) {
        switch (this.selectedDevices[0].type) {
          case OGATE:
            result = [DISABLED_UFACE, DISABLED_CONTRL];
            break;
          case UFACE:
          case CONTRL:
            result = [DISABLED_ALL];
            break;
          default:
            break;
        }
      }

      return result;
    },
  },

  watch: {
    stat(val) {
      this.stat = val;
    },
  },

  destroyed() {
    this.disconnectedWebSockets();
  },

  created() {
    const addr = localStorage.getItem("terminal.devices.addr") || "[]";
    this.addresses = JSON.parse(addr);
    this.getZones();
    this.scan();
    this.getAllDevices();
    this.getDevices();

    this.connectWebSockets();
    this.setActiveStyle();
  },
};
</script>
