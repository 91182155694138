<template>
  <div>
    <UserEdit :userId="id" />
  </div>
</template>
<script>
import UserEdit from "./users/UserEdit";
export default {
  data() {
    return {
      id: this.$route.params.id,
    };
  },
  components: {
    UserEdit,
  },
};
</script>
