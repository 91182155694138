<template>
  <div v-if="show" class="right-panel" ref="rpanel">
    <div>
      <img :src="value.profile" alt="" />
    </div>
    <h5 class="visitor-name">{{ value.fio }}</h5>
    <div>
      <label>{{ value.companyName }}</label>
    </div>
    <button @click="close">Close</button>
  </div>
</template>

<script>
export default {
  props: ["value", "autoclose"],
  name: "UserCard",
  data() {
    return {
      show: false,
      tooltip: false,
    };
  },
  methods: {
    close() {
      this.value = false;
      this.show = false;
    },
  },
  watch: {
    value(value) {
      if (value) {
        this.show = true;
        var _this = this;
        if (this.autoclose) {
          //this.$refs.rpanel.style="";
          setTimeout(function () {
            // _this.$refs.rpanel.style="opacity: 0;\n" +
            //     "  visibility: hidden;\n" +
            //     "  transition: opacity 0.6s, visibility 0s linear 0.6s;"
            _this.show = false;

            _this.value = false;
          }, this.autoclose);
        }
      } else {
        this.show = false;
      }
    },
  },
};
</script>

<style>
.right-panel {
  padding: 15px;
  background: #fff;
  width: 350px;
  position: fixed;
  right: 0;
  top: 80px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.right-panel img {
  width: 100%;
}
.right-panel .visitor-name {
  margin-top: 20px;
}
</style>
