export default {
  init({ commit }) {
    let isMenuMinimize = false;

    if (localStorage.getItem("isMenuMinimize") === "true") {
      isMenuMinimize = true;
    }

    commit("setMenuSize", isMenuMinimize);
  },

  toggleMenuSize({ commit, state }) {
    const isMenuMinimize = !state.isMenuMinimize;
    localStorage.setItem("isMenuMinimize", isMenuMinimize);
    commit("setMenuSize", isMenuMinimize);
  },
};
