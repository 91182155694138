<template>
  <v-row>
    <v-col cols="6">
      <v-select
        v-model="userTypeState"
        :items="userTypeOptions"
        outlined
        clearable
        hide-details
        dense
        :label="$ml.get('users.userType')"
        @change="onChange"
        :disabled="isDisabled"
      />
    </v-col>

    <v-col cols="6">
      <v-select
        v-model="zoneIdState"
        :items="zoneIdOptions"
        outlined
        clearable
        hide-details
        dense
        :label="$ml.get('guard.zones')"
        @change="onChange"
        :disabled="isDisabled"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    typeUser: {
      type: String,
      default: null,
    },

    zoneId: {
      type: Number,
      default: null,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      userTypeState: this.typeUser,
      userTypeOptions: [
        {
          value: "guest",
          text: this.$ml.get("requests.guest"),
        },
        {
          value: "employee",
          text: this.$ml.get("requests.employee"),
        },
        {
          value: "manager",
          text: this.$ml.get("users.manager"),
        },
      ],

      zoneIdState: this.zoneId,
      zoneIdOptions: [],
    };
  },

  methods: {
    onChange() {
      this.$emit("change", {
        typeUser: this.userTypeState,
        zoneId: this.zoneIdState,
      });
    },

    getZones() {
      this.axios("zones").then((response) => {
        const userZones = this.$auth.user().zones;

        const zones = response.data.data.map((zone) => ({
          value: zone.id,
          text: zone.name,
        }));

        if (userZones) {
          this.zoneIdOptions = zones.filter((zone) =>
            userZones.includes(zone.value)
          );
        } else {
          this.zoneIdOptions = zones;
        }
      });
    },
  },

  created() {
    this.getZones();
  },
};
</script>
