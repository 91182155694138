<template>
  <DataTable
    :fields="fields"
    :rows="data"
    :click="onClick"
    :dblclick="onDblClick"
    :rowstyle="rowstyle"
    :isLoading="isLoading"
  >
    {{ $ml.get("users.no_passes_registered") }}

    <template #[`item.avatar`]="{ item }">
      <img
        :src="item.avatar"
        style="width: 50px; border-radius: 50%"
        @mouseenter="item.isHasPhoto ? onHoverImg(item.photo) : null"
        @mouseleave="onLeaveHoverImg"
      />
    </template>

    <template #[`item.meta`]="{ item }">
      <span>
        <v-tooltip v-if="item?.event?.escort_id" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="primary"
              >mdi-account-supervisor-circle-outline</v-icon
            >
          </template>
          <span>
            {{ $ml.get("user.escort") }} <br />
            {{ item.event.escort_name }} <br v-if="item.event.escort_name" />
            {{ $ml.get(`users.${item.event.escort_identifier}`) }}
          </span>
        </v-tooltip>
      </span>
    </template>

    <template #[`item.identity_types`]="{ item }">
      <span>
        <v-tooltip
          v-if="
            item.purposeType === 'entrance' || item.purposeType === 'duplex'
          "
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="success"
              >mdi-location-enter</v-icon
            >
          </template>
          <span>{{ $ml.get("device.purpose_entrance") }}</span>
        </v-tooltip>
        <v-tooltip v-if="item.purposeType === 'camera'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mirror-x mr-1"
              v-bind="attrs"
              v-on="on"
              small
              color="primary"
              >mdi-cctv</v-icon
            >
          </template>
          <span>{{ $ml.get("device.purpose_camera") }}</span>
        </v-tooltip>
        <v-tooltip v-if="item.purposeType === 'exit'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              color="error"
              class="rotate-180"
              >mdi-location-exit</v-icon
            >
          </template>
          <span>{{ $ml.get("device.purpose_exit") }}</span>
        </v-tooltip>
        <v-tooltip v-if="item.purposeType === 'basiclock'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="warning"
              >mdi-key</v-icon
            >
          </template>
          <span>{{ $ml.get("device.purpose_basicLock") }}</span>
        </v-tooltip>
      </span>
      <span v-for="type in item.identity_types" :key="type">
        <v-tooltip v-if="type === 'face'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              :color="item.blacklisted ? 'white' : ''"
            >
              mdi-emoticon-happy-outline
            </v-icon>
          </template>
          <span>{{ $ml.get("users.face") }}</span>
        </v-tooltip>

        <v-tooltip v-if="type === 'qr'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              :color="item.blacklisted ? 'white' : ''"
            >
              mdi-qrcode-scan
            </v-icon>
          </template>
          <span>{{ $ml.get("users.qr") }}</span>
        </v-tooltip>

        <v-tooltip v-if="type === 'card'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              :color="item.blacklisted ? 'white' : ''"
            >
              mdi-card-bulleted-outline
            </v-icon>
          </template>
          <span>{{ $ml.get("users.card") }}</span>
        </v-tooltip>
      </span>
    </template>
  </DataTable>
</template>

<script>
import DataTable from "@/components/datatable";

export default {
  components: { DataTable },

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    rowstyle: Function,
  },
  data() {
    return {
      fields: [
        {
          name: "meta",
          style: "width: 16px",
        },
        {
          name: "identity_types",
          style: "width: 16px",
        },
        {
          name: "avatar",
          style: "width: 50px;",
        },
        {
          group: [
            {
              name: "full_name",
              title: this.$ml.get("users.fullname"),
              btag: "",
              atag: "",
            },
            {
              name: "purpose",
              title: this.$ml.get("users.device"),
              style: "font-size: 13px; float: left; margin-right: 3px;",
            },
            {
              name: "utype",
              title: this.$ml.get("users.status"),
              btag: " / ",
              style: "font-size: 13px; float: left;",
            },
            {
              name: "temperature",
              title: this.$ml.get("users.temperature"),
              btag: "",
              style: "font-size: 13px; float: left; margin-left: 3px;",
            },
          ],
          header: { text: this.$ml.get("users.visitor") },
        },
        {
          name: "visits",
          header: { text: this.$ml.get("users.visit_time") },
          style: "width: 50px;",
        },
      ],
    };
  },

  methods: {
    onClick(item) {
      this.$emit("click", item);
    },

    onDblClick(item) {
      this.$emit("dblclick", item);
    },

    onHoverImg(img) {
      this.$emit("hoverImg", img);
    },

    onLeaveHoverImg(img) {
      this.$emit("leaveHoverImg", img);
    },
  },
};
</script>
