<template>
  <v-alert v-if="message" type="error">
    <v-row align="center">
      <v-col class="grow">{{ message }}</v-col>
      <v-col v-if="userId" class="shrink">
        <v-btn
          :to="{
            name: routerName,
            params: { id: userId },
          }"
          color="red darken-1"
          target="_blank"
          depressed
          small
          >{{ $ml.get("requests.toUserPage") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      require: true,
    },
    userId: {
      type: String,
      require: true,
    },
    routerName: {
      type: String,
      default: "showUser",
    },
  },
};
</script>
