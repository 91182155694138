<template>
  <v-container>
    <v-app>
      <v-card elevation="2">
        <v-card-text>
          <v-alert v-if="isFailure && errorMessage" type="error">{{
            errorMessage
          }}</v-alert>

          <v-row class="mb-4">
            <v-col>
              <v-btn @click="goBack">{{ $ml.get("button.back") }}</v-btn>
            </v-col>
          </v-row>

          <Form
            ref="addForm"
            :user_id="form.user_id"
            :name="form.name"
            :phone="form.contacts.phone"
            :subdivisions="form.subdivisions"
            :department="form.contacts.department"
            :userType="form.contacts.user_type"
            :onepass="form.contacts.onepass"
            :local="local"
            @change="onChange"
          />

          <v-row justify="end">
            <v-col cols="auto">
              <v-btn
                color="primary"
                :loading="isPending"
                :disabled="isPending"
                @click="add"
                >{{ $ml.get("button.add") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-app>
  </v-container>
</template>

<script>
import Form from "./form";

export default {
  components: { Form },

  data() {
    const { user } = this.$route.params;

    let form = {
      subdivisions: [],
      contacts: {
        phone: "",
        department: null,
        user_type: "guest",
        onepass: true,
      },
    };

    if (user) {
      const subdivisions = user.access_groups.map(
        (subdivision) => subdivision.id
      );

      let user_type = user.type_user;
      if (user.type_user === "manager") {
        user_type = "employee";
      }

      form = {
        user_id: user.id,
        name: `${user.last_name} ${user.first_name} ${user.middle_name}`.trim(),
        subdivisions,
        contacts: {
          phone: user.phone || "",
          department: user.department?.id || null,
          user_type,
          onepass: JSON.parse(user.data)?.onepass || true,
        },
      };
    }

    return {
      local: {
        description: {
          guest: localStorage.getItem("requestGuestDescription") || "",
          employee: localStorage.getItem("requestEmployeeDescription") || "",
        },
        department: {
          guest:
            parseInt(localStorage.getItem("requestGuestDepartment")) || null,
          employee:
            parseInt(localStorage.getItem("requestEmployeeDepartment")) || null,
        },
        subdivisions: {
          guest:
            JSON.parse(localStorage.getItem("requestGuestSubdivisions")) || [],
          employee:
            JSON.parse(localStorage.getItem("requestEmployeeSubdivisions")) ||
            [],
        },
      },

      isPending: false,
      isSuccess: false,
      isFailure: false,
      errorMessage: "",
      form,
    };
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    onChange(form) {
      this.form = { ...this.form, ...form };
    },

    add() {
      const validate = this.$refs.addForm.$refs.form.validate();
      const validateSubdivisions = this.$refs.addForm.validateSubdivisions();
      const validateDepartments = this.$refs.addForm.validateDepartments();

      if (validate && validateSubdivisions && validateDepartments) {
        const { form } = this;

        this.isPending = true;
        this.isSuccess = false;
        this.isFailure = false;

        this.axios
          .post("requests", form)
          .then(() => {
            this.isPending = false;
            this.isSuccess = true;
            this.isFailure = false;

            switch (form.contacts.user_type) {
              case "guest":
                localStorage.setItem(
                  "requestGuestDepartment",
                  form.contacts.department
                );
                localStorage.setItem(
                  "requestGuestSubdivisions",
                  JSON.stringify(form.subdivisions)
                );
                localStorage.setItem(
                  "requestGuestDescription",
                  form.description
                );
                break;

              case "employee":
                localStorage.setItem(
                  "requestEmployeeDepartment",
                  form.contacts.department
                );
                localStorage.setItem(
                  "requestEmployeeSubdivisions",
                  JSON.stringify(form.subdivisions)
                );
                localStorage.setItem(
                  "requestEmployeeDescription",
                  form.description
                );
                break;

              default:
                break;
            }

            this.$router.push({
              name: "requests",
              query: this.$route.params.prevQuery,
            });
          })
          .catch((error) => {
            this.isPending = false;
            this.isSuccess = false;
            this.isFailure = true;
            this.errorMessage = error.response.data.status.message;
          });
      }
    },
  },
};
</script>
