import Vue from "vue";
import { MLInstaller, MLCreate, MLanguage } from "vue-multilanguage";
var ru = require("../lang/ru.json");
var en = require("../lang/en.json");
var currentLang = "en";

Vue.use(MLInstaller);

export default new MLCreate({
  initial: currentLang,
  save: process.env.NODE_ENV === "production",
  languages: [new MLanguage("en").create(en), new MLanguage("ru").create(ru)],
});
