<template>
  <v-app class="p-3">
    <v-row align="center">
      <v-col cols="12" md="8">
        <v-row justify="start" align="center">
          <v-col cols="auto">
            <RequestsFilter
              :status="status"
              :onePass="onePass"
              :onlyActive="onlyActive"
              :owner="owner"
              :userType="userType"
              :department="department"
              :defaultState="defaultFilterState"
              :isAlwaysSaveFilter="isAlwaysSaveFilter"
              @save="onChangeFilter"
              :isDisabled="isPending"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="
          $auth.user().type_user == 'admin' ||
          $auth.user().roles.includes('requests') ||
          $auth.user().roles.includes('requestsemployee')
        "
        cols="12"
        md="4"
      >
        <v-row justify="start" justify-md="end" align="center">
          <v-col cols="auto">
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary"
                  >{{ $ml.get("button.add") }}&nbsp;
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  link
                  :to="{
                    name: 'addRequest',
                    params: { prevQuery: this.$route.query },
                  }"
                >
                  <v-list-item-title>{{
                    $ml.get("requests.oneRequest")
                  }}</v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  :to="{
                    name: 'addMultiRequest',
                    params: { prevQuery: this.$route.query },
                  }"
                >
                  <v-list-item-title>{{
                    $ml.get("requests.multiRequest")
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Datatable
          :dblclick="goToEdit"
          :fields="fields"
          :searchfn="searchfn"
          :rows="data"
          :rowstyle="rowStyle"
          :searchParams="searchParams"
          :sortName="sortName"
          :sortOrder="sortOrder"
          :itemCount="limit"
          isChangeItemCount
          saveSearchHistory="requests"
          @changeItemCount="onChangeItemCount"
          @sort="onSort"
          :searchDisabled="isPending"
          :isLoading="isPending"
        >
          {{ $ml.get("requests.notRequests") }}
          <template v-slot:header></template>
          <template v-slot:footer>
            <vue-pagination
              :pagination="pagination"
              :offset="2"
              @paginate="onChangePage"
              :isDisabled="isPending"
            />
          </template>

          <template #[`item.name`]="{ item }">
            <v-tooltip v-if="item.contacts.user_type === 'guest'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small>
                  mdi-account-clock
                </v-icon>
              </template>
              <span>{{ $ml.get("requests.guest") }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.contacts.user_type === 'employee'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small> mdi-briefcase </v-icon>
              </template>
              <span>{{ $ml.get("requests.employee") }}</span>
            </v-tooltip>

            <template v-if="item.guest_id && item.status !== 4">
              <router-link
                :to="{ name: 'showUser', params: { id: item.guest_id } }"
              >
                {{ item.name }}
              </router-link>
            </template>
            <template v-else>
              {{ item.name }}
            </template>
          </template>

          <template #[`item.statusText`]="{ item }">
            <v-chip :color="getColorByStatus(item.status)" outlined>
              <span class="d-none d-sm-inline">
                {{ item.statusText }}
              </span>
              <v-icon class="d-inline d-sm-none">
                {{ getIconByStatus(item.status) }}
              </v-icon>
            </v-chip>
          </template>

          <template #[`item.departmentName`]="{ item }">
            {{ item.department_name }}
            <br />
            <small
              v-if="
                $auth.user().type_user == 'admin' ||
                $auth.user().type_user == 'guard'
              "
              class="grey--text lighten-1--text"
            >
              {{ item.owner_name }}
            </small>
          </template>

          <template #[`item.due`]="{ item }">
            <small>
              <span v-if="item.due_from">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-icon small> mdi-calendar-start </v-icon>
                      {{ item.due_from | moment("DD.MM.YYYY HH:mm") }}
                    </div>
                  </template>
                  <span>{{ $ml.get("requests.startDue") }}</span>
                </v-tooltip>
              </span>
              <span v-if="item.due_to">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      :class="[{ 'red--text': $moment().isAfter(item.due_to) }]"
                    >
                      <v-icon
                        small
                        :class="[
                          { 'red--text': $moment().isAfter(item.due_to) },
                        ]"
                      >
                        mdi-calendar-end
                      </v-icon>
                      {{ item.due_to | moment("DD.MM.YYYY HH:mm") }}
                    </div>
                  </template>
                  <span>
                    {{ $ml.get("requests.endDue") }}
                    <span v-if="$moment().isAfter(item.due_to)">
                      ({{ $ml.get("requests.overdue") }})
                    </span>
                  </span>
                </v-tooltip>
              </span>
            </small>
          </template>

          <template #[`item.requestType`]="{ item }">
            <v-tooltip v-if="item.requestType.includes(1)" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small>
                  mdi-link-variant
                </v-icon>
              </template>
              <span>{{ $ml.get("requests.byLink") }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.requestType.includes(0)" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small> mdi-tablet </v-icon>
              </template>
              <span>{{ $ml.get("requests.byKiosk") }}</span>
            </v-tooltip>
          </template>

          <template #[`item.requestConfirm`]="{ item }">
            <v-row no-gutters>
              <v-col v-if="isRequestConfirm(item.status)">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="green"
                      @click="confirmRequest(item.id)"
                    >
                      <v-icon> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $ml.get("requests.confirmRequest") }}</span>
                </v-tooltip>
              </v-col>
              <v-spacer />
              <v-col v-if="isRequestCancel(item)">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="red"
                      @click="cancelRequest(item.id)"
                    >
                      <v-icon> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $ml.get("requests.cancelRequest") }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </Datatable>
        <v-alert v-if="isFailure" border="top" color="red lighten-2" dark>
          {{ $ml.get("message.errorLoading") }}
        </v-alert>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
const REQUESTS_FILTER = "requestsFilter";
const LS_REQUESTS_LIMIT = "requestsLimit";

import Datatable from "@/components/datatable";
import VuePagination from "@/components/pagination";
import RequestsFilter from "./filter";

export default {
  components: { Datatable, VuePagination, RequestsFilter },

  data() {
    const limit = localStorage.getItem(LS_REQUESTS_LIMIT) || 10;

    const defaultFilterState = {
      status: [0, 1, 2],
      onePass: null,
      onlyActive: false,
      owner:
        this.$auth.user().type_user === "manager"
          ? this.$auth.user().admin_id
          : null,
      userType: null,
      department: null,
    };

    let filterState = {
      ...defaultFilterState,
    };

    const localStorageFilter = localStorage.getItem(
      `${REQUESTS_FILTER}-${this.$auth.user().admin_id}`
    );
    let isAlwaysSaveFilter = false;

    if (localStorageFilter) {
      filterState = {
        ...JSON.parse(localStorageFilter),
      };

      isAlwaysSaveFilter = true;
    }

    const {
      status,
      onePass,
      onlyActive,
      owner,
      userType,
      department,
      page,
      sortName,
      sortOrder,
      search,
    } = this.$route.query;

    if (status !== undefined) {
      if (Array.isArray(status)) {
        filterState.status = status.map((item) => parseInt(item));
      }

      if (typeof status === "string") {
        filterState.status = [parseInt(status)];
      }
    }

    if (onePass !== undefined && onePass !== null)
      filterState.onePass = onePass === "true";
    if (onlyActive !== undefined && onlyActive !== null)
      filterState.onlyActive = onlyActive === "true";
    if (owner !== undefined && owner !== null) filterState.owner = owner;
    if (userType !== undefined && userType !== null)
      filterState.userType = userType;
    if (department !== undefined && department !== null)
      filterState.department = department;

    return {
      isPending: false,
      isSuccess: false,
      isFailure: false,
      data: [],
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 30,
        current_page: page || 1,
      },
      search,
      limit: parseInt(limit),

      searchParams: [
        {
          value: "id",
          text: "ID",
        },
        {
          value: "name",
          text: this.$ml.get("user.name"),
        },
        {
          value: "email",
          text: this.$ml.get("user.email"),
        },
        {
          value: "tel",
          text: this.$ml.get("user.phone"),
        },
      ],

      // Sort
      sortName: sortName || "",
      sortOrder: sortOrder || "",

      // Filter
      isAlwaysSaveFilter,
      defaultFilterState,
      ...filterState,
    };
  },

  methods: {
    onChangeItemCount(value) {
      this.limit = value;
      localStorage.setItem(LS_REQUESTS_LIMIT, value);
      this.pagination.current_page = 1;
      this.getRequests();
    },

    getRequests() {
      const params = {
        owner_id: this.owner,
        page: this.pagination.current_page,
        limit: this.limit,
        search: this.search,
        excluded_status: this.status,
        onepass: this.onePass,
        onlyactive: this.onlyActive,
        user_type: this.userType,
        department: this.department,
      };

      if (this.sortName) {
        params.sortName = this.sortName;
        params.sortOrder = this.sortOrder || "asc";
      }

      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;

      this.axios("requests", { params })
        .then((response) => {
          this.isPending = false;
          this.isSuccess = true;
          this.isFailure = false;

          this.data = this.formattedData(response.data.data);
          this.pagination = response.data.meta.pagination;
        })
        .catch(() => {
          this.isPending = false;
          this.isSuccess = false;
          this.isFailure = true;
        });
    },

    searchfn(value) {
      this.search = value;
      this.pagination.current_page = 1;
      this.setQuery();
      this.getRequests();
    },

    formattedData(data) {
      const getStatus = (code) => {
        switch (code) {
          case 0:
            return this.$ml.get("requests.statusNew");
          case 1:
            return this.$ml.get("requests.statusWait");
          case 2:
            return this.$ml.get("requests.statusDone");
          case 3:
            return this.$ml.get("requests.statusCancel");
          case 4:
            return this.$ml.get("requests.statusUserDelete");
          default:
            break;
        }
      };

      const getRequestType = (requestType) => {
        const requestTypeSplit = String(requestType)
          .split("")
          .map((num) => {
            return Number(num);
          });

        return requestTypeSplit;
      };

      return data.map((item) => ({
        ...item,
        statusText: getStatus(item.status),
        requestType: getRequestType(item.request_type),
        createdAtFormatted: this.$moment(item.created_at).format(
          "DD.MM.YY HH:mm"
        ),
      }));
    },

    getColorByStatus(status) {
      switch (status) {
        case 0:
          return "blue";
        case 1:
          return "orange";
        case 2:
          return "green";
        case 3:
          return "";
        default:
          return "";
      }
    },

    getIconByStatus(status) {
      switch (status) {
        case 0:
          return "mdi-new-box";
        case 1:
          return "mdi-clock";
        case 2:
          return "mdi-check";
        case 3:
          return "mdi-cancel";
        case 4:
          return "mdi-account-remove";
        default:
          return "";
      }
    },

    goToEdit({ id, status }) {
      if (status !== 3 && status !== 4) {
        this.$router.push({
          name: "editRequest",
          params: { id, prevQuery: this.$route.query },
        });
      }
    },

    rowStyle({ status }) {
      if (status === 3 || status === 4) {
        return "background-color: #eee; color: #aaa; cursor: default";
      }
    },

    confirmRequest(id) {
      this.axios
        .put(`request/${id}/status`, {
          status: 1,
        })
        .then(() => {
          this.getRequests();
        });
    },

    cancelRequest(id) {
      this.axios
        .put(`request/${id}/status`, {
          status: 3,
        })
        .then(() => {
          this.getRequests();
        });
    },

    isRequestConfirm(status) {
      return (
        (this.$auth.user().type_user == "admin" ||
          this.$auth.user().roles.includes("reqconfirm")) &&
        status === 0
      );
    },

    isRequestCancel(item) {
      return (
        (this.$auth.user().type_user == "admin" ||
          this.$auth.user().admin_id === item.owner_id ||
          this.$auth.user().roles.includes("reqconfirm")) &&
        item.status !== 3 &&
        item.status !== 4
      );
    },

    onChangeFilter({
      status,
      onePass,
      onlyActive,
      owner,
      userType,
      department,
      isAlwaysSaveFilter,
    }) {
      this.status = status;
      this.onePass = onePass;
      this.onlyActive = onlyActive;
      this.owner = owner;
      this.userType = userType;
      this.department = department;
      this.pagination.current_page = 1;

      this.setQuery();
      this.saveFilterToLocalStorage(isAlwaysSaveFilter, {
        status,
        onePass,
        onlyActive,
        owner,
        userType,
        department,
      });
      this.getRequests();
    },

    saveFilterToLocalStorage(isSave, filter) {
      if (isSave) {
        localStorage.setItem(
          `${REQUESTS_FILTER}-${this.$auth.user().admin_id}`,
          JSON.stringify(filter)
        );
      } else {
        localStorage.removeItem(
          `${REQUESTS_FILTER}-${this.$auth.user().admin_id}`
        );
      }
    },

    onChangePage() {
      this.getRequests();
    },

    setQuery() {
      const {
        status,
        onePass,
        onlyActive,
        owner,
        userType,
        department,
        sortName,
        sortOrder,
        search,
      } = this;

      this.$router.replace({
        name: "requests",
        query: {
          status,
          onePass,
          onlyActive,
          owner,
          userType,
          department,
          sortName,
          sortOrder,
          search,
        },
      });
    },

    onSort({ name, order }) {
      this.sortName = name;
      this.sortOrder = order;

      this.getRequests();
    },
  },

  computed: {
    fields() {
      let fields = [
        {
          name: "id",
          header: { text: "ID" },
          style: "width: 50px;",
          sortBy: {
            name: "id",
          },
        },
        {
          name: "name",
          header: { text: this.$ml.get("requests.name") },
          sortBy: {
            name: "name",
          },
        },
        {
          name: "departmentName",
          header: { text: this.$ml.get("requests.department") },
        },
      ];

      if (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().type_user == "guard"
      ) {
        fields = [
          ...fields,
          {
            name: "due",
            style: "width: 120px",
            header: { text: this.$ml.get("requests.due") },
          },
        ];
      }

      fields = [
        ...fields,
        {
          name: "statusText",
          style: "width: 170px",
          header: { text: this.$ml.get("requests.status") },
        },
        {
          name: "createdAtFormatted",
          style: "width: 130px",
          header: { text: this.$ml.get("requests.date") },
          sortBy: {
            name: "created_at",
          },
        },
        {
          name: "requestType",
          style: "width: 30px",
        },
        {
          name: "requestConfirm",
          style: "width: 100px",
        },
      ];

      return fields;
    },
  },

  created() {
    this.getRequests();
  },
};
</script>
