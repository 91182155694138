import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  errorMessage: "",
  data: {},

  isPendingSendOnTerminal: false,
  isSuccessSendOnTerminal: false,
  isFailureSendOnTerminal: false,
  errorMessageSendOnTerminal: "",

  isPendingTerminalOff: false,
  isSuccessTerminalOff: false,
  isFailureTerminalOff: false,
  errorMessageTerminalOff: "",

  isPendingSendSms: false,
  isSuccessSendSms: false,
  isFailureSendSms: false,
  errorMessageSendSms: "",

  isPendingUpdatePerson: false,
  isSuccessUpdatePerson: false,
  isFailureUpdatePerson: false,
  errorMessageUpdatePerson: "",
};

export default {
  state,
  mutations,
  actions,
};
