<template>
  <div>
    <v-switch
      v-model="checkWeek"
      flat
      :label="$ml.get('schedule.check_week')"
    ></v-switch>
    <WeekDaysCheckbox v-if="checkWeek" v-model="weekdays" />
    <v-switch
      v-model="checkDate"
      flat
      :label="$ml.get('schedule.check_date')"
    ></v-switch>
    <v-row v-if="checkDate" style="margin-top: -30px">
      <v-col cols="12" lg="6">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFrom"
              :label="$ml.get('dtpicker.dateFrom')"
              :hint="$ml.get('dtpicker.dateFormat')"
              persistent-hint
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFrom"
            :min="dueFromMin"
            :max="dueFromMax"
            :first-day-of-week="firstDayOfWeek"
            no-title
            @input="menu1 = false"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu1 = false">
              {{ $ml.get("button.close") }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" lg="6">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateTo"
              :label="$ml.get('dtpicker.dateTo')"
              :hint="$ml.get('dtpicker.dateFormat')"
              persistent-hint
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateTo"
            :min="dueToMin"
            :first-day-of-week="firstDayOfWeek"
            no-title
            @input="menu2 = false"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu2 = false">
              {{ $ml.get("button.close") }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <div class="md-form md-outline">
          <input
            type="time"
            id="default-picker"
            class="form-control"
            :placeholder="$ml.get('dtpicker.startTime')"
            v-model="timeFrom"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="6">
        <div class="md-form md-outline">
          <input
            type="time"
            id="default-picker"
            class="form-control"
            :placeholder="$ml.get('dtpicker.toTime')"
            v-model="timeTo"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WeekDaysCheckbox from "./WeekDaysCheckbox";
import calendar from "@/mixins/calendar";

const dateFormat = "YYYY-MM-DD";

export default {
  mixins: [calendar],
  props: ["value"],
  data() {
    return {
      checkWeek: false,
      checkDate: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      timeFrom: "00:00",
      timeTo: "00:00",
      weekdays: "1111100",
      dateFrom: "",
      dateTo: "",
    };
  },
  components: { WeekDaysCheckbox },
  watch: {
    weekdays(val) {
      this.weekdays = val;
      this.store();
    },
    checkWeek(val) {
      if (val && !this.weekdays) {
        this.weekdays = "1111100";
      }
      this.checkWeek = val;
      this.store();
    },
    checkDate(val) {
      this.checkDate = val;
      this.store();
    },
    dateFrom(val) {
      this.dateFrom = this.correctTime(val);
      this.store();
    },
    dateTo(val) {
      this.dateTo = this.correctTime(val);
      this.store();
    },
    timeFrom(val) {
      this.timeFrom = this.correctTime(val);
      this.store();
    },
    timeTo(val) {
      this.timeTo = this.correctTime(val);
      this.store();
    },
  },
  computed: {
    computedDateFrom() {
      return this.formatLocateDate(this.dateFrom);
    },

    computedDateTo() {
      return this.formatLocateDate(this.dateTo);
    },

    dueFromMin() {
      const date = this.$moment().format(dateFormat);

      return date;
    },

    dueFromMax() {
      const date = this.dateTo;

      return date;
    },

    dueToMin() {
      const date = this.dateFrom || this.dueFromMin;

      return date;
    },
  },
  methods: {
    setValue() {
      if (this.value) {
        if (this.value.time_from) {
          this.timeFrom = this.value.time_from;
        }
        if (this.value.time_to) {
          this.timeTo = this.value.time_to;
        }
        if (!this.value.weekdays || this.value.weekdays == "0000000") {
          this.weekdays = "0000000";
          this.checkWeek = false;
        } else {
          this.weekdays = this.value.weekdays;
          this.checkWeek = true;
        }
        if (this.value.date_from || this.value.date_to) {
          this.checkDate = true;
          this.dateFrom = this.value.date_from;
          this.dateTo = this.value.date_to;
        } else {
          this.checkDate = false;
          this.dateFrom = "";
          this.dateTo = "";
        }
      }
    },
    store() {
      if (!this.checkWeek) {
        this.weekdays = "";
      }

      if (!this.checkDate) {
        this.dateFrom = "";
        this.dateTo = "";
      }

      if (this.timeFrom) {
        if (typeof this.timeFrom == "object") {
          this.timeFrom =
            (this.timeFrom["HH"] || "00") + ":" + (this.timeFrom["mm"] || "00");
        }
      }
      if (this.timeTo != this.timeFrom) {
        if (typeof this.timeTo == "object") {
          this.timeTo =
            (this.timeTo["HH"] || "23") + ":" + (this.timeTo["mm"] || "59");
        }
      } else {
        this.timeFrom = "";
        this.timeTo = "";
      }
      if (
        this.dateFrom != "" ||
        this.dateTo != "" ||
        this.timeFrom != "" ||
        this.timeTo != "" ||
        this.weekdays != ""
      ) {
        this.$emit("input", {
          full_access: false,
          date_from: this.dateFrom,
          date_to: this.dateTo,
          time_from: this.timeFrom,
          time_to: this.timeTo,
          weekdays: this.weekdays,
        });
      } else {
        this.$emit("input", { full_access: true });
      }
    },
    correctTime(val) {
      let re = /([\d]{2}):([\d]{2})/i;
      let f = val.match(re);
      let isW = false;
      if ((f && f[1] > "59") || (f && f[1] < "00")) {
        f[1] = "00";
        isW = true;
      }
      if ((f && f[2] > "59") || (f && f[2] < "00")) {
        f[2] = "00";
        isW = true;
      }
      if (isW) {
        val = f[1] + ":" + f[2];
      }
      return val;
    },
  },
  mounted() {
    this.setValue();
  },
};
</script>
