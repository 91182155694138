<template>
  <div
    :class="[
      'device-monitoring__item',
      { 'device-monitoring__item--success': isSuccess },
    ]"
    @click="goToDevice"
  >
    <strong>{{ data.device_type }}</strong
    >:
    {{ data.name }}
  </div>
</template>

<script>
const SYNC_DEVICE_EVENT = "syncdevice";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    syncData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSuccess: this.data.monitoring.status,
    };
  },

  methods: {
    goToDevice() {
      this.$router.push({
        name: "editDevice",
        params: {
          id: this.data.id,
        },
      });
    },

    onSyncDevice(event) {
      if (event && event.id === this.data.id) {
        this.isSuccess = event.status;
      }
    },
  },

  watch: {
    syncData() {
      this.onSyncDevice(this.syncData);
    },
  },
};
</script>
