import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  data: {
    lang: "en",
    show: false,
    temp_meas: "c",
    temperature_range: [],
    temperature_enabled: false,
    temperature_rise: false,
    version: "",
  },
};

export default {
  state,
  mutations,
  actions,
};
