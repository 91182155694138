<template>
  <v-app>
    <v-dialog
      v-model="dialog"
      persistent
      width="375"
      :hide-overlay="isPendingLink"
    >
      <v-card>
        <v-card-title class="pb-6"
          ><v-row
            ><v-col>{{
              $ml.get(
                `user.${isPendingLink ? "linkGeneration" : "linkDisplayMethod"}`
              )
            }}</v-col
            ><v-col cols="auto"
              ><v-btn
                @click="closeTakeBiometrics"
                :label="$ml.get('button.close')"
                icon
                ><v-icon>mdi-close</v-icon></v-btn
              ></v-col
            ></v-row
          ></v-card-title
        >
        <v-card-text>
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="isPendingLink"
          />
          <template v-else>
            <v-row>
              <v-col cols="12" v-if="!telInputShow"
                ><v-btn
                  :disabled="isReload(telReload)"
                  color="primary"
                  block
                  @click="onTel"
                  small
                >
                  {{ getTelTitle }}</v-btn
                ></v-col
              >
              <v-col cols="12" v-else>
                <v-text-field
                  class="mt-0 pt-0 v-text-field--none-appearance"
                  :disabled="isPendingAddPhone"
                  v-model="phone"
                  :label="$ml.get('user.inputPhone')"
                  hide-details="auto"
                  outlined
                />
                <v-btn
                  :disabled="isPendingAddPhone"
                  class="mt-2"
                  color="primary"
                  block
                  @click="acceptTel"
                  small
                >
                  {{ $ml.get("button.saveAndSend") }}</v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-row class="ma-0 flex-nowrap">
                  <v-col cols="auto" class="pa-0 flex-grow-1">
                    <v-btn
                      :disabled="QRDActive"
                      color="primary"
                      block
                      @click="onQRD"
                      small
                      >{{ getQRDTitle }}
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="QRDActive"
                    cols="auto"
                    class="pa-0 flex-grow-0 d-flex align-center"
                  >
                    <v-tooltip top max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-2"
                          v-bind="attrs"
                          v-on="on"
                          color="grey lighten-1"
                          >mdi-information</v-icon
                        >
                      </template>
                      <span>{{ $ml.get("user.messageLinkQRD") }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$ml.get('user.invitationLink')"
                  v-model="invitationLink"
                  dense
                  @click="selectAll"
                  ref="linkText"
                  readonly
                >
                  <template #append>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          class="mb-2"
                          v-clipboard:copy="invitationLink"
                          v-clipboard:success="onCopy"
                        >
                          <v-icon>mdi-clipboard-multiple-outline</v-icon>
                        </v-btn>
                      </template>
                      {{ $ml.get("requests.copyUrl") }}
                    </v-tooltip>
                  </template>
                  <template v-if="invitationLink" v-slot:append-outer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          v-print="{
                            id: '#printQrBlock',
                            previewTitle: user.fullName,
                            popTitle: user.fullName,
                          }"
                        >
                          <v-icon>mdi-printer</v-icon>
                        </v-btn>
                      </template>
                      {{ $ml.get("card.printQR") }}
                    </v-tooltip>
                  </template></v-text-field
                >
              </v-col>
              <v-col colas="12" class="pt-0">
                <div
                  v-if="invitationLink"
                  id="printQrBlock"
                  class="printQrPage"
                >
                  <VueQRCodeComponent :text="invitationLink" :size="327" />
                </div>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-skeleton-loader v-if="isPending" type="card-avatar" />

    <div v-if="isSuccess" class="d-flex add-new-visitor-form-container">
      <div class="user-card mb-3">
        <div class="column image-column col-sm-4">
          <img :src="user.avatar" width="100%" alt="photo" id="avatar" />
        </div>
        <v-tabs v-model="tabItem" show-arrows>
          <v-tab>
            <v-icon left> mdi-account-details-outline </v-icon>
            {{ $ml.get("user.general") }}
          </v-tab>
          <v-tab>
            <v-icon left> mdi-email-outline </v-icon>
            {{ $ml.get("user.inviteList") }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabItem">
          <v-tab-item>
            <div class="column form-column col-sm-8">
              <v-row v-if="!user.deleted" class="mb-2" justify="end">
                <v-spacer />
                <v-col cols="auto">
                  <v-btn
                    v-if="
                      user.request_id &&
                      ($auth.user().type_user == 'admin' ||
                        $auth.user().roles.includes('reqview'))
                    "
                    color="primary"
                    depressed
                    link
                    class="font-weight-regular"
                    :to="{
                      name: 'editRequest',
                      params: { id: user.request_id },
                    }"
                  >
                    {{ $ml.get("user.toRequestPage") }}
                  </v-btn>
                </v-col>
              </v-row>

              <table style="table-layout: fixed">
                <tbody>
                  <tr>
                    <td colspan="2">
                      <div v-if="user.error" class="alert alert-danger">
                        <div class="container p-0">
                          <div class="row">
                            <div class="col-9 d-flex align-items-center">
                              {{ $ml.get("message." + user.error) }}
                            </div>
                            <div class="col-3 d-flex justify-content-end">
                              <button
                                v-if="user.error == 'NoBiometricData'"
                                type="button"
                                class="btn btn-primary"
                                @click="refeature"
                              >
                                {{ $ml.get("button.refeature") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="user.deleted" class="alert alert-danger">
                        {{ $ml.get("user.deleted") }}
                      </div>
                      <template v-else>
                        <div
                          v-if="user.on_object"
                          class="alert alert-success"
                          role="alert"
                        >
                          <strong>{{ $ml.get("statistic.advanced") }}</strong>
                        </div>
                        <div v-else class="alert alert-warning" role="alert">
                          <strong>{{
                            $ml.get("statistic.notAdvanced")
                          }}</strong>
                        </div>
                      </template>

                      <div
                        v-if="user.is_twin"
                        class="alert alert-info"
                        role="alert"
                      >
                        <strong>{{ $ml.get("user.isTwin") }}</strong>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="grey--text" width="240">
                      {{ $ml.get("users.fullname") }}:
                    </td>
                    <td>{{ user.fullName }}</td>
                  </tr>
                  <tr v-if="user.type_user">
                    <td class="grey--text">{{ $ml.get("users.userType") }}:</td>
                    <td>{{ $ml.get("users." + user["type_user"]) }}</td>
                  </tr>
                  <tr v-if="user.department">
                    <td class="grey--text">
                      {{ $ml.get("users.department") }}:
                    </td>
                    <td>{{ user.department.name }}</td>
                  </tr>
                  <template v-if="user['type_user'] == 'guest'">
                    <tr>
                      <td class="grey--text">
                        {{ $ml.get("user.visit_purpose") }}:
                      </td>
                      <td>{{ visit_purpose }}</td>
                    </tr>
                    <tr v-if="onepass">
                      <td class="grey--text">
                        {{ $ml.get("user.type_of_pass") }}:
                      </td>
                      <td v-if="onepass">{{ $ml.get("user.pass_one") }}</td>
                      <td v-if="!onepass">{{ $ml.get("user.pass_all") }}</td>
                    </tr>
                  </template>
                  <template
                    v-if="
                      user['type_user'] == 'guest' ||
                      user['type_user'] == 'employee'
                    "
                  >
                    <tr v-if="startDate">
                      <td class="grey--text">
                        {{ $ml.get("user.startDate") }}:
                      </td>
                      <td>{{ startDate }}</td>
                    </tr>
                    <tr v-if="expireDate">
                      <td class="grey--text">
                        {{ $ml.get("user.expireDate") }}:
                      </td>
                      <td>{{ expireDate }}</td>
                    </tr>
                  </template>
                  <tr>
                    <td class="grey--text">
                      {{ $ml.get("users.subdivision") }}:
                    </td>
                    <td>
                      <div
                        v-for="sdv in user.access_groups"
                        :key="sdv.id"
                        class="companies-item"
                      >
                        {{ sdv.name }}
                      </div>
                    </td>
                  </tr>
                  <tr v-if="user.type_user == 'employee'">
                    <td class="grey--text">{{ $ml.get("user.post") }}:</td>
                    <td>{{ post }}</td>
                  </tr>
                  <tr v-if="user.accessLevel">
                    <td class="grey--text">
                      {{ $ml.get("user.permission") }}:
                    </td>
                    <td>{{ $ml.get("access." + user.accessLevel) }}</td>
                    v-if=""
                  </tr>
                  <tr v-if="user.phone">
                    <td class="grey--text">{{ $ml.get("user.phone") }}:</td>
                    <td>{{ user.phone }}</td>
                  </tr>
                  <tr
                    v-if="
                      tabNum &&
                      (user.type_user == 'employee' ||
                        user.type_user == 'manager')
                    "
                  >
                    <td class="grey--text">{{ $ml.get("user.tabNum") }}:</td>
                    <td style="overflow: hidden; word-break: break-word">
                      {{ tabNum }}
                    </td>
                  </tr>
                  <tr v-if="user.documentNumber">
                    <td class="grey--text">
                      {{ $ml.get("documents." + user.documentType) }}:
                    </td>
                    <td>{{ user.documentNumber }}</td>
                  </tr>
                  <tr>
                    <td class="grey--text">ID:</td>
                    <td>{{ user.id }}</td>
                  </tr>
                  <tr>
                    <td class="grey--text">UID:</td>
                    <td>{{ user.uid }}</td>
                  </tr>
                  <tr v-if="user.externalId">
                    <td class="grey--text">External ID:</td>
                    <td>{{ user.externalId }}</td>
                  </tr>
                  <tr v-if="user.created_at">
                    <td class="grey--text">{{ $ml.get("user.createdAt") }}:</td>
                    <td>{{ user.created_at | moment("DD.MM.YYYY HH:mm") }}</td>
                  </tr>
                  <tr v-if="user.updated_at">
                    <td class="grey--text">{{ $ml.get("user.editedAt") }}:</td>
                    <td>{{ user.updated_at | moment("DD.MM.YYYY HH:mm") }}</td>
                  </tr>
                  <tr v-if="user.deleted && user.deleted_at">
                    <td class="grey--text">{{ $ml.get("user.deletedAt") }}:</td>
                    <td>{{ user.deleted_at | moment("DD.MM.YYYY HH:mm") }}</td>
                  </tr>
                  <tr v-if="this.note">
                    <td class="grey--text">{{ $ml.get("user.note") }}:</td>
                    <td>
                      {{ getNote }}

                      <v-tooltip
                        right
                        v-if="!showNote && this.note.length > 15"
                      >
                        <template v-slot:activator="{ on }">
                          <strong
                            v-on="on"
                            style="
                              cursor: pointer;
                              color: #7165ff;
                              font-size: 20px;
                            "
                            @click="showNote = !showNote"
                          >
                            {{ "..." }}
                          </strong>
                        </template>
                        <span>{{ $ml.get("user.expand") }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
              <v-row
                v-if="
                  $auth.user().type_user == 'admin' ||
                  $auth.user().type_user == 'guard' ||
                  $auth.user().type_user == 'manager'
                "
                style="margin-top: 0"
              >
                <v-col v-if="!user.deleted" cols="auto">
                  <v-btn
                    v-if="
                      !user.request_id &&
                      ($auth.user().type_user == 'admin' ||
                        $auth.user().roles.includes('requests'))
                    "
                    color="primary"
                    depressed
                    link
                    :to="{ name: 'addRequest', params: { user: user } }"
                  >
                    {{ $ml.get("user.createRequest") }}
                  </v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                  <button
                    v-if="isEditUser() && $auth.user().id != id"
                    class="btn btn-success mr-1"
                    @click="goEdit"
                  >
                    {{ $ml.get("user.edit") }}
                  </button>
                </v-col>
                <template
                  v-if="
                    user.type_user != 'admin' &&
                    isEditUser() &&
                    !user.deleted &&
                    $auth.user().id != id
                  "
                >
                  <v-col v-if="!user.blocked" cols="auto">
                    <button
                      class="btn btn-light mr-1"
                      type="button"
                      @click="blocked(1)"
                      id="user-blocked"
                    >
                      {{ $ml.get("button.block") }}
                    </button>
                  </v-col>
                  <v-col v-else cols="auto">
                    <button
                      class="btn btn-danger mr-1"
                      type="button"
                      @click="blocked(0)"
                      id="user-unblocked"
                    >
                      {{ $ml.get("button.unblock") }}
                    </button>
                  </v-col>
                  <v-col v-if="!user.blacklisted" cols="auto">
                    <button
                      class="btn btn-light mr-1"
                      type="button"
                      @click="blacklisted(1)"
                      id="user-blocked"
                    >
                      {{ $ml.get("button.add_blacklist") }}
                    </button>
                  </v-col>
                  <v-col v-else cols="auto">
                    <button
                      class="btn btn-black mr-1"
                      type="button"
                      @click="blacklisted(0)"
                      id="user-unblocked"
                    >
                      {{ $ml.get("button.remove_blacklist") }}
                    </button>
                  </v-col>
                </template>
              </v-row>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-row class="ma-0">
              <v-spacer />
              <v-col
                cols="auto d-flex flex-wrap-0"
                v-if="user?.invites?.length || isCanInvited"
              >
                <v-tooltip bottom v-if="user?.invites?.length">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-4" v-bind="attrs" color="green" v-on="on">
                      mdi-email-fast-outline
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("user.inviteSent") }}</span>
                </v-tooltip>
                <v-btn
                  v-if="isCanInvited"
                  color="primary"
                  depressed
                  :disabled="isPendingLink"
                  link
                  class="font-weight-regular"
                  @click="takeBiometrics"
                >
                  {{ $ml.get("user.invitationToTakeBiometrics") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="invites"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template #[`item.type_invite`]="{ item }">
                    {{ $ml.get(`user.inviteType${item.type_invite}`) }}
                  </template>
                  <template #[`item.sended_at`]="{ item }">
                    {{
                      item.sended_at
                        ? $moment(item.sended_at).format(DATE_FORMAT)
                        : "-"
                    }}
                  </template>
                  <template #[`item.created_at`]="{ item }">
                    {{ $moment(item.created_at).format(DATE_FORMAT) }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row></v-tab-item
          >
        </v-tabs-items>
      </div>

      <div class="user-card user-card-statistic p-3">
        <h3>{{ $ml.get("user.visitStatistics") }}</h3>
        <div class="row">
          <div class="col-sm-2">
            <select
              class="custom-select"
              v-model="thismonth"
              @change="getVisits"
            >
              <option
                v-for="month in months"
                :key="month.name"
                :value="month.name"
                :selected="month.name == thismonth"
              >
                {{ month.title }}
              </option>
            </select>
          </div>
          <v-spacer />
          <v-col cols="auto"
            ><v-menu offset-y bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                  {{ $ml.get("advanced.allEvents") }}&nbsp;
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item link @click="downloadAttendance()">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("user.attendanceReport")
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-tooltip left max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="grey lighten-1"
                          >mdi-information</v-icon
                        >
                      </template>
                      <span>{{
                        $ml.get("advanced.onlyZoneWithWorktime")
                      }}</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item link @click="downloadWorkTime()">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("advanced.worktime")
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-tooltip left max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="grey lighten-1"
                          >mdi-information</v-icon
                        >
                      </template>
                      <span>{{
                        $ml.get("advanced.onlyZoneWithWorktime")
                      }}</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item link @click="downloadVisits()">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $ml.get("user.passReport")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <div
            v-if="visiteStatistic.userentrance"
            class="col-sm-10"
            style="font-weight: bold; color: green"
          >
            {{ visiteStatistic.userentrance }}
          </div>
        </div>
        <div style="display: flex; flex-direction: column; margin-top: 10px">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a class="nav-link active" href="#short" data-toggle="tab">{{
                $ml.get("user.abbreviated")
              }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#allvisits" data-toggle="tab">{{
                $ml.get("user.allPassages")
              }}</a>
            </li>
          </ul>
        </div>
        <div
          class="tab-content"
          style="padding: 5px; border-top: 1px solid #dee2e6; margin-top: -1px"
        >
          <div class="row tab-pane active" id="short">
            <div class="col-sm-12">
              <table class="last-visitors-statistic">
                <thead>
                  <tr>
                    <th>{{ $ml.get("user.statDate") }}</th>
                    <th width="20%">
                      {{ $ml.get("device.purpose_entrance") }}
                    </th>
                    <th width="20%">{{ $ml.get("device.purpose_exit") }}</th>
                    <th width="20%" style="text-align: left">
                      {{ $ml.get("statistic.onObject") }}
                    </th>
                    <!-- <th width="20%" style="text-align: left;">Вне</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!visiteStatistic.days">
                    <td class="no-results" colspan="6">
                      {{ $ml.get("statistic.noVisit") }}
                    </td>
                  </tr>
                  <template v-else>
                    <tr v-for="visit in visiteStatistic.days" :key="visit.day">
                      <td>{{ visit.day | moment("DD.MM.YYYY") }}</td>
                      <td>{{ visit.firstEntering }}</td>
                      <td>{{ visit.lastExit }}</td>
                      <td>{{ visit.workTime }}</td>
                      <!-- <td>{{ visit.outWorkTime }}</td> -->
                    </tr>
                  </template>
                  <tr>
                    <th colspan="3" style="text-align: right">
                      {{ $ml.get("statistic.totalMonth") }}:
                    </th>
                    <th style="padding: 10px">{{ visiteStatistic.total }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row tab-pane" id="allvisits">
            <div class="col-sm-12">
              <table class="last-visitors-statistic" width="100%">
                <thead>
                  <tr>
                    <th width="60px"></th>
                    <th>{{ $ml.get("user.statDate") }}</th>
                    <th>{{ $ml.get("statistic.time") }}</th>
                    <th>
                      {{ $ml.get("statistic.zone") }}
                    </th>
                    <template v-if="!l.temperature_rise">
                      <th style="text-align: right">
                        °{{ l.temp_meas.toUpperCase() }}
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!visiteStatistic.visits.length">
                    <td class="no-results" colspan="6">
                      {{ $ml.get("statistic.noVisit") }}
                    </td>
                  </tr>
                  <template v-if="visiteStatistic.visits.length">
                    <tr v-for="vis in visiteStatistic.visits" :key="vis.id">
                      <td>
                        <span class="mr-1">
                          <v-tooltip
                            v-if="
                              vis.purpose === 'entrance' ||
                              vis.purpose === 'duplex'
                            "
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="success"
                                >mdi-location-enter</v-icon
                              >
                            </template>
                            <span>{{
                              $ml.get("device.purpose_entrance")
                            }}</span>
                          </v-tooltip>
                          <v-tooltip v-if="vis.purpose === 'exit'" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="error"
                                class="rotate-180"
                                >mdi-location-exit</v-icon
                              >
                            </template>
                            <span>{{ $ml.get("device.purpose_exit") }}</span>
                          </v-tooltip>
                        </span>

                        <span v-for="type in vis.identity_types" :key="type">
                          <v-tooltip v-if="type === 'face'" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small>
                                mdi-emoticon-happy-outline
                              </v-icon>
                            </template>
                            <span>{{ $ml.get("users.face") }}</span>
                          </v-tooltip>

                          <v-tooltip v-if="type === 'qr'" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small>
                                mdi-qrcode-scan
                              </v-icon>
                            </template>
                            <span>{{ $ml.get("users.qr") }}</span>
                          </v-tooltip>

                          <v-tooltip v-if="type === 'card'" top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small>
                                mdi-card-bulleted-outline
                              </v-icon>
                            </template>
                            <span>{{ $ml.get("users.card") }}</span>
                          </v-tooltip>
                        </span>
                      </td>

                      <td>{{ vis.day | moment("DD.MM.YYYY") }}</td>
                      <td>{{ vis.time }}</td>
                      <td>{{ vis.zone }}</td>
                      <template v-if="!l.temperature_rise">
                        <td style="text-align: right">
                          <span v-if="parseFloat(vis.temperature)">{{
                            calculateCalcFeel(parseFloat(vis.temperature))
                          }}</span>
                          <span v-if="!parseFloat(vis.temperature)">-</span>
                        </td>
                      </template>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import router from "@/router";
import { mapState, mapActions } from "vuex";
import VueQRCodeComponent from "vue-qrcode-component";
import print from "vue-print-nb";

const DATE_FORMAT = "DD.MM.YYYY HH:mm";

export default {
  directives: {
    print,
  },
  components: { VueQRCodeComponent },
  data() {
    return {
      DATE_FORMAT,
      thismonth: "",
      isPending: false,
      isSuccess: false,
      isFailure: false,
      invitationLink: "",
      telReload: 0,
      tabItem: null,
      invites: [],
      headers: [
        {
          text: this.$ml.get("user.typeInvite"),
          align: "center",
          sortable: false,
          value: "type_invite",
        },
        {
          text: this.$ml.get("user.typeSend"),
          align: "center",
          sortable: false,
          value: "sender_type",
        },
        {
          text: this.$ml.get("user.inviteMessage"),
          align: "center",
          sortable: false,
          value: "message",
        },
        {
          text: this.$ml.get("user.inviteSendedAt"),
          align: "center",
          sortable: false,
          value: "sended_at",
        },
        {
          text: this.$ml.get("user.inviteCreatedAt"),
          align: "center",
          sortable: false,
          value: "created_at",
        },
      ],
      telReloadFirst: true,
      telReloadTimer: null,
      telInputShow: false,
      telValid: true,
      phone: "",
      QRDActive: false,
      dialog: false,
      user: {
        id: 0,
        avatar: "/avatar/profile/avatar.png",
        fullName: "",
      },
      months: [],
      visiteStatistic: {
        days: [],
        visits: [],
        total: "",
      },
      expireDate: "",
      startDate: null,
      onepass: false,
      id: this.$route.params["id"],
      visit_purpose: "",
      post: "",
      tabNum: "",
      note: "",
      showNote: false,
      phoneRules: [(v) => !!v || this.$ml.get("message.NameIsRequired")],
    };
  },

  async created() {
    await this.getUser();
    this.setMonths();
  },

  methods: {
    ...mapActions({
      getLink: "person/getLink",
      sendOnTerminal: "person/sendOnTerminal",
      terminalOff: "person/terminalOff",
      sendSms: "person/sendSms",
      updatePerson: "person/updatePerson",
    }),
    acceptTel() {
      if (!this.phone) return;
      const params = { phone: this.phone };
      this.updatePerson({ id: this.id, params: params })
        .then(() => {
          this.onTel();
          this.telInputShow = false;
        })
        .catch(() => {
          let mess = "";
          if (this.errorMessageUpdatePerson == "duplicate phone")
            mess = this.$ml.get("requests.phoneExist");

          this.$notify({
            group: "info",
            type: "error",
            text:
              mess ||
              this.errorMessageUpdatePerson ||
              this.get("message.saveError"),
          });
        });
    },
    selectAll(event) {
      event.target.select();
    },
    onCopy() {
      this.$notify({
        group: "info",
        type: "success",
        text: this.$ml.get("message.successCopy"),
      });
    },
    isReload(timer) {
      if (timer) return true;
      return false;
    },
    downloadAttendance() {
      const params = {
        search: "",
        userId: this.id,
        lang: this.l.lang,
      };

      const requestParams = {
        method: "get",
        url: "report/iotime",
        responseType: "blob",
        params,
      };

      if (this.dateRange[0]) {
        params.startDate = this.$moment(
          this.dateRange[0],
          DATE_FORMAT
        ).toISOString();
      }

      if (this.dateRange[1]) {
        params.endDate = this.$moment(
          this.dateRange[1],
          DATE_FORMAT
        ).toISOString();
      }

      this.axios
        .request(requestParams)
        .then((response) => {
          if (response.status === 204) {
            this.$notify({
              group: "info",
              type: "error",
              text: this.$ml.get("advanced.nopass"),
            });
          } else {
            const filename = `${requestParams.url}-${this.$moment().format(
              "YYYYMMDDHHmmss"
            )}.xlsx`;

            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/xlsx",
              })
            );

            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        })
        .catch(() => {
          this.$notify({
            group: "info",
            type: "error",
            text: this.$ml.get("advanced.nopass"),
          });
        });
    },
    downloadWorkTime() {
      const formData = {
        userId: this.id,
        lang: this.l.lang,
        search: "",
      };
      if (this.dateRange[0]) {
        formData.startDate = this.$moment(
          this.dateRange[0],
          DATE_FORMAT
        ).toISOString();
      }

      if (this.dateRange[1]) {
        formData.endDate = this.$moment(
          this.dateRange[1],
          DATE_FORMAT
        ).toISOString();
      }
      this.actDownloadWorkingTime({
        formData: formData,
        time: this.$moment().format(DATE_FORMAT),
      });
    },
    downloadVisits() {
      const formData = {
        userId: this.id,
        lang: this.l.lang,
        search: "",
      };
      if (this.dateRange[0]) {
        formData.startDate = this.$moment(
          this.dateRange[0],
          DATE_FORMAT
        ).toISOString();
      }

      if (this.dateRange[1]) {
        formData.endDate = this.$moment(
          this.dateRange[1],
          DATE_FORMAT
        ).toISOString();
      }
      this.actDownloadVisits({
        formData: formData,
        time: this.$moment().format(DATE_FORMAT),
      });
    },

    actDownloadWorkingTime({ formData, time }) {
      const requestParams = {
        method: "get",
        url: "report/worktime",
        responseType: "blob",
        params: formData,
      };

      return this.axios
        .request(requestParams)
        .then((response) => {
          if (response.status !== 204) {
            const filename = `${requestParams.url}-${this.$moment().format(
              "YYYYMMDDHHmmss"
            )}.xlsx`;
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/xlsx",
              })
            );

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
          } else {
            this.$notify({
              group: "info",
              type: "error",
              text: this.$ml.get("advanced.nopass"),
            });
          }
        })
        .catch((error) => {
          this.$notify({
            group: "info",
            type: "error",
            text: error?.response?.data?.status?.message || "",
          });
        });
    },

    actDownloadVisits({ formData, time }) {
      const requestParams = {
        method: "get",
        url: "report/passed",
        responseType: "blob",
        params: formData,
      };
      return this.axios
        .request(requestParams)
        .then((response) => {
          if (response.status === 204) {
            this.$notify({
              group: "info",
              type: "error",
              text: this.$ml.get("advanced.nopass"),
            });
          } else {
            const filename = `${requestParams.url}-${this.$moment().format(
              "YYYYMMDDHHmmss"
            )}.xlsx`;
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/xlsx",
              })
            );

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        })
        .catch((error) => {
          this.$notify({
            group: "info",
            type: "error",
            text: error?.response?.data?.status?.message || "",
          });
        });
    },

    isEditUser() {
      return (
        !this.l.cloud_connect &&
        (this.$auth.user().type_user == "admin" ||
          this.$auth.user().roles.includes("useredit") ||
          this.$auth.user().roles.includes("userdel"))
      );
    },
    goEdit() {
      router.push({ path: `/user/${this.id}/edit` });
    },
    takeBiometrics() {
      this.dialog = true;
      this.getLink(this.id)
        .then(() => {
          this.invitationLink = this.invitationLinkState.data || "";
        })
        .catch(() => {
          this.dialog = false;
        });
    },
    closeTakeBiometrics() {
      if (this.QRDActive) this.terminalOff();
      this.QRDActive = false;
      this.dialog = false;
      this.telInputShow = false;
      this.phone = "";
    },
    setTelReload() {
      this.telReload = 40;
      this.telReloadFirst = false;
      if (!this.telReloadTimer) {
        this.telReloadTimer = setInterval(() => {
          if (this.telReload > 0) {
            --this.telReload;
          } else {
            this.telReloadTimer = clearInterval(this.telReloadTimer);
          }
        }, 1000);
      }
    },
    onTel() {
      const params = {
        sendtype: "sms",
        template: "bio_invite",
        url: this.invitationLink,
      };
      this.sendSms({ id: this.id, params: params })
        .then(() => {
          this.setTelReload();
        })
        .catch(() => {
          if (this.isSendSmsErrorMessage.code == 400) {
            this.telInputShow = true;
          }
        });
    },
    onQRD() {
      this.sendOnTerminal(this.invitationLink);
      this.QRDActive = true;
    },
    formatdate(date) {
      let d = date.split("/");
      d[2] = d[2] - 2000;
      return d.join(".");
    },
    blocked(stat) {
      let com = stat == 1 ? "blocked" : "unblocked";
      this.axios
        .put("/user/" + com, { ids: [parseInt(this.id)] })
        .then((response) => {
          if (response.status == 200 && response.data.status.code == 0) {
            this.user.blocked = stat;

            if (com === "blocked") {
              this.$notify({
                group: "info",
                type: "success",
                text: this.$ml.get("message.userBlockSuccess"),
              });
            } else {
              this.$notify({
                group: "info",
                type: "success",
                text: this.$ml.get("message.userUnblockSuccess"),
              });
            }
          }
        });
    },
    blacklisted(stat) {
      let com = stat == 1 ? "blacklisted" : "unblacklisted";
      this.axios
        .put("/user/" + com, { ids: [parseInt(this.id)] })
        .then((response) => {
          if (response.status == 200 && response.data.status.code == 0) {
            this.user.blacklisted = stat;

            if (com === "blacklisted") {
              this.$notify({
                group: "info",
                type: "success",
                text: this.$ml.get("message.userBlacklistSuccess"),
              });
            } else {
              this.$notify({
                group: "info",
                type: "success",
                text: this.$ml.get("message.userUnblacklistSuccess"),
              });
            }
          }
        });
    },
    getUser() {
      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;

      return this.axios
        .get("/user/" + this.id)
        .then((response) => {
          this.isPending = false;
          this.isSuccess = true;
          this.isFailure = false;

          this.getVisits();
          this.user = response.data.data ? response.data.data : {};
          this.user.fullName = (
            this.user.last_name +
            " " +
            this.user.first_name +
            " " +
            this.user.middle_name
          ).trim();
          this.user.avatar = this.user.photo
            ? "/avatar/profile/" + this.user.photo
            : "/avatar/profile/" + "avatar.png";

          this.invites = this.user.invites ?? [];
          if (this.user.data) {
            let data = JSON.parse(this.user.data);
            this.visit_purpose = data.visit_purpose || "";
            this.post = data.post || "";
            this.onepass = data.onepass || false;
            this.note = data.note;
            this.startDate = data.start_date
              ? this.$moment(data.start_date).format("DD.MM.YYYY HH:mm")
              : null;
            this.expireDate = data.expire_date
              ? this.$moment(data.expire_date).format("DD.MM.YYYY HH:mm")
              : "";
            this.tabNum = data.tab_num;
          }
        })
        .catch((error) => {
          const message = error.response.data.status.message;

          switch (message) {
            case "record not found":
              this.$notify({
                group: "info",
                type: "error",
                text: this.$ml.get("message.noUser"),
              });
              break;
            default:
              this.$notify({
                group: "info",
                type: "error",
                text: message,
              });
              break;
          }

          this.$router.go(-1);

          this.isPending = false;
          this.isSuccess = false;
          this.isFailure = true;
        });
    },
    calculateCalcFeel(t) {
      if (this.l.temp_meas == "f") {
        return Math.round(((t * 9) / 5 + 32) * 10) / 10;
      } else {
        return t;
      }
    },

    setMonths() {
      let m = {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        10: "October",
        11: "November",
        12: "December",
      };

      let tm = new this.$moment();
      //.format("YYYY-MM")
      for (let i = 0; i < 12; i++) {
        this.months.push({
          name: tm.format("YYYY-MM"),
          title:
            tm.format("YYYY") +
            " - " +
            this.$ml.get("dtpicker." + m[tm.format("MM")]),
        });
        tm.add(-1, "M");
      }
      if (this.months.length > 0) {
        this.thismonth = this.months[0].name;
      }
    },

    getVisits() {
      this.visiteStatistic.days = [];
      this.visiteStatistic.visits = [];
      this.axios("/user/" + this.id + "/visits?month=" + this.thismonth).then(
        (response) => {
          if (response.status == 200) {
            let data = response.data.data ? response.data.data : {};
            if (data) {
              if (data.days) {
                data.days.forEach((value) => {
                  this.visiteStatistic.days.push({
                    day: value.day,
                    firstEntering: value.first_entering,
                    lastExit: value.last_exit,
                    workTime: value.work_time,
                    outWorkTime: value.out_work_time,
                  });
                });
              }
              this.visiteStatistic.total = data.total;
              if (data.visits) {
                data.visits.forEach((value) => {
                  const data = JSON.parse(value.data);

                  this.visiteStatistic.visits.push({
                    day: value.day,
                    purpose: value.purpose,
                    time: value.time_purpose,
                    zone: data?.zone,
                    type: data?.type,
                    temperature: data?.temperature,
                    identity_types: this.getIdentityTypesArr(
                      data.identity_types
                    ),
                  });
                });
              }
            }
          }
        }
      );
    },

    getIdentityTypesArr(types) {
      let result = ["face"];

      if (types) {
        result = types.split(",");
      }

      return result;
    },

    refeature() {
      this.axios
        .put(`/user/${this.user.id}/refeature`)
        .then((response) => {
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("message.refeature_success"),
          });
          this.user.error = "";
        })
        .catch((err) => {
          this.$notify({
            group: "info",
            type: "error",
            text: err.response.data.status.message,
          });
        });
    },
  },

  computed: {
    ...mapState({
      l: (state) => state.settings.data,
      isPendingLink: (state) => state.person.isPending,
      invitationLinkState: (state) => state.person.data,
      isSendSmsErrorMessage: (state) => state.person.errorMessageSendSms,
      isPendingAddPhone: (state) => state.person.isPendingUpdatePerson,
      errorMessageUpdatePerson: (state) =>
        state.person.errorMessageUpdatePerson,
    }),
    isCanInvited() {
      return (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().roles.includes("usersendbio")
      );
    },
    getTelTitle() {
      return `${
        !this.telReloadFirst && !this.telReload
          ? this.$ml.get("user.again")
          : ""
      } ${this.$ml.get("user.sendLinkSms")} ${
        this.telReload ? "(" + this.telReload + ")" : ""
      }`;
    },
    getQRDTitle() {
      return this.$ml.get(
        `user.${this.QRDActive ? "offLinkQRD" : "sendLinkQRD"}`
      );
    },
    getNote() {
      if (this.showNote) {
        return this.note;
      } else {
        return this.note.slice(0, 15);
      }
    },
    dateRange() {
      return [
        this.$moment(this.thismonth).startOf("month").format(DATE_FORMAT),
        this.$moment(this.thismonth).endOf("month").format(DATE_FORMAT),
      ];
    },
  },
};
</script>
<style>
.v-text-field--none-appearance input[type="number"]::-webkit-outer-spin-button,
.v-text-field--none-appearance input[type="number"]::-webkit-inner-spin-button {
  appearance: none !important;
}
.user-card .v-data-table__wrapper tr {
  height: auto !important;
}
</style>

<style scoped>
.nav-pills .nav-link {
  border-radius: 0.25rem 0.25rem 0 0;
}
.btn.btn-black {
  background: #000;
  color: #fff;
}
.last-visitors-statistic td,
.last-visitors-statistic th {
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 575px) {
  .user-card .column {
    float: none !important;
  }
}
.form-column {
  width: 100%;
  max-width: 100%;
}
.form-column > table {
  width: 100%;
}
</style>
<style scoped>
.printQrPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
