<template>
  <v-app>
    <v-card elevation="2" class="settings-page">
      <v-tabs v-model="tab" show-arrows @change="onChangeTab">
        <v-tab>{{ $ml.get("advanced.passedTab") }}</v-tab>
        <v-tab v-if="isShowEvents">{{ $ml.get("advanced.eventTab") }}</v-tab>
      </v-tabs>

      <v-card-text>
        <v-tabs-items
          touchless
          v-model="tab"
          style="overflow: initial"
          class="mt-5"
        >
          <v-tab-item :transition="false" :reverse-transition="false">
            <Passed />
          </v-tab-item>
          <v-tab-item
            v-if="isShowEvents"
            :transition="false"
            :reverse-transition="false"
          >
            <Events />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import Passed from "./Passed";
import Events from "./Events";

export default {
  components: {
    Passed,
    Events,
  },

  methods: {
    onChangeTab(tab) {
      this.$router.replace({
        query: {
          page: 1,
          tab,
        },
      });
    },
  },

  data() {
    const { tab } = this.$route.query;

    return {
      tab: parseInt(tab) || 0,
    };
  },

  computed: {
    isShowEvents() {
      return (
        this.$auth.user().type_user == "admin" ||
        this.$auth.user().type_user == "guard"
      );
    },
  },
};
</script>
