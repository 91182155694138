<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span v-if="!multi" class="headline">{{
            $ml.get("subdiv.title")
          }}</span>
          <span v-else class="headline">{{ $ml.get("subdiv.titles") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col :cols="setCols">
                <v-text-field
                  v-if="!multi"
                  :label="$ml.get('subdiv.name') + '*'"
                  v-model="name"
                  :rules="nameRules"
                  required
                />
                <v-select
                  v-if="!l.cloud_connect"
                  return-object
                  item-text="name"
                  item-value="id"
                  :items="subdivisions"
                  dblclick
                  v-model="thisparent"
                  :label="$ml.get('subdiv.parent')"
                />
                <v-select
                  return-object
                  item-text="name"
                  item-value="id"
                  :items="zones"
                  dblclick
                  multiple
                  v-model="zoneparents"
                  :label="$ml.get('subdiv.zone')"
                />
                <v-switch v-model="isGuest" :label="$ml.get('subdiv.guest')" />
                <v-switch
                  v-model="isSchedule"
                  :label="$ml.get('subdiv.schedule')"
                ></v-switch>
              </v-col>
              <v-col v-if="isSchedule" :cols="setCols">
                <div
                  v-for="(schedule, it) in schedules"
                  :key="it"
                  class="shedule-item"
                >
                  <v-btn
                    v-if="schedules.length > 1"
                    icon
                    color="red"
                    class="shedule-item__close-btn"
                    @click="sheduleRemove(it)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <TimeAccess v-model="schedules[it]" />
                </div>
                <v-btn @click="addNewShedule" class="mt-5">{{
                  $ml.get("schedule.add")
                }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">{{ $ml.get("button.close") }}</v-btn>
          <v-btn
            v-if="
              (multi && (zoneparents.length || isSchedule || thisparent.id)) ||
              (name && thisparent)
            "
            color="primary"
            text
            @click="store"
            >{{ $ml.get("button.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import TimeAccess from "@/components/TimeAccess";
import { mapState } from "vuex";

export default {
  props: ["companyId", "subdivisions", "multi", "ids"],

  components: { TimeAccess },

  data() {
    return {
      isPending: false,
      isSuccess: false,
      isFailure: false,
      errorMessage: "",

      dialog: true,
      thisparent: {
        id: null,
      },
      zoneparents: [],
      schedules: [],
      //schedule: {},
      zones: [],
      name: "",
      id: 0,
      nameRules: [
        (v) => !!v || this.$ml.get("message.NameIsRequired"),
        (v) => v.length <= 70 || this.$ml.get("user.70maxSizeField"),
      ],
      isSchedule: false,
      isGuest: false,
    };
  },
  methods: {
    close() {
      this.$emit("toggle", false);
    },
    store() {
      if (this.multi) {
        this.multistore();
        return;
      }

      if (!this.name) return;
      let url = "/subdivision/add";
      let zones = [];
      this.zoneparents.forEach((z) => {
        if (z.id !== null) {
          zones.push(z.id);
        }
      });
      if (!this.isSchedule) {
        this.schedules = [];
      }

      let data = {
        name: this.name,
        parent_id: this.thisparent.id,
        zones_id: zones,
        access: this.schedules,
        is_guest: this.isGuest,
      };

      if (this.id) {
        data.id = this.id;
        url = "/subdivision/" + this.id;
        this.axios
          .put(url, data, {
            header: {
              "Content-Type": "application/json",
            },
            timeout: 20000,
          })
          .then((response) => {
            if (response.status == 200) {
              this.close();
            }
          })
          .catch((error) => {
            this.$notify({
              group: "info",
              type: "error",
              text: error?.response?.data?.status?.message || "",
            });
          });
      } else {
        this.axios
          .post(url, data, {
            header: {
              "Content-Type": "application/json",
            },
            timeout: 20000,
          })
          .then((response) => {
            if (response.status == 200) {
              this.close();
            }
          })
          .catch((error) => {
            this.$notify({
              group: "info",
              type: "error",
              text: error?.response?.data?.status?.message || "",
            });
          });
      }
    },
    multistore() {
      if (!this.ids) return;
      let zones = [];
      this.zoneparents.forEach((z) => {
        if (z.id !== null) {
          zones.push(z.id);
        }
      });
      if (!this.isSchedule) {
        this.schedules = [];
      }
      let data = {};
      if (this.thisparent.id) {
        data.parent_id = this.thisparent.id;
      }
      if (zones.length) {
        data.zones_id = zones;
      }
      data.access = this.schedules;
      data.is_guest = this.isGuest;
      let len = this.ids.length;
      this.ids.forEach((id) => {
        data.id = id;
        let url = "/subdivision/" + id;
        this.axios
          .put(url, data, {
            header: {
              "Content-Type": "application/json",
            },
            timeout: 20000,
          })
          .then((response) => {
            if (response.status == 200) {
              if (this.ids[len - 1] == id) {
                this.close();
              }
            }
          })
          .catch((error) => {
            this.$notify({
              group: "info",
              type: "error",
              text: error?.response?.data?.status?.message || "",
            });
          });
      });
    },
    getZones() {
      this.axios.get("/zones").then((response) => {
        if (response.status == 200) {
          this.zones = response.data.data;
        }
      });
    },
    addNewShedule() {
      this.schedules.push({
        id: +new Date(),
        checkWeek: false,
        checkDate: false,
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        timeFrom: "00:00",
        timeTo: "00:00",
        weekdays: "1111100",
        dateFrom: "",
        dateTo: "",
      });
    },
    sheduleRemove(id) {
      this.schedules = this.schedules.filter((_, it) => it !== id);
    },

    getCompany(id) {
      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;
      this.errorMessage = "";

      this.axios(`subdivision/${id}`).then((response) => {
        this.name = response.data.data.name;

        if (response.data.data.zones_id) {
          this.zoneparents = response.data.data.zones_id.map((zone) => ({
            id: zone,
          }));
        }

        this.thisparent = {
          id: response.data.data.parent_id,
          parent_id: null,
          name: "",
        };

        if (
          response.data.data.access != null &&
          response.data.data.access.length
        ) {
          this.schedules = response.data.data.access;
          this.schedules.forEach((item) => {
            if (!item.full_access) {
              this.isSchedule = true;
            }
          });
        }

        this.subdivisions.splice(0, 0, {
          id: null,
          parent_id: null,
          name: this.$ml.get("subdiv.notParent"),
        });
        this.subdivisions.forEach((item) => {
          if (item.parent_id != null) {
            let find = this.subdivisions.find((el) => el.id == item.parent_id);
            item.name = find.name + "/" + item.name;
          }
        });

        this.id = response.data.data.id;
        this.isGuest = response.data.data.is_guest;
      });
    },
  },

  computed: {
    ...mapState({
      l: (state) => state.settings.data,
    }),

    setCols() {
      return this.isSchedule ? 6 : 12;
    },
  },

  created() {
    this.getZones();
    this.getCompany(this.companyId);
  },
};
</script>

<style lang="scss">
.shedule-item {
  position: relative;
  &__close-btn {
    position: absolute !important;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
</style>
