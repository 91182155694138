<template>
  <v-card :color="alarmColor">
    <v-card-title class="text-h5">
      {{ data.full_name }}
    </v-card-title>

    <v-card-text>
      {{ data.purpose }}
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <small>{{ $ml.with("time", timer).get("users.autoCloseTimes") }}</small>
      <v-btn text @click="$emit('close')">{{ $ml.get("button.close") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      require: true,
    },
  },

  data() {
    return {
      alarmColor: "#ffffff",
      timer: 15,
    };
  },

  methods: {
    alarmColorInterval() {
      setInterval(() => {
        if (this.alarmColor === "#ffffff") {
          this.alarmColor = "#e3342f";
        } else {
          this.alarmColor = "#ffffff";
        }
      }, 1000);
    },

    autoClose() {
      setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          this.$emit("close");
        }
      }, 1000);
    },
  },

  mounted() {
    this.alarmColorInterval();
    this.autoClose();
  },
};
</script>
