<template>
  <div>
    <UserEdit />
  </div>
</template>

<script>
import UserEdit from "./users/UserEdit";
export default {
  name: "AddNewUser",
  components: { UserEdit },
};
</script>

<style scoped></style>
