<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="callbackMode"
          :items="callbackItems"
          hide-details="auto"
          :label="$ml.get('devices.callbackMode')"
          outlined
        />
      </v-col>
      <v-col v-if="callbackMode === 'custom'" cols="12" md="6">
        <v-text-field
          v-model="ipAddressCallback"
          :rules="callbackIpRules"
          hide-details="auto"
          :label="`${$ml.get('devices.ipCallback')} *`"
          outlined
          required
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="recMode"
          :items="recognitionItems"
          item-text="name"
          item-value="value"
          hide-details="auto"
          :label="$ml.get('devices.recognitionMode')"
          @change="recModeChange"
          outlined
        >
          <!-- <template v-slot:label>
            <v-tooltip right max-width="250">
              <template v-slot:activator="{ on, attrs }">
                {{ $ml.get("devices.silenMode") }}
                <v-icon
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  color="grey lighten-1"
                  >mdi-information</v-icon
                >
              </template>
              <span>{{ $ml.get("advanced.silentMode") }}</span>
            </v-tooltip>
          </template> -->
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-if="recognitionMode == 'by_frame_in_cloud'"
          :rules="recognitionServerRules"
          v-model="recognitionServer"
          hide-details="auto"
          :label="$ml.get('devices.recognitionServer')"
          @change="store()"
          outlined
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(channel, index) of channels"
        :key="channel.channel"
        cols="12"
      >
        <Channel :channel="channel" @change="updateChannels($event, index)" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="systemmode"
          :items="systemmodes"
          item-text="name"
          item-value="val"
          :label="$ml.get('devices.system_mode')"
          outlined
        ></v-select>
        <v-select
          v-model="displayMode"
          :items="displaymodes"
          item-text="name"
          item-value="val"
          :label="$ml.get('devices.DisplayMode')"
          outlined
        ></v-select>
        <v-select
          v-model="aspoofing"
          :items="antispoofing"
          item-text="name"
          item-value="val"
          :label="$ml.get('devices.AntispoofingMode')"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-row style="margin-top: -12px">
          <v-col :cols="!controlModeSelect(controlMode) ? 12 : 6">
            <v-select
              v-model="controlMode"
              :items="controls"
              item-text="name"
              item-value="val"
              :label="$ml.get('devices.controlMode')"
              outlined
            >
              <template v-slot:item="{ on, attrs, item }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-tooltip top min-width="400" max-width="400">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span>{{ item.description }}</span>
                    </v-tooltip>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="controlModeSelect(controlMode)" cols="6">
            <v-select
              v-model="cardtype"
              :items="cardtypes"
              item-text="name"
              item-value="val"
              :label="$ml.get('card.type')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-select
          v-if="!checkAuthenticationCard(authenticationType)"
          v-model="authenticationType"
          :items="authentications"
          item-text="name"
          item-value="val"
          :label="$ml.get('devices.authenticationType')"
          outlined
        ></v-select>
        <v-row v-else style="margin-top: -12px">
          <v-col cols="6">
            <v-select
              v-model="authenticationType"
              :items="authentications"
              item-text="name"
              item-value="val"
              :label="$ml.get('devices.authenticationType')"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="effectiveWiegandLen"
              :items="effectiveWiegandLens"
              item-text="name"
              item-value="val"
              :label="$ml.get('devices.effectiveWiegandLen')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-select
          cols="12"
          v-model="showName"
          :items="shownames"
          item-text="name"
          item-value="val"
          :label="$ml.get('devices.ShowFullname')"
          outlined
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-switch
          v-model="isEnableScreensaver"
          :label="$ml.get('devices.isEnableScreensaver')"
        />
      </v-col>

      <v-col v-if="isEnableScreensaver" cols="12" md="6">
        <v-subheader>{{ $ml.get("devices.screensaverDelay") }}</v-subheader>
        <v-slider
          v-model="screensaverDelay"
          class="align-center"
          max="120"
          min="1"
          step="1"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="screensaverDelay"
              class="mt-0 pt-0"
              hide-details
              max="120"
              min="1"
              step="1"
              type="number"
              style="width: 70px"
              outlined
            />
          </template>
        </v-slider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-subheader
          >{{ $ml.get("devices.recognition_range") }}
          <template v-if="l.temp_meas == 'c'">
            {{ $ml.get("devices.recognition_metr") }}
          </template>
          <template v-else>
            {{ $ml.get("devices.recognition_imper") }}
          </template>
        </v-subheader>
        <v-slider
          v-model="recognitionRange"
          class="align-center"
          :max="getFft(recognitionRangeMax)"
          :min="getFft(0.3)"
          step="0.1"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="recognitionRange"
              class="mt-0 pt-0"
              hide-details
              :max="getFft(recognitionRangeMax)"
              :min="getFft(0.3)"
              step="0.1"
              type="number"
              style="width: 60px"
              outlined
            ></v-text-field>
          </template>
        </v-slider>
        <v-subheader>{{ $ml.get("devices.recognition_face") }}</v-subheader>
        <v-slider
          v-model="recognitionFace"
          class="align-center"
          max="1"
          min="0"
          step="0.01"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="recognitionFace"
              class="mt-0 pt-0"
              hide-details
              max="1"
              min="0"
              step="0.01"
              type="number"
              style="width: 70px"
              outlined
            ></v-text-field>
          </template>
        </v-slider>
        <v-subheader v-if="regimMask">{{
          $ml.get("devices.recognition_mask")
        }}</v-subheader>
        <v-slider
          v-if="regimMask"
          v-model="recognitionMask"
          class="align-center"
          max="1"
          min="0"
          step="0.01"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="recognitionMask"
              class="mt-0 pt-0"
              hide-details
              max="1"
              min="0"
              step="0.01"
              type="number"
              style="width: 70px"
              outlined
            ></v-text-field>
          </template>
        </v-slider>
        <v-switch
          v-if="authModeByFace(authenticationType)"
          v-model="silentMode"
          @change="store()"
          :label="$ml.get('devices.silenMode')"
        >
          <template v-slot:label>
            <v-tooltip right max-width="250">
              <template v-slot:activator="{ on, attrs }">
                {{ $ml.get("devices.silenMode") }}
                <v-icon
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  color="grey lighten-1"
                  >mdi-information</v-icon
                >
              </template>
              <span>{{ $ml.get("advanced.silentMode") }}</span>
            </v-tooltip>
          </template>
        </v-switch>
        <v-switch
          v-model="extraFactor"
          :label="$ml.get('devices.extraFactor')"
        />
        <!-- ExtraFactor -->
        <div v-if="extraFactor">
          <v-subheader>{{
            $ml.get("devices.extraFactorTimeoutSec")
          }}</v-subheader>
          <v-slider
            v-model="extraFactorTimeoutSec"
            class="align-center"
            max="60"
            min="1"
            step="1"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="extraFactorTimeoutSec"
                class="mt-0 pt-0"
                hide-details
                max="60"
                min="1"
                step="1"
                type="number"
                style="width: 70px"
                outlined
              ></v-text-field>
            </template>
          </v-slider>
          <v-row style="margin-top: 10px">
            <v-col cols="12" md="8">
              <v-text-field
                v-model="extraFactorUrl"
                :label="`${$ml.get('devices.extraFactorUrl')} *`"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="extraFactorMethod"
                :label="`${$ml.get('devices.extraFactorMethod')} *`"
                outlined
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="8">
              <v-select
                cols="12"
                v-model="extraFactorMode"
                :items="[
                  { name: 'hard', val: 'hard' },
                  { name: 'soft', val: 'soft' },
                ]"
                item-text="name"
                item-value="val"
                :label="$ml.get('devices.extraFactorMode')"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                type="number"
                v-model="extraFactorAttempsLimit"
                :label="`${$ml.get('devices.extraFactorAttempsLimit')} *`"
                min="1"
                max="10"
                outlined
                required
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <v-subheader>{{ $ml.get("devices.pass_timeout") }}</v-subheader>
        <v-slider
          v-model="mltimeout"
          class="align-center"
          max="60"
          :min="mltimeoutMin"
          step="1"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="mltimeout"
              class="mt-0 pt-0"
              hide-details
              max="60"
              :min="mltimeoutMin"
              step="1"
              type="number"
              style="width: 60px"
              outlined
            ></v-text-field>
          </template>
        </v-slider>

        <v-subheader>{{
          $ml.get("devices.controller_polling_delay")
        }}</v-subheader>
        <v-slider
          v-model="controller_polling_delay"
          class="align-center"
          max="90"
          min="0"
          step="1"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="controller_polling_delay"
              class="mt-0 pt-0"
              hide-details
              max="90"
              min="0"
              step="1"
              type="number"
              style="width: 60px"
              outlined
            ></v-text-field>
          </template>
        </v-slider>

        <v-switch v-model="asReader" :label="$ml.get('devices.readerMode')" />

        <div
          v-if="
            (systemmode === 'default' || systemmode === 'bypass') &&
            authenticationType === 'face'
          "
        >
          <v-switch
            v-model="cardRepeatMode"
            :label="$ml.get('devices.cardRepeatMode')"
          />
        </div>
        <!-- TODO: восстановить когда появится функционал авторегистрации -->
        <!-- <div>
          <v-switch
            v-model="autoregistrationMode"
            :label="$ml.get('devices.autoregistrationMode')"
          ></v-switch>
        </div> -->
        <div
          v-if="
            authenticationType !== 'card' && authenticationType !== 'qr_code'
          "
        >
          <v-switch
            :disabled="!l.temperature_enabled"
            v-model="temperatureEnable"
            :label="$ml.get('devices.temperatureEnable')"
          ></v-switch>
        </div>
        <div v-if="temperatureEnable">
          <v-switch
            v-model="showThempInfo"
            :label="$ml.get('devices.ShowThempInfo')"
          ></v-switch>
        </div>
        <div v-if="temperatureEnable && systemmode != 'temperature_pass'">
          <v-switch
            v-model="showAccess"
            :label="$ml.get('devices.ShowAccess')"
          ></v-switch>
        </div>
        <div v-if="purpose !== 'off' && apiVersion">
          <v-switch
            v-model="showDirection"
            :label="$ml.get('devices.showEntrance')"
          ></v-switch>
        </div>
        <v-switch
          v-if="regimMask"
          v-model="maskEnabled"
          :label="$ml.get('setting.maskEnabled')"
        ></v-switch>
        <v-switch
          v-model="escort_mode"
          :label="$ml.get('devices.escortMode')"
        />
      </v-col>
    </v-row>
    <password
      v-if="pdlg"
      v-model="pconfirm"
      @toggle="confirm($event)"
    ></password>
    <password
      v-if="pdlg2"
      v-model="pconfirm2"
      @toggle="confirm2($event)"
    ></password>

    <hr />
    <v-expansion-panels focusable class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header>{{
          $ml.get("devices.wiegandErrorsCode")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content class="pt-5">
          <v-row v-for="note of notificationReferenceBooks" :key="note.code">
            <v-col :cols="12" :md="6">
              <v-subheader>{{ getWiegandErrorTitle(note.code) }}</v-subheader>
            </v-col>
            <v-col :cols="12" :md="5">
              <v-row>
                <v-col>
                  <v-text-field
                    :value="note.value.toUpperCase()"
                    @input="note.value = $event.toUpperCase()"
                    outlined
                    required
                    :rules="hexRules"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-btn icon color="red" @click="removeWError(note.code)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="newCode"
                :items="notificationCodes"
                item-text="name"
                item-value="val"
                :label="$ml.get('devices.addWiegandError')"
                outlined
              ></v-select>
            </v-col>
            <v-col>
              <v-btn v-if="newCode" color="primary" dark @click="addWError">
                <v-icon left> mdi-plus </v-icon>
                {{ $ml.get("devices.addWiegandError") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- <v-row>
      <v-col :cols="12">
        <h4>{{ $ml.get("devices.wiegandErrorsCode") }}</h4>
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12" :md="6">
        <v-subheader>{{ $ml.get("devices.wiegandError7") }}</v-subheader>
      </v-col>
      <v-col :cols="12" :md="6">
        <v-text-field
          :value="notificationReferenceBook7.value.toUpperCase()"
          @input="notificationReferenceBook7.value = $event.toUpperCase()"
          outlined
          required
          :rules="hexRules"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12" :md="6">
        <v-subheader>{{ $ml.get("devices.wiegandError12") }}</v-subheader>
      </v-col>
      <v-col :cols="12" :md="6">
        <v-text-field
          :value="notificationReferenceBook12.value.toUpperCase()"
          @input="notificationReferenceBook12.value = $event.toUpperCase()"
          outlined
          required
          :rules="hexRules"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12" :md="6">
        <v-subheader>{{ $ml.get("devices.wiegandError23") }}</v-subheader>
      </v-col>
      <v-col :cols="12" :md="6">
        <v-text-field
          :value="notificationReferenceBook23.value.toUpperCase()"
          @input="notificationReferenceBook23.value = $event.toUpperCase()"
          outlined
          required
          :rules="hexRules"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12" :md="6">
        <v-subheader>{{ $ml.get("devices.wiegandError3") }}</v-subheader>
      </v-col>
      <v-col :cols="12" :md="6">
        <v-text-field
          :value="notificationReferenceBook3.value.toUpperCase()"
          @input="notificationReferenceBook3.value = $event.toUpperCase()"
          maxlength="6"
          outlined
          required
          :rules="hexRules"
        />
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import password from "@/components/PasswordDlg.vue";
import Channel from "../Channel";
import { mapState } from "vuex";
import { isHexadecimal, isURL } from "validator";

export default {
  props: ["value", "purpose", "channels"],
  components: {
    password,
    Channel,
  },
  watch: {
    callbackMode() {
      if (this.callbackMode === "default") {
        this.ipAddressCallback = "";
      }
    },
    ipAddressCallback() {
      this.store();
    },
    aspoofing(val) {
      if (val != this.antispoofingMode && val == "off") {
        this.pdlg = true;
        this.pconfirm = "";
      } else {
        this.antispoofingMode = val;
      }
      this.recognitionRangeMax = 3;
      //this.recognitionRangeMax = val != "off" ? 2 : 3;

      this.store();
    },
    controlMode() {
      this.store();
    },
    extraFactor(val) {
      this.store();
    },
    extraFactorTimeoutSec(val) {
      this.store();
    },
    extraFactorUrl(val) {
      this.store();
    },
    extraFactorMethod(val) {
      this.store();
    },
    extraFactorMode(val) {
      this.store();
    },
    extraFactorAttempsLimit(val) {
      this.store();
    },
    temperatureEnable() {
      this.store();
    },
    systemmode(systemmode) {
      this.store();

      if (systemmode === "temp_sensor") {
        this.authenticationType = "face";
        this.controlMode = "only_led";
      }

      if (systemmode === "bypass") {
        this.controlMode = "card";
      }
    },
    displayMode() {
      this.store();
    },
    cardtype() {
      this.store();
    },
    authenticationType(authenticationType) {
      if (!this.authModeByFace(authenticationType)) {
        this.silentMode = false;
      }
      if (authenticationType === "card" || authenticationType === "qr_code") {
        this.temperatureEnable = false;
      }
      this.store();
    },
    showName() {
      this.store();
    },
    recognitionRange() {
      this.store();
    },
    isEnableScreensaver() {
      this.store();
    },
    screensaverDelay() {
      this.store();
    },
    recognitionFace() {
      this.store();
    },
    recognitionMask() {
      this.store();
    },
    mltimeout() {
      this.store();
    },
    controller_polling_delay() {
      this.store();
    },
    autoregistrationMode() {
      this.store();
    },
    maskEnabled() {
      this.store();
    },
    showFullname() {
      this.store();
    },
    showAccess() {
      this.store();
    },
    showThempInfo() {
      this.store();
    },
    showDirection() {
      this.store();
    },
    effectiveWiegandLen() {
      this.store();
    },
    cardRepeatMode() {
      this.store();
    },
    asReader() {
      this.store();
    },
    escort_mode() {
      this.store();
    },
  },
  data() {
    return {
      callbackMode: "default",
      ipAddressCallback: "",
      newCode: 0,
      cardRepeatMode: false,
      asReader: false,
      pdlg: false,
      pdlg2: false,
      pconfirm2: "",
      loading: false,
      escort_mode: false,
      recognitionRange: this.getFft(2),
      isEnableScreensaver: false,
      screensaverDelay: 7,
      temperatureEnable: false,
      showFullname: "",
      controlMode: "no",
      silentMode: false,
      recMode: "local",
      recognitionMode: "local",
      authenticationsType: "face",
      showName: "full",
      recognitionServer: "",
      recognitionItems: [
        {
          name: this.$ml.get("devices.recognitionLocal"),
          value: "local",
        },
        {
          name: this.$ml.get("devices.recognitionCloud"),
          value: "by_frame_in_cloud",
        },
      ],
      shownames: [
        { name: this.$ml.get("devices.showNo"), val: "no" },
        { name: this.$ml.get("devices.showFullName"), val: "full" },
        { name: this.$ml.get("devices.showShortName"), val: "short" },
        { name: this.$ml.get("devices.showTabNum"), val: "tab_num" },
      ],
      enabled: true,
      mltimeout: 0,
      controller_polling_delay: 3,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 20 || "Name must be less than 20 characters",
      ],
      systemmode: "default",
      systemmodes: [
        {
          val: "default",
          name: this.$ml.get("devices.systemModeDefault"),
        },
        {
          val: "bypass",
          name: this.$ml.get("devices.systemModeBypass"),
        },
        {
          val: "temp_sensor",
          name: this.$ml.get("devices.ThermalSensor"),
        },
        // {
        //   val: "temperature_pass",
        //   name: this.$ml.get("devices.TemperaturePass"),
        // },
      ],
      cardtype: "WDEC26",
      cardtypes: [
        { name: "Wiegand26", val: "WDEC26" },
        { name: "Wiegand34", val: "WDEC34" },

        { name: "Wiegand37", val: "WDEC37" },
        { name: "Wiegand42", val: "WDEC42" },
        { name: "Wiegand44", val: "WDEC44" },
        { name: "Wiegand48", val: "WDEC48" },
        { name: "Wiegand50", val: "WDEC50" },

        { name: "Wiegand58", val: "WDEC58" },
        { name: "WiegandUni", val: "UDEC" },
        { name: "Touch Memory", val: "TM" },
      ],
      recognitionRangeMax: 3,
      recognitionFace: 0.7,
      recognitionMask: 0.7,
      effectiveWiegandLen: 0,
      regimMask: false,
      showAccess: false,
      showThempInfo: false,
      maskEnabled: false,
      passlist: ["only_led", "door", "door_inverted", "card"],
      errorMessage: "",
      autoregistrationMode: false,
      authenticationType: "face",
      antispoofingMode: "print",
      aspoofing: "print",
      pconfirm: "",
      apiVersion: "",
      deviceType: "OGATE",
      featuresVersion: {
        basic: {
          version: "",
          thresholds: {
            min: 0.5,
            max: 0.6,
          },
        },
        masked: {
          version: "",
          thresholds: {
            min: 0.5,
            max: 0.6,
          },
        },
      },
      antispoofing: [
        { val: "off", name: this.$ml.get("button.off") },
        { val: "phone", name: this.$ml.get("button.AntispoofingPhone") },
        //{ val: "print", name: this.$ml.get("button.AntispoofingPhoto") },
        { val: "print", name: this.$ml.get("button.AntispoofingFull") },
      ],
      displayMode: "static",
      displaymodes: [
        { val: "static", name: this.$ml.get("devices.modeDiaplayStatic") },
        { val: "video", name: this.$ml.get("devices.modeDiaplayVideo") },
      ],

      showDirection: false,
      notificationReferenceBooks: [],
      notificationReferenceBooksCodes: [
        { val: 7, name: this.$ml.get("devices.wiegandError7") },
        { val: 12, name: this.$ml.get("devices.wiegandError12") },
        { val: 23, name: this.$ml.get("devices.wiegandError23") },
        { val: 3, name: this.$ml.get("devices.wiegandError3") },
        { val: 7, name: this.$ml.get("devices.wiegandError7") },
        { val: 5, name: this.$ml.get("devices.wiegandError5") },
        { val: 27, name: this.$ml.get("devices.wiegandError27") },
        { val: 9, name: this.$ml.get("devices.wiegandError9") },
        { val: 13, name: this.$ml.get("devices.wiegandError13") },
        { val: 20, name: this.$ml.get("devices.wiegandError20") },
        { val: 24, name: this.$ml.get("devices.wiegandError24") },
        { val: 26, name: this.$ml.get("devices.wiegandError26") },
        { val: 29, name: this.$ml.get("devices.wiegandError29") },
        { val: 36, name: this.$ml.get("devices.wiegandError36") },
        { val: 37, name: this.$ml.get("devices.wiegandError37") },
        { val: 100, name: this.$ml.get("devices.wiegandError100") },
      ],
      extraFactor: false,
      extraFactorTimeoutSec: 10,
      extraFactorUrl: "http://192.168.88.167:7777",
      extraFactorMethod: "/pass",
      extraFactorMode: "hard",
      extraFactorAttempsLimit: 3,
      state_text: {},

      callbackItems: [
        { text: this.$ml.get("devices.callbackDefault"), value: "default" },
        { text: this.$ml.get("devices.callbackCustom"), value: "custom" },
      ],

      hexRules: [(v) => isHexadecimal(v) || this.$ml.get("message.isHex")],
      recognitionServerRules: [
        (v) => !!v || this.$ml.get("message.NameIsRequired"),
        (v) => isURL(v) || this.$ml.get("message.isCallbackIP"),
      ],
      callbackIpRules: [
        (v) => !!v || this.$ml.get("message.NameIsRequired"),
        (v) => isURL(v) || this.$ml.get("message.isCallbackIP"),
      ],
    };
  },
  methods: {
    removeWError(code) {
      this.notificationReferenceBooks = this.notificationReferenceBooks.filter(
        (item) => item.code !== code
      );
      this.store();
    },
    addWError() {
      if (this.newCode == 0) {
        return;
      }
      this.notificationReferenceBooks.push({
        value: "",
        code: this.newCode,
      });
      this.newCode = 0;
    },
    getWiegandErrorTitle(code) {
      let a = this.notificationReferenceBooksCodes.find(
        (item) => item.val === code
      );
      if (a) {
        return a.name;
      }
    },
    confirm(ev) {
      if (ev && this.pconfirm == "facechain123") {
        this.antispoofingMode = this.aspoofing;
      } else {
        this.aspoofing = this.antispoofingMode;
      }
      this.store();
      this.pdlg = false;
    },
    recModeChange(val) {
      if (val != this.recognitionMode) {
        this.pdlg2 = true;
        this.pconfirm2 = "";
      } else {
        this.recognitionMode = val;
      }

      this.store();
    },
    confirm2(ev) {
      if (ev && this.pconfirm2 == "facechain123") {
        this.recognitionServer = "";
        this.recognitionMode = this.recMode;
      } else {
        this.recMode = this.recognitionMode;
      }
      this.store();
      this.pdlg2 = false;
    },
    controlModeSelect(mode) {
      return mode.indexOf("card") != -1;
    },
    authModeByFace(mode) {
      return mode.indexOf("face") != -1;
    },
    getFft(m) {
      if (this.l !== undefined && this.l["temp_meas"] == "f") {
        return Math.round(m * 3.28084 * 10) / 10;
      } else {
        return m;
      }
    },
    getMetr(m) {
      if (this.l !== undefined && this.l["temp_meas"] == "f") {
        return Math.round((m / 3.28084) * 10) / 10;
      } else {
        return m;
      }
    },
    store() {
      let temperature_enable = this.temperatureEnable ? "on" : "off";
      if (this.systemmode == "temperature_pass") {
        this.showAccess = false;
      }
      if (
        (this.systemmode != "default" && this.systemmode != "bypass") ||
        this.authenticationType != "face"
      ) {
        this.cardRepeatMode = false;
      }
      let basic = this.featuresVersion.basic;
      let masked = this.featuresVersion.masked;
      let data = {
        escort_mode: this.escort_mode,
        api_version: this.apiVersion,
        temperature_enable: temperature_enable,
        recognition_range: this.getMetr(this.recognitionRange),
        screen_saver: this.isEnableScreensaver,
        screen_saver_delay: this.screensaverDelay,
        control_mode: this.controlMode,
        card_type: this.cardtype,
        authentication_type: this.authenticationType,
        pass_timeout: this.mltimeout,
        controller_polling_delay: this.controller_polling_delay,
        system_mode: this.systemmode,
        recognition_face: this.recognitionFace,
        recognition_mask: this.recognitionMask,
        mask_enabled: this.maskEnabled,
        show_name: this.showName,
        show_themp_info: this.showThempInfo,
        show_access: this.showAccess,
        antispoofing_mode: this.antispoofingMode,
        recognition_mode: this.recognitionMode,
        recognition_server: this.recognitionServer,
        as_reader: this.asReader,
        card_repeat_mode: this.cardRepeatMode,
        display_mode: this.displayMode,
        autoregistration_mode: this.autoregistrationMode,
        device_type: this.deviceType,
        features_version: { basic, masked },
        show_direction: this.showDirection,
        effective_wiegand_len: this.effectiveWiegandLen,
        notification_reference_book: this.notificationReferenceBooks,
        silent_mode: this.silentMode,
        // [
        //   this.notificationReferenceBook7,
        //   this.notificationReferenceBook12,
        //   this.notificationReferenceBook23,
        //   this.notificationReferenceBook3,
        // ],
        extra_factor: this.extraFactor,
        extra_factor_timeout_sec: parseInt(this.extraFactorTimeoutSec),
        extra_factor_url: this.extraFactorUrl,
        extra_factor_method: this.extraFactorMethod,
        extra_factor_mode: this.extraFactorMode,
        extra_factor_attemps_limit: parseInt(this.extraFactorAttempsLimit),
        state_text: this.stateText,
      };

      if (this.ipAddressCallback) {
        data.ip_address_callback = this.ipAddressCallback;
      }

      this.$emit("input", data);
    },
    getRegimMask() {
      let _this = this;
      this.axios.get("/setting/MASK_RECOGNITION").then((response) => {
        if (response.status == 200) {
          let val = response.data.data.value || "off";
          _this.regimMask = val == "on";
        }
      });
    },
    getData(data) {
      this.escort_mode = data["escort_mode"] || false;
      this.ipAddressCallback = data["ip_address_callback"] || "";
      this.callbackMode = data["ip_address_callback"] ? "custom" : "default";
      this.asReader = data["as_reader"] || false;
      this.cardRepeatMode = data["card_repeat_mode"] || false;
      this.temperatureEnable = data["temperature_enable"] == "on";
      this.recognitionRange = this.getFft(data["recognition_range"] || 2);
      this.isEnableScreensaver = data["screen_saver"] || false;
      this.screensaverDelay = data["screen_saver_delay"] || 7;
      this.recognitionFace = data["recognition_face"] || 0;
      this.recognitionMask = data["recognition_mask"] || 0;
      this.controlMode = data["control_mode"] || "no";
      this.apiVersion = data["api_version"] || "";
      this.effectiveWiegandLen = data["effective_wiegand_len"] || 24;
      this.authenticationType = data["authentication_type"] || "face";
      this.systemmode = data["system_mode"] || "default";
      this.showFullname = data["show_fullname"] || false;
      this.showName = data["show_name"] || "full";
      this.showAccess = data["show_access"] || false;
      this.showThempInfo = data["show_themp_info"] || false;
      this.recognitionMode = data["recognition_mode"] || "local";
      this.recognitionServer = data["recognition_server"] || "";
      this.recMode = data["recognition_mode"] || "local";
      this.maskEnabled = data["mask_enabled"] || false;
      this.mltimeout = data["pass_timeout"] || 0;
      this.controller_polling_delay =
        data["controller_polling_delay"] ||
        data["controller_polling_delay"] === 0
          ? data["controller_polling_delay"]
          : 3;
      this.silentMode = data["silent_mode"] ? data["silent_mode"] : false;
      this.cardtype = data["card_type"] || "WDEC26";
      this.autoregistrationMode = data["autoregistration_mode"] || false;
      this.aspoofing = data["antispoofing_mode"] || "off";
      this.antispoofingMode = this.aspoofing;
      this.displayMode = data["display_mode"] || "static";
      this.deviceType = data["device_type"] || "OGATE";
      this.featuresVersion = data["features_version"] || {
        basic: { version: "", thresholds: { min: 0.5, max: 0.6 } },
        masked: { version: "", thresholds: { min: 0.5, max: 0.6 } },
      };
      this.showDirection = data["show_direction"];
      if (data["notification_reference_book"]) {
        this.notificationReferenceBooks =
          data["notification_reference_book"] || [];
        // this.notificationReferenceBook7 =
        //   data["notification_reference_book"][0];
        // this.notificationReferenceBook12 =
        //   data["notification_reference_book"][1];
        // this.notificationReferenceBook23 =
        //   data["notification_reference_book"][2];
        // this.notificationReferenceBook3 =
        //   data["notification_reference_book"][3];
      }
      this.stateText = data["state_text"] || {};
      this.extraFactor = data["extra_factor"] || false;
      this.extraFactorTimeoutSec = data["extra_factor_timeout_sec"] || 10;
      this.extraFactorUrl =
        data["extra_factor_url"] || "http://192.68.88.164:7777";
      this.extraFactorMethod = data["extra_factor_method"] || "/pass";
      this.extraFactorMode = data["extra_factor_mode"] || "hard";
      this.extraFactorAttempsLimit = data["extra_factor_attemps_limit"] || 3;
    },

    checkAuthenticationCard(auth) {
      return auth.indexOf("card") != -1;
    },

    updateChannels(e, index) {
      const channels = this.channels;
      channels[index] = e;

      this.$emit("change-channels", channels);
    },
  },

  computed: {
    ...mapState({
      l: (state) => state.settings.data,
    }),

    notificationCodes() {
      let codes = [];
      this.notificationReferenceBooksCodes.forEach((c) => {
        if (
          !this.notificationReferenceBooks.find((item) => item.code === c.val)
        ) {
          codes.push(c);
        }
      });
      return codes;
    },
    mltimeoutMin() {
      if (this.controlMode == "dry_contact") {
        return 1;
      } else {
        return 3;
      }
    },
    effectiveWiegandLens() {
      const list = [
        { name: "16 bit", val: 16 },
        { name: "24 bit", val: 24 },
        { name: "32 bit", val: 32 },
        { name: "35 bit", val: 35 },
        { name: "40 bit", val: 40 },
        { name: "42 bit", val: 42 },
        { name: "46 bit", val: 46 },
        { name: "48 bit", val: 48 },
        { name: "56 bit", val: 56 },
        { name: "64 bit", val: 64 },
      ];
      return list;
    },
    authentications() {
      let list = [{ name: this.$ml.get("devices.auth.face"), val: "face" }];

      if (
        (this.systemmode === "default" || this.systemmode === "bypass") &&
        this.apiVersion
      ) {
        list = [
          ...list,

          { name: this.$ml.get("devices.auth.card"), val: "card" },
          { name: this.$ml.get("devices.auth.qrCode"), val: "qr_code" },

          {
            name: this.$ml.get("devices.auth.faceOrCard"),
            val: "face_or_card",
          },
          {
            name: this.$ml.get("devices.auth.faceOrQrCode"),
            val: "face_or_qr_code",
          },
          {
            name: this.$ml.get("devices.auth.faceOrCardOrQrCode"),
            val: "face_or_card_or_qr_code",
          },
        ];

        switch (this.deviceType) {
          case "OGATEV8":
            break;
          default:
            list = [
              ...list,

              {
                name: this.$ml.get("devices.auth.faceAndCard"),
                val: "face_and_card",
              },
            ];
            break;
        }
      }

      return list;
    },

    controls() {
      let list = [];

      if (this.systemmode === "bypass") {
        list = [
          {
            name: this.$ml.get("controls.card"),
            val: "card",
            description: this.$ml.get("controls.cardDescription"),
          },
          {
            name: this.$ml.get("controls.cardConfirm"),
            val: "card_confirm",
            description: this.$ml.get("controls.cardConfirmDescription"),
          },
          {
            name: this.$ml.get("controls.cardPass"),
            val: "card_pass",
            description: this.$ml.get("controls.cardPassDescription"),
          },
        ];
      }

      if (this.systemmode === "default") {
        list = [
          {
            name: this.$ml.get("controls.card"),
            val: "card",
            description: this.$ml.get("controls.cardDescription"),
          },
          {
            name: this.$ml.get("controls.cardConfirm"),
            val: "card_confirm",
            description: this.$ml.get("controls.cardConfirmDescription"),
          },
          {
            name: this.$ml.get("controls.cardPass"),
            val: "card_pass",
            description: this.$ml.get("controls.cardPassDescription"),
          },
          //{ name: this.$ml.get("controls.cardPassConfirm"), val: "card_pass_confirm" },
          {
            name: this.$ml.get("controls.dryContact"),
            val: "dry_contact",
            description: this.$ml.get("controls.dryContactDescription"),
          },
          {
            name: this.$ml.get("controls.door"),
            val: "door",
            description: this.$ml.get("controls.doorDescription"),
          },
          {
            name: this.$ml.get("controls.doorInverted"),
            val: "door_inverted",
            description: this.$ml.get("controls.doorInvertedDescription"),
          },
          {
            name: this.$ml.get("controls.doorInvertedImpulse"),
            val: "door_inverted_impulse",
            description: this.$ml.get(
              "controls.doorInvertedImpulseDescription"
            ),
          },
          {
            name: this.$ml.get("controls.onlyLed"),
            val: "only_led",
            description: this.$ml.get("controls.onlyLedDescription"),
          },
          {
            name: this.$ml.get("controls.no"),
            val: "no",
            description: this.$ml.get("controls.noDescription"),
          },
        ];
      }

      if (this.systemmode === "temp_sensor") {
        list = [
          {
            name: this.$ml.get("controls.onlyLed"),
            val: "only_led",
            description: this.$ml.get("controls.onlyLedDescription"),
          },
          {
            name: this.$ml.get("controls.no"),
            val: "no",
            description: this.$ml.get("controls.noDescription"),
          },
        ];
      }

      return list;
    },
  },

  created() {
    if (this.value) {
      this.getData(this.value);
      this.getRegimMask();
    }
  },
};
</script>
