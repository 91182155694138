import { mapState } from "vuex";

export default {
  methods: {
    formatLocateDate(date) {
      const FORMAT_DATE_RU = "DD.MM.YYYY";
      const FORMAT_DATE_EN = "MM/DD/YYYY";

      if (!date) return null;

      switch (this.settings.lang) {
        case "ru":
          return this.$moment(date).format(FORMAT_DATE_RU);
        default:
          return this.$moment(date).format(FORMAT_DATE_EN);
      }
    },
  },

  computed: {
    firstDayOfWeek() {
      switch (this.settings.lang) {
        case "ru":
          return 1;
        default:
          return 0;
      }
    },

    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },
};
