<template>
  <v-app class="d-flex add-new-visitor-form-container add-new-visitor-form">
    <v-container>
      <v-row>
        <v-col cols="12" sm="8">
          <v-text-field
            :label="`${$ml.get('schedule.nameSchedule')} *`"
            v-model="name"
            outlined
            required
          ></v-text-field>
          <v-textarea
            :label="$ml.get('schedule.description')"
            v-model="description"
            outlined
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="4">
          <v-switch
            v-model="check_week"
            flat
            :label="$ml.get('schedule.check_week')"
          ></v-switch>
          <WeekDaysCheckbox
            v-if="check_week"
            v-model="schedule.weekdays"
            @toggle="schedule.weekdays = $event"
          />
          <v-switch
            v-model="check_date"
            flat
            :label="$ml.get('schedule.check_date')"
          ></v-switch>
          <v-row v-if="check_date">
            <v-col cols="12" lg="6">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFrom"
                    :label="$ml.get('dtpicker.dateFrom')"
                    :hint="$ml.get('dtpicker.dateFormat')"
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="schedule.date_from"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" lg="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateTo"
                    :label="$ml.get('dtpicker.dateTo')"
                    :hint="$ml.get('dtpicker.dateFormat')"
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="schedule.date_to"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="6">
              <div class="md-form md-outline">
                <input
                  type="time"
                  id="default-picker"
                  class="form-control"
                  :placeholder="$ml.get('dtpicker.startTime')"
                  v-model="timeFrom"
                />
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <div class="md-form md-outline">
                <input
                  type="time"
                  id="default-picker"
                  class="form-control"
                  :placeholder="$ml.get('dtpicker.toTime')"
                  v-model="timeTo"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <button class="white-link-button" type="button" @click.prevent="back">
        {{ $ml.get("button.back") }}
      </button>
      <button
        v-if="id"
        class="red-transparent-link-button"
        type="button"
        @click.prevent="remove"
      >
        {{ $ml.get("button.delete") }}
      </button>
      <button
        v-if="name && (check_week || check_date || timeFrom || timeTo)"
        class="blue-link-button"
        type="button"
        @click.prevent="store"
      >
        {{ $ml.get("button.save") }}
      </button>
    </v-container>
  </v-app>
</template>
<script>
import WeekDaysCheckbox from "../../components/WeekDaysCheckbox";
import router from "../../router";
import { mapState } from "vuex";

// CSS
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  data() {
    return {
      schedule: {
        weekdays: "1111100",
        date_from: "",
        date_to: "",
        time_from: "",
        time_to: "",
      },
      id: this.$route.params.id || 0,
      name: "",
      description: "",
      check_week: false,
      check_date: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      timeFrom: "00:00",
      timeTo: "23:59",
    };
  },
  components: { WeekDaysCheckbox },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      let lang = this.settings.lang || "ru";
      if (lang == "ru") {
        return `${day}.${month}.${year}`;
      } else return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getLoad() {
      if (this.id > 0) {
        this.axios.get("/schedule/" + this.id).then((response) => {
          if (response.status == 200) {
            if (response.data.data.data) {
              this.schedule = JSON.parse(response.data.data.data);
              if (this.schedule.time_from) {
                this.timeFrom = this.schedule.time_from;
              }
              if (this.schedule.time_to) {
                this.timeTo = this.schedule.time_to;
              }
            }
            this.check_week = true;
            if (
              !this.schedule.weekdays ||
              this.schedule.weekdays == "0000000"
            ) {
              this.schedule.weekdays = "0000000";
              this.check_week = false;
            }
            if (this.schedule.date_from || this.schedule.date_to) {
              this.check_date = true;
            }
            this.name = response.data.data.name;
            this.description = response.data.data.description;
          }
        });
      }
    },
    store() {
      if (!this.check_week) {
        this.schedule.weekdays = "";
      }

      if (!this.check_date) {
        this.schedule.date_from = "";
        this.schedule.date_to = "";
      }
      if (this.schedule.date_from > this.schedule.date_to) {
        this.schedule.date_to = this.schedule.date_from;
      }

      if (this.timeFrom) {
        if (typeof this.timeFrom == "object") {
          this.timeFrom =
            (this.timeFrom["HH"] || "00") + ":" + (this.timeFrom["mm"] || "00");
        }
        this.schedule.time_from = this.timeFrom;
      }
      if (this.timeTo) {
        if (typeof this.timeTo == "object") {
          this.timeTo =
            (this.timeTo["HH"] || "23") + ":" + (this.timeTo["mm"] || "59");
        }
        // if (this.timeTo < this.schedule.time_from)
        //   this.timeTo = this.schedule.time_from;
        this.schedule.time_to = this.timeTo;
      }
      let form = {
        name: this.name,
        description: this.description,
        data: JSON.stringify(this.schedule),
      };

      let config = {
        header: {
          "Content-Type": "application/json",
        },
        timeout: 20000,
      };

      if (!this.id) {
        this.axios.post("/schedule/add", form, config).then((response) => {
          if (response.data.success == false) {
            this.errorMessage = response.data.message;
          } else {
            router.push({ path: `/schedules` });
          }
        });
      } else {
        this.axios
          .put("/schedule/" + this.id, form, config)
          .then((response) => {
            if (response.data.success == false) {
              this.errorMessage = response.data.message;
            } else {
              router.push({ path: `/schedules` });
            }
          });
      }
    },
    back() {
      router.push({ path: `/schedules` });
    },
    remove() {
      let options = {
        loader: false,
        okText: this.$ml.get("button.delete"),
        cancelText: this.$ml.get("button.cancel"),
        backdropClose: true,
      };
      let _this = this;
      this.$dialog
        .confirm(
          `<h3>${this.$ml.get("message.Delete")}</h3>` +
            this.$ml.get("message.confirmDelete"),
          {
            loader: false,
            okText: this.$ml.get("button.delete"),
            cancelText: this.$ml.get("button.cancel"),
            backdropClose: true,
          }
        )
        .then(() => {
          this.axios.delete("/schedule/" + _this.id).then((response) => {
            if (response.statusText == "OK") {
              if (response.data.success == false) {
                _this.errorMessage = response.data.message;
              } else {
                router.push({ path: `/schedules` });
              }
            }
          });
        });
    },
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),

    computedDateFrom() {
      return this.formatDate(this.schedule.date_from);
    },
    computedDateTo() {
      return this.formatDate(this.schedule.date_to);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  mounted() {
    this.getLoad();
  },
};
</script>
<style scoped>
button {
  margin-right: 10px;
  width: 180px;
  height: 54px;
}
/* .white-link-button {
  margin-right: 50px;
} */
</style>
