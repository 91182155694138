<template>
  <v-card
    :color="
      'enable' in channelState && !channelState.enable ? '#F3F2FF' : '#DCD9FD'
    "
    elevation="0"
  >
    <v-card-title class="text-h5">
      <v-row align-content="center">
        <v-col>{{ title }}</v-col>
        <v-col v-if="'enable' in channelState" cols="auto">
          <v-switch v-model="channelState.enable" hide-details class="mt-0" />
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col v-if="'name' in channelState && isShowName">
          <v-text-field
            v-model="channelState.name"
            :label="$ml.get('devices.nameChannel')"
            hide-details
            outlined
            required
            :disabled="isDisabledForm"
          />
        </v-col>
        <v-col>
          <v-select
            v-model="channelState.purpose"
            :items="modeItems"
            item-text="name"
            item-value="val"
            :label="$ml.get('devices.purpose')"
            hide-details
            outlined
            :disabled="isDisabledForm"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            ref="zones"
            v-model="channelState.zones_id"
            :items="zonesTo"
            item-text="name"
            item-value="id"
            :label="
              channelState.purpose === 'exit'
                ? `${$ml.get('devices.zoneFrom')} *`
                : `${$ml.get('devices.zoneTo')} *`
            "
            :disabled="isDisabledForm"
            hide-details
            outlined
          />
        </v-col>
        <v-col>
          <v-select
            ref="sources"
            v-model="source_id"
            :items="zonesFrom"
            item-text="name"
            item-value="id"
            :label="
              channelState.purpose === 'exit'
                ? `${$ml.get('devices.zoneTo')} *`
                : `${$ml.get('devices.zoneFrom')} *`
            "
            :disabled="isDisabledForm"
            hide-details
            outlined
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- <v-col v-if="'is_door_lock_mode' in channelState">
          <v-switch
            v-model="channelState.is_door_lock_mode"
            :label="$ml.get('devices.doorLockMode')"
            :disabled="isDisabledForm"
            hide-details
            class="mt-0"
          />
        </v-col> -->

        <!-- <v-col
          v-if="
            'is_fixed_pass' in channelState && !channelState.is_door_lock_mode
          "
        >
          <v-switch
            v-model="channelState.is_fixed_pass"
            :label="$ml.get('devices.fixedPass')"
            :disabled="isDisabledForm"
            hide-details
            class="mt-0"
          />
        </v-col> -->

        <v-col v-if="'relay_id' in channelState && isShowRelays">
          <v-select
            v-model="channelState.relay_id"
            :items="relaysOptions"
            :label="$ml.get('devices.relay')"
            outlined
            hide-details
            :disabled="isDisabledForm"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import debounce from "lodash.debounce";

const PURPOSE_ENTRANCE = "entrance";
const PURPOSE_EXIT = "exit";
const PURPOSE_DUPLEX = "duplex";
const PURPOSE_BASICLOCK = "basiclock";

export default {
  name: "Channel",

  props: {
    channel: {
      type: Object,
      required: true,
    },

    relays: {
      type: Array,
      default: () => [],
    },

    isShowRelays: {
      type: Boolean,
      default: true,
    },

    isShowName: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    isController: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    channelState: {
      handler: debounce(function () {
        this.$emit("change", this.channelState);
      }, 300),
      deep: true,
    },

    source_id() {
      this.channelState.source_id = this.source_id;
      if (this.source_id) {
        this.channelState.purpose = PURPOSE_ENTRANCE;
      }
    },
  },

  data() {
    return {
      channelState: this.channel,
      source_id: this.channel.source_id,
      zones: [],
      purposeItems: [
        { val: PURPOSE_ENTRANCE, name: this.$ml.get("button.entrance") },
        { val: PURPOSE_EXIT, name: this.$ml.get("button.exit") },
        { val: PURPOSE_DUPLEX, name: this.$ml.get("button.duplex") },
        { val: PURPOSE_BASICLOCK, name: this.$ml.get("button.basiclock") },
      ],
    };
  },

  methods: {
    getZones() {
      this.axios("zones").then((response) => {
        this.zones = [
          { id: null, name: this.$ml.get("devices.zoneEmpty") },
          ...response.data.data,
        ];
      });
    },
  },

  computed: {
    zonesTo() {
      return this.zones.filter(
        (zone) => zone.id !== this.channelState.source_id && zone.id !== null
      );
    },

    modeItems() {
      return !this.isController
        ? this.purposeItems.filter((item) => item.val != PURPOSE_BASICLOCK)
        : this.purposeItems;
    },

    zonesFrom() {
      return this.zones.filter(
        (zone) => zone.id !== this.channelState.zones_id
      );
    },

    isDisabledForm() {
      return "enable" in this.channelState ? !this.channelState.enable : false;
    },

    relaysOptions() {
      return this.relays.map((relay) => ({
        value: relay.id,
        text: `${this.$ml.get("devices.relay")} ${relay.id}`,
      }));
    },
  },

  created() {
    this.getZones();
  },
};
</script>
