<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="callbackMode"
          :items="callbackItems"
          :label="$ml.get('devices.callbackMode')"
          outlined
        />
      </v-col>
      <v-col v-if="callbackMode === 'custom'" cols="12" md="6">
        <v-text-field
          v-model="ipAddressCallback"
          :rules="callbackIpRules"
          :label="`${$ml.get('devices.ipCallback')} *`"
          outlined
          required
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(channel, index) of channels"
        :key="channel.id || channel.key"
        cols="12"
        :md="getChannelCol(index)"
      >
        <SmartCameras
          :channel="channel"
          :index="index"
          :isShowRemoveBtn="channels.length > 1"
          @change="updateChannels($event, index)"
          @remove-channel="removeChannel($event)"
        />
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          v-if="channels.length < 5"
          link
          @click="addChannel"
          >{{ $ml.get("devices.addCamera") }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { isURL } from "validator";
import SmartCameras from "../SmartCameras.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    purpose: {
      type: String,
      default: "",
    },
    channels: {
      type: Array,
      default: () => [],
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    SmartCameras,
  },

  watch: {
    callbackMode() {
      if (this.callbackMode === "default") {
        this.ipAddressCallback = "";
      }
    },
    ipAddressCallback() {
      this.store();
    },
  },

  data() {
    return {
      callbackMode: "default",
      ipAddressCallback: "",

      callbackItems: [
        { text: this.$ml.get("devices.callbackDefault"), value: "default" },
        { text: this.$ml.get("devices.callbackCustom"), value: "custom" },
      ],
      callbackIpRules: [
        (v) => !!v || this.$ml.get("message.NameIsRequired"),
        (v) => isURL(v) || this.$ml.get("message.isCallbackIP"),
      ],
    };
  },
  methods: {
    removeChannel(channel) {
      this.$emit("remove-channel", channel);
    },

    store() {
      let data = {};

      if (this.ipAddressCallback) {
        data.ip_address_callback = this.ipAddressCallback;
      }

      this.$emit("input", data);
    },
    getData(data) {
      this.ipAddressCallback = data["ip_address_callback"] || "";
      this.callbackMode = data["ip_address_callback"] ? "custom" : "default";
    },

    updateChannels(e, index) {
      const channels = this.channels;
      channels[index] = e;

      this.$emit("change-channels", channels);
    },
    addChannel() {
      this.$emit("add-channel");
    },

    getChannelCol(index) {
      if (
        this.channels.length === index + 1 &&
        this.channels.length % 2 === 1
      ) {
        return 12;
      } else {
        return 6;
      }
    },
  },

  created() {
    if (this.value) {
      this.getData(this.value);
    }
  },
};
</script>
