<template>
  <v-container>
    <v-app>
      <v-card elevation="2">
        <v-card-text>
          <v-alert v-if="isFailure && errorMessage" type="error">{{
            errorMessage
          }}</v-alert>

          <v-row class="mb-4">
            <v-col>
              <v-btn @click="goBack">{{ $ml.get("button.back") }}</v-btn>
            </v-col>
          </v-row>

          <v-textarea
            v-if="!isParsedContacts"
            v-model="contacts"
            :label="$ml.get('requests.insertContacts')"
            :placeholder="$ml.get('requests.insertContactsExample')"
            outlined
          />

          <template v-else>
            <v-expansion-panels focusable class="mt-5">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row align="center">
                    <v-col>
                      {{ $ml.get("requests.parsedRequestItems") }} -
                      {{ contactsParsed.length }}
                    </v-col>
                    <v-spacer />
                    <v-col v-if="!isImporting" cols="auto">
                      <v-btn small icon @click="isParsedContacts = false">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $ml.get("requests.name") }}
                          </th>
                          <th class="text-left">
                            {{ $ml.get("requests.email") }}
                          </th>
                          <th class="text-left">
                            {{ $ml.get("requests.phone") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in contactsParsed"
                          :key="index"
                        >
                          <td>{{ item.full_name }}</td>
                          <td>{{ item.email }}</td>
                          <td>{{ item.phone }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <Form
              v-if="!isImporting"
              ref="addForm"
              isMulti
              :description="form.description"
              :onepass="form.onepass"
              :dueFrom="form.due_from"
              :dueTo="form.due_to"
              :subdivisions="form.subdivisions"
              :status="form.status"
              :requestType="form.request_type"
              :department="form.department"
              :purpose="form.purpose"
              :userType="form.user_type"
              :cards="form.cards"
              :local="local"
              @change="onChange"
            />

            <v-row v-if="isImporting" class="mt-5">
              <v-col>
                <v-progress-linear
                  :value="importProgress"
                  :color="importProgress === 100 ? 'success' : 'primary'"
                  height="28"
                  rounded
                >
                  <strong
                    >{{ $ml.get("requests.import") }} -
                    {{ Math.ceil(importProgress) }}%</strong
                  >
                </v-progress-linear>
              </v-col>

              <v-col v-if="importErrors.length" cols="auto">
                <v-dialog v-model="isShowImportErrorDialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge
                      color="primary"
                      :content="importErrors.length"
                      bordered
                      overlap
                    >
                      <v-btn small color="error" v-bind="attrs" v-on="on">
                        <v-icon left>mdi-alert-circle-outline</v-icon>
                        {{ $ml.get("requests.errors") }}
                      </v-btn>
                    </v-badge>
                  </template>

                  <v-card>
                    <v-card-title>
                      <span class="headline"
                        >{{ $ml.get("requests.errors") }} -
                        {{ importErrors.length }}</span
                      >
                    </v-card-title>

                    <v-card-text>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <tbody>
                            <tr
                              v-for="(item, index) in importErrors"
                              :key="index"
                            >
                              <td>{{ item.full_name }}</td>
                              <td>
                                {{ item.error }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="success" text @click="correct">
                        {{ $ml.get("button.refeature") }}
                      </v-btn>
                      <v-btn text @click="isShowImportErrorDialog = false">
                        {{ $ml.get("button.close") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </template>

          <v-row v-if="!isImporting" justify="end">
            <v-col cols="auto">
              <v-btn
                v-if="!isParsedContacts"
                color="primary"
                @click="parseContacts"
                >{{ $ml.get("button.continue") }}</v-btn
              >
              <v-btn
                v-else
                color="primary"
                :loading="isPending"
                :disabled="isPending"
                @click="add"
                >{{ $ml.get("button.add") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-app>
  </v-container>
</template>

<script>
import Form from "./form";

export default {
  components: { Form },

  data() {
    return {
      local: {
        description: {
          guest: localStorage.getItem("requestGuestDescription") || "",
          employee: localStorage.getItem("requestEmployeeDescription") || "",
        },
        department: {
          guest:
            parseInt(localStorage.getItem("requestGuestDepartment")) || null,
          employee:
            parseInt(localStorage.getItem("requestEmployeeDepartment")) || null,
        },
        subdivisions: {
          guest:
            JSON.parse(localStorage.getItem("requestGuestSubdivisions")) || [],
          employee:
            JSON.parse(localStorage.getItem("requestEmployeeSubdivisions")) ||
            [],
        },
      },

      isPending: false,
      isSuccess: false,
      isFailure: false,
      isImporting: false,
      errorMessage: "",

      isParsedContacts: false,
      contacts: "",
      contactsParsed: [],
      form: {},

      importProgress: 0,
      importErrors: [],
      isShowImportErrorDialog: false,
    };
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    onChange(form) {
      const {
        contacts: { purpose, user_type, department, onepass },
        request_type,
        subdivisions,
        description,
        due_from,
        due_to,
      } = form;

      this.form = {
        ...this.form,
        request_type,
        purpose,
        subdivisions,
        description,
        user_type,
        department,
        onepass,
        due_from,
        due_to,
      };
    },

    parseContacts() {
      const newLineRegex = /[\n\r]/g;
      const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
      const phoneRegex = /^[+]?\d+$/;
      const nameRegex =
        /^[a-zA-ZА-Яа-яЁё]+(([',. -][a-zA-ZА-Яа-яЁё ])?[a-zA-ZА-Яа-яЁё]*)*$/;

      const lines = this.contacts.split(newLineRegex);

      this.contactsParsed = lines
        .map((line) => {
          const contact = {};

          const splittedLine = line
            .replace(/\s*[,]\s*/, ";")
            .replace(/\s*[;]\s*/, ";")
            .replace(/\s*[\r\t]\s*/g, ";")
            .split(";");

          const fullName = splittedLine.find((value) => nameRegex.test(value));

          if (!fullName) {
            return undefined;
          }

          contact.full_name = fullName;
          contact.email = splittedLine.find((value) => emailRegex.test(value));
          contact.phone = splittedLine.find((value) => phoneRegex.test(value));

          return contact;
        })
        .filter((contact) => contact);

      this.isParsedContacts = true;
      this.form = {
        ...this.form,
        contacts: this.contactsParsed,
      };
    },

    add() {
      const validate = this.$refs.addForm.$refs.form.validate();
      const validateSubdivisions = this.$refs.addForm.validateSubdivisions();
      const validateDepartments = this.$refs.addForm.validateDepartments();

      if (validate && validateSubdivisions && validateDepartments) {
        const { form } = this;

        this.isPending = true;
        this.isSuccess = false;
        this.isFailure = false;

        this.axios
          .post("requests/multi", form)
          .then(() => {
            this.isImporting = true;
            this.isPending = false;
            this.isSuccess = true;
            this.isFailure = false;

            switch (form.contacts.user_type) {
              case "guest":
                localStorage.setItem(
                  "requestGuestDepartment",
                  form.contacts.department
                );
                localStorage.setItem(
                  "requestGuestSubdivisions",
                  JSON.stringify(form.subdivisions)
                );
                localStorage.setItem(
                  "requestGuestDescription",
                  form.description
                );
                break;

              case "employee":
                localStorage.setItem(
                  "requestEmployeeDepartment",
                  form.contacts.department
                );
                localStorage.setItem(
                  "requestEmployeeSubdivisions",
                  JSON.stringify(form.subdivisions)
                );
                localStorage.setItem(
                  "requestEmployeeDescription",
                  form.description
                );
                break;

              default:
                break;
            }
          })
          .catch((error) => {
            this.isPending = false;
            this.isSuccess = false;
            this.isFailure = true;
            this.errorMessage = error.response.data.status.message;
          });
      }
    },

    correct() {
      this.isShowImportErrorDialog = false;
      this.isPending = false;
      this.isSuccess = false;
      this.isFailure = false;
      this.isImporting = false;
      this.isParsedContacts = false;
      this.importProgress = 0;

      const contactsErrorList = this.importErrors.map((error) => {
        const contact = this.contactsParsed.find(
          (contact) => contact.full_name === error.full_name
        );

        let result = contact.full_name;

        if (contact.phone) {
          result += `;${contact.phone}`;
        }

        if (contact.email) {
          result += `;${contact.email}`;
        }

        return result;
      });

      this.contactsParsed = [];
      this.importErrors = [];
      this.contacts = contactsErrorList.join("\n");
    },
  },

  created() {
    this.$root.$on("request_error", (errors) => {
      this.importErrors.push(errors);
    });
    this.$root.$on("request_progress", ({ proc }) => {
      this.importProgress = proc;
    });
  },

  destroyed() {
    this.$root.$off("request_error");
    this.$root.$off("request_progress");
  },
};
</script>
