<template>
  <div>
    <ZoomImg v-show="isOpenZoomImg" :url="zoomImgUrl" top right />

    <v-row>
      <v-col cols="auto">
        <EventsFilter
          :minDate="firstItemCreatedAt"
          @change="onChangeFilter"
          :isDisabled="isPending"
        />
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-btn color="primary" @click="isAllEventsOpen = true">{{
          $ml.get("advanced.allEvents")
        }}</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <DataTable
          :fields="fields"
          :rows="users"
          :dblclick="showUser"
          :rowstyle="styleRow"
          :searchfn="onSearch"
          :searchParams="searchParams"
          :itemCount="limit"
          isChangeItemCount
          saveSearchHistory="events"
          @changeItemCount="onChangeItemCount"
          @sort="onSort"
          :searchDisabled="isPending"
          :isLoading="isPending"
        >
          {{ $ml.get("users.no_passes_registered") }}
          <template v-slot:footer>
            <div class="row">
              <div class="col-sm-10">
                <vue-pagination
                  :pagination="pagination"
                  :offset="2"
                  @paginate="getUsers"
                  :isDisabled="isPending"
                />
              </div>
            </div>
          </template>

          <template #[`item.pass_type`]="{ item }">
            <v-tooltip v-if="item.data.type === 'before'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  :color="item.blacklisted ? 'white' : ''"
                >
                  mdi-check
                </v-icon>
              </template>
              <span>{{ $ml.get("users.recognized") }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.data.type === 'pass'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  :color="item.blacklisted ? 'white' : ''"
                >
                  mdi-check-all
                </v-icon>
              </template>
              <span>{{ $ml.get("users.recognizedAndPass") }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.data.type === 'alarm'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  :color="item.blacklisted ? 'white' : ''"
                >
                  mdi-account-remove-outline
                </v-icon>
              </template>
              <span>{{ $ml.get("users.notRecognized") }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.data.type === 'error'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  :color="item.blacklisted ? 'white' : ''"
                >
                  mdi-alert-circle-outline
                </v-icon>
              </template>
              <span>{{ $ml.get("users.error") }}</span>
            </v-tooltip>
          </template>

          <template #[`item.meta`]="{ item }">
            <span>
              <v-tooltip v-if="item?.event?.escort_id" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small color="primary"
                    >mdi-account-supervisor-circle-outline</v-icon
                  >
                </template>
                <span>
                  {{ $ml.get("user.escort") }} <br />
                  {{ item.event.escort_name }}
                  <br v-if="item.event.escort_name" />
                  {{ $ml.get(`users.${item.event.escort_identifier}`) }}
                </span>
              </v-tooltip>
            </span>
          </template>

          <template #[`item.identity_types`]="{ item }">
            <span v-if="item.purpose">
              <v-tooltip
                v-if="item.purpose === 'entrance' || item.purpose === 'duplex'"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small color="success"
                    >mdi-location-enter</v-icon
                  >
                </template>
                <span>{{ $ml.get("device.purpose_entrance") }}</span>
              </v-tooltip>
              <v-tooltip v-if="item.purpose === 'camera'" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mirror-x mr-1"
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="primary"
                    >mdi-cctv</v-icon
                  >
                </template>
                <span>{{ $ml.get("device.purpose_camera") }}</span>
              </v-tooltip>
              <v-tooltip v-if="item.purpose === 'exit'" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="error"
                    class="rotate-180"
                    >mdi-location-exit</v-icon
                  >
                </template>
                <span>{{ $ml.get("device.purpose_exit") }}</span>
              </v-tooltip>
              <v-tooltip v-if="item.purpose === 'basiclock'" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small color="warning"
                    >mdi-key</v-icon
                  >
                </template>
                <span>{{ $ml.get("device.purpose_basicLock") }}</span>
              </v-tooltip>
            </span>

            <template v-if="item.data.identity_types">
              <span
                v-for="type in item.data.identity_types.split(',')"
                :key="type"
              >
                <v-tooltip v-if="type === 'face'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      :color="item.blacklisted ? 'white' : ''"
                    >
                      mdi-emoticon-happy-outline
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.face") }}</span>
                </v-tooltip>

                <v-tooltip v-if="type === 'qr'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      :color="item.blacklisted ? 'white' : ''"
                    >
                      mdi-qrcode-scan
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.qr") }}</span>
                </v-tooltip>

                <v-tooltip v-if="type === 'card'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      :color="item.blacklisted ? 'white' : ''"
                    >
                      mdi-card-bulleted-outline
                    </v-icon>
                  </template>
                  <span>{{ $ml.get("users.card") }}</span>
                </v-tooltip>
              </span>
            </template>
          </template>

          <template #[`item.avatar`]="{ item }">
            <Avatar
              v-if="item.photo"
              :src="`/avatar/small/${item.photo}`"
              :type="item.type_user"
              downloadable
              :downloadName="getAvatarName(item)"
              @mouseenter="openZoomImg(`/avatar/profile/${item.photo}`)"
              @mouseleave="closeZoomImg"
            />
            <Avatar
              v-else-if="item.data.image_encode"
              :src="`data:image/png;base64,${item.data.image_encode}`"
              :type="item.type_user"
              downloadable
              :downloadName="getAvatarName(item)"
              @mouseenter="
                openZoomImg(`data:image/png;base64,${item.data.image_encode}`)
              "
              @mouseleave="closeZoomImg"
            />
            <Avatar
              v-else-if="parseInt(item.data.code) === 350"
              :src="require('@/assets/img/press-btn.svg')"
              :type="item.type_user"
            />
            <Avatar
              v-else-if="parseInt(item.data.code) >= 301"
              :src="require('@/assets/img/error.svg')"
              :type="item.type_user"
            />
            <Avatar
              v-else
              src="/avatar/small/avatar.png"
              :type="item.type_user"
            />
          </template>

          <template #[`item.full_name`]="{ item }">
            <div v-if="item.data.code === '350'">
              {{ $ml.get("devices.btnPress") }}
            </div>
            <div v-else>
              {{ item.data.name || $ml.get("users.unrecognized") }}
            </div>
            <small> {{ item.data.zone }} / {{ item.data.device_name }} </small>
          </template>

          <template #[`item.message`]="{ item }">
            {{ item.data.message }}
          </template>

          <template #[`item.identity_value`]="{ item }">
            <template v-if="item.data.identity_value">
              <v-icon small>{{
                getIdentityValueIconName(item.data.identity_value)
              }}</v-icon>
              {{ getIdentityValue(item.data.identity_value) }}
            </template>
          </template>

          <template #[`item.created_at`]="{ item }">
            <div class="text-right">
              {{ item.created_at | moment("DD.MM.YY") }}
              <br />
              {{ item.created_at | moment("HH:mm:ss") }}
            </div>
          </template>
        </DataTable>
      </v-col>
    </v-row>

    <ModalAllEventsSettings
      v-if="isAllEventsOpen"
      :loading="isAllEventsPending"
      @close="isAllEventsOpen = false"
      @submit="allEventsReport"
    />
  </div>
</template>

<script>
import ModalAllEventsSettings from "./ModalAllEventsSettings";
import DataTable from "@/components/datatable";
import VuePagination from "@/components/pagination";
import Avatar from "@/components/Avatar";
import ZoomImg from "@/components/ZoomImg";
import EventsFilter from "./EventsFilter";

const LS_EVENTS_LIMIT = "eventsLimit";

export default {
  components: {
    ModalAllEventsSettings,
    DataTable,
    VuePagination,
    Avatar,
    EventsFilter,
    ZoomImg,
  },

  data() {
    const { page } = this.$route.query;
    const limit = localStorage.getItem(LS_EVENTS_LIMIT) || 10;

    return {
      isOpenZoomImg: false,
      zoomImgUrl: "",
      isPending: false,

      fields: [
        {
          name: "meta",
          style: "width: 16px",
        },
        {
          name: "pass_type",
          style: "width: 16px",
        },
        {
          name: "identity_types",
          style: "width: 16px",
        },
        {
          name: "avatar",
          style: "width: 50px;",
          header: { style: "width: 91px" },
        },
        {
          name: "full_name",
          header: {
            text: this.$ml.get("users.visitor"),
          },
          sortBy: {
            name: "full_name",
          },
        },
        {
          name: "message",
          header: {
            text: this.$ml.get("advanced.code"),
          },
        },
        {
          name: "identity_value",
          header: {
            text: this.$ml.get("advanced.identityValue"),
          },
        },
        {
          name: "created_at",
          header: { text: this.$ml.get("users.visit_time") },
          style: "width: 50px;",
          sortBy: {
            name: "created_at",
          },
        },
      ],

      limit: parseInt(limit),
      users: [],
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 10,
        current_page: page,
      },
      firstItemCreatedAt: null,

      isAllEventsOpen: false,
      isAllEventsPending: false,

      searchParams: [
        {
          value: "name",
          text: this.$ml.get("user.name"),
        },
        {
          value: "identety_value",
          text: this.$ml.get("advanced.identityValue"),
        },
        {
          value: "zone",
          text: this.$ml.get("advanced.zone"),
        },
      ],
      search: "",
      filter: {},

      // Sort
      sortName: "",
      sortOrder: "",
    };
  },

  methods: {
    getAvatarName(item) {
      const name =
        item.user_id != 0
          ? `avatar-${this.$moment(item.created_at).format(
              "YYYY_MM_DD-HH_mm_ss"
            )}.jpg`
          : `avatar-${this.$moment(item.created_at).format(
              "YYYY_MM_DD-HH_mm_ss"
            )}.png`;
      return name;
    },
    openZoomImg(img) {
      this.isOpenZoomImg = true;
      this.zoomImgUrl = img;
    },

    closeZoomImg() {
      this.isOpenZoomImg = false;
      this.zoomImgUrl = "";
    },

    allEventsOpenSettings() {
      this.isAllEventsOpen = true;
    },

    allEventsReport(list) {
      this.isAllEventsPending = true;

      const params = {
        search: this.search,
        ...this.filter,
      };

      const data = {
        header: list
          .filter((e) => e.checked)
          .map((e) => {
            const result = {
              title: e.name,
              field: e.value,
              width: e.width,
            };

            if (e.type) {
              result.type = e.type;
            }

            return result;
          }),
      };

      const requestParams = {
        method: "post",
        url: "report/events",
        responseType: "blob",
        data,
        params,
      };

      this.axios
        .request(requestParams)
        .then((response) => {
          const filename = `events-${this.$moment().format(
            "YYYYMMDDHHmmss"
          )}.xlsx`;

          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/xlsx",
            })
          );

          const link = document.createElement("a");

          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          this.$dialog
            .alert(this.$ml.get("advanced.nopass"), {
              loader: false,
              okText: "OK",
              backdropClose: true,
            })
            .then(function (dialog) {});
        })
        .finally(() => {
          this.isAllEventsPending = false;
          this.isAllEventsOpen = false;
        });
    },

    showUser(item) {
      if (item.user_id) {
        let routeData = this.$router.resolve({
          name: "showUser",
          params: { id: item.user_id },
        });
        window.open(routeData.href, "_blank");
      }
    },

    styleRow(item) {
      if (item.blacklisted) {
        return "background: #272D3D; color: #fff;";
      } else if (item.temperatureValue >= this.tAlarm) {
        return "background: #ff9996; color: #000;";
      }
    },

    onChangeItemCount(value) {
      this.limit = value;
      localStorage.setItem(LS_EVENTS_LIMIT, value);
      this.pagination.current_page = 1;
      this.getUsers();
    },

    getUsers() {
      this.isPending = true;
      const params = {
        page: this.pagination.current_page,
        temp: false,
        limit: this.limit,
        ...this.filter,
      };

      if (this.search) {
        params.search = this.search;
      }

      if (this.sortName) {
        params.sortName = this.sortName;
        params.sortOrder = this.sortOrder || "asc";
      }

      this.axios
        .get("alarmvisits", { params })
        .then((response) => {
          const data = response.data.data ? response.data.data : [];
          this.pagination = response.data.meta.pagination || {};
          this.firstItemCreatedAt = response.data.meta.FirstItemCreatedAt;
          this.users = data.map((item) => ({
            ...item,
            data: item.event,
          }));
          this.isPending = false;
        })
        .catch(() => (this.isPending = false));
    },

    onSearch(value) {
      this.search = value;
      this.pagination.current_page = 1;
      this.getUsers();
    },

    onChangeFilter(data) {
      this.filter = data;
      this.pagination.current_page = 1;
      this.getUsers();
    },

    getIdentityValueIconName(identityValue) {
      const separator = identityValue.indexOf(":");
      const type = identityValue.substring(0, separator);

      switch (type) {
        case "qr":
          return "mdi-qrcode-scan";
        case "card":
          return "mdi-card-bulleted-outline";
        default:
          break;
      }
    },

    getIdentityValue(identityValue) {
      const separator = identityValue.indexOf(":");
      return identityValue.substring(identityValue.length, separator + 1);
    },

    onSort({ name, order }) {
      this.sortName = name;
      this.sortOrder = order;

      this.getUsers();
    },
  },

  created() {
    this.getUsers();
  },
};
</script>
