var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"radial-progress",style:({
    width: `${_vm.size}px`,
    height: `${_vm.size}px`,
    background: `conic-gradient(#38c172 ${_vm.progress}%, 0, white ${
      100 - _vm.progress
    }%)`,
  })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }