import Vue from "vue";
export default {
  getLink({ commit }, id) {
    commit("setLoading", { type: "IS_PENDING" });

    return Vue.axios
      .get(`user/${id}/generate_bio_invite`)
      .then((response) => {
        commit("set", { data: response.data.data });
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text:
            error?.response?.data?.status?.message ||
            Vue.$ml.get("message.errorLoading"),
        });
        throw error;
      });
  },
  sendOnTerminal({ commit, rootState }, text) {
    commit("setLoadingSendOnTerminal", { type: "IS_PENDING" });

    return Vue.axios
      .get(`${rootState.settings.data.qr_terminal}/qr?text=${text}`)
      .then(() => {
        commit("setLoadingSendOnTerminal", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingSendOnTerminal", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        throw error;
      });
  },
  terminalOff({ commit, rootState }) {
    commit("setLoadingTerminalOff", { type: "IS_PENDING" });

    return Vue.axios
      .get(`${rootState.settings.data.qr_terminal}/off`)
      .then(() => {
        commit("setLoadingTerminalOff", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingTerminalOff", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        throw error;
      });
  },
  sendSms({ commit }, { id, params }) {
    commit("setLoadingSendSms", { type: "IS_PENDING" });

    return Vue.axios
      .post(`user/${id}/invite`, params)
      .then(() => {
        commit("setLoadingSendSms", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingSendSms", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text:
            error?.response?.data?.status?.message ||
            Vue.$ml.get("message.errorLoading"),
        });
        throw error;
      });
  },
  updatePerson({ commit }, { id, params }) {
    commit("setLoadingUpdatePerson", { type: "IS_PENDING" });

    return Vue.axios
      .post(`user/${id}`, params)
      .then(() => {
        commit("setLoadingUpdatePerson", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingUpdatePerson", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status.message || "",
        });
        throw error;
      });
  },
};
