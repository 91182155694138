export default {
  set(state, payload) {
    state.data = payload;
  },

  setLoading(state, payload) {
    state.isPending = payload.type === "IS_PENDING";
    state.isSuccess = payload.type === "IS_SUCCESS";
    state.isFailure = payload.type === "IS_FAILURE";
    state.errorMessage = payload.payload;
  },

  setLoadingSendOnTerminal(state, payload) {
    state.isPendingSendOnTerminal = payload.type === "IS_PENDING";
    state.isSuccessSendOnTerminal = payload.type === "IS_SUCCESS";
    state.isFailureSendOnTerminal = payload.type === "IS_FAILURE";
    state.errorMessageSendOnTerminal = payload.payload;
  },

  setLoadingTerminalOff(state, payload) {
    state.isPendingTerminalOff = payload.type === "IS_PENDING";
    state.isSuccessTerminalOff = payload.type === "IS_SUCCESS";
    state.isFailureTerminalOff = payload.type === "IS_FAILURE";
    state.errorMessageTerminalOff = payload.payload;
  },

  setLoadingSendSms(state, payload) {
    state.isPendingSendSms = payload.type === "IS_PENDING";
    state.isSuccessSendSms = payload.type === "IS_SUCCESS";
    state.isFailureSendSms = payload.type === "IS_FAILURE";
    state.errorMessageSendSms = payload.payload;
  },

  setLoadingUpdatePerson(state, payload) {
    state.isPendingUpdatePerson = payload.type === "IS_PENDING";
    state.isSuccessUpdatePerson = payload.type === "IS_SUCCESS";
    state.isFailureUpdatePerson = payload.type === "IS_FAILURE";
    state.errorMessageUpdatePerson = payload.payload;
  },
};
