<template>
  <v-app>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="550px" height="400px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $ml.get("subdiv.title") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="search"
                    :label="$ml.get('datatable.search')"
                    outlined
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  />
                </v-col>
                <v-col cols="12" class="tree-box">
                  <v-skeleton-loader
                    v-if="!treeLoaded"
                    class="mx-auto"
                    type="paragraph@2"
                  />
                  <v-treeview
                    v-else
                    v-model="access"
                    :items="accesslist"
                    :search="search"
                    item-disabled="locked"
                    item-text="label"
                    selection-type="independent"
                    selectable
                    hoverable
                    open-all
                  >
                    <template
                      v-if="item.is_guest"
                      slot="append"
                      item-key="id"
                      item-text="name"
                      slot-scope="{ item }"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" class="mr-2" small
                            >mdi-account-clock</v-icon
                          >
                        </template>
                        <span>{{ $ml.get("subdiv.guest") }}</span>
                      </v-tooltip>
                    </template>
                  </v-treeview>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">{{ $ml.get("button.close") }}</v-btn>
            <v-btn
              :disabled="sending"
              v-if="access.length"
              color="primary"
              text
              @click="store"
              >{{ $ml.get("button.save") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>
<script>
export default {
  props: ["value", "action"],
  data() {
    return {
      search: "",
      accesslist: [],
      access: [],
      dialog: true,
      treeLoaded: false,
      sending: false,
    };
  },

  watch: {
    search(val) {
      this.$emit("search", val);
    },
  },

  methods: {
    getAccessList() {
      this.treeLoaded = false;

      this.axios
        .get("/subdivisions/tree")
        .then((response) => {
          this.accesslist = response.data.data ? response.data.data : [];
        })
        .finally(() => {
          this.treeLoaded = true;
        });
    },
    close() {
      this.$emit("toggle", false);
    },
    store() {
      if (!this.access.length || this.sending) return;
      this.sending = true;
      let data = {
        ids: this.value,
        subdivisions: this.access,
        action: this.action,
      };
      this.axios
        .post("/users/multi/sdv", data, {
          header: {
            "Content-Type": "application/json",
          },
          timeout: 20000,
        })
        .then((response) => {
          if (response.status == 200) {
            this.sending = true;
            this.close();
          }
        })
        .catch((err) => {
          this.sending = false;
        });
    },
  },
  created() {
    this.getAccessList();
  },
};
</script>
