<template>
  <v-app>
    <v-card elevation="2" class="settings-page">
      <v-tabs v-model="tab" show-arrows>
        <v-tab>
          {{ $ml.get("setting.GeneralSettings") }}
        </v-tab>
        <v-tab>
          {{ $ml.get("setting.import") }}
        </v-tab>
        <v-tab>
          {{ $ml.get("setting.users") }}
        </v-tab>
        <v-tab>
          {{ $ml.get("setting.api") }}
        </v-tab>
        <v-tab>
          {{ $ml.get("setting.notifications") }}
        </v-tab>
        <v-tab>
          {{ $ml.get("setting.updates") }}
        </v-tab>
        <v-tab>
          {{ $ml.get("setting.log") }}
        </v-tab>
      </v-tabs>

      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item :transition="false" :reverse-transition="false">
            <General v-model="setting" />
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <Import v-model="setting" />
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <Users v-model="setting" />
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <Api v-model="setting" />
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <NotificationsSetting v-model="setting" />
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <UpdateVersion :version="version" />
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <Log />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import General from "./setting/General";
//import Mode from "./setting/Mode";
import Import from "./setting/Import";
import Users from "./setting/Users";
import Api from "./setting/Api";
import NotificationsSetting from "./setting/Notifications";
import UpdateVersion from "./setting/UpdateVersion";
import Log from "./setting/Log";

export default {
  name: "Settings",
  data() {
    return {
      setting: [],
      version: "",
      tab: 0,
      //     v: "general"
    };
  },
  components: {
    General,
    Import,
    Users,
    Api,
    NotificationsSetting,
    UpdateVersion,
    Log,
  },
  methods: {
    loadSettings() {
      //this.lock = [true, true, true];
      this.axios.get("/settings").then((response) => {
        if (response.status == 200) {
          this.setting = {};
          this.version = response.data.version;
          if (this.version == "") this.version = "none";
          if (response.data.data) {
            response.data.data.forEach((item) => {
              this.setting[item.Key] = item.Value;
            });
          }
        }
      });
    },
    // activeBlock(name) {
    //   return this.v == name ? " active" : "";
    // },
    // setTab(tab) {
    //   localStorage.setItem("terminal.users.tabs", tab);
    //   localStorage.removeItem("terminal.users." + tab + ".current_page");
    //   this.users[tab].pagination.current_page = 1;

    //   this.getUsers(tab);
    //   this.v = tab;
    // }
  },
  mounted() {
    this.loadSettings();
  },
};
</script>

<style>
.v-application--wrap {
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: auto;
  max-width: 100%;
  position: relative;
}
</style>
