<template>
  <div
    :class="[
      'zoom-img',
      {
        'zoom-img--top': bottom ? false : top,
        'zoom-img--bottom': bottom,
        'zoom-img--left': right ? false : left,
        'zoom-img--right': right,
      },
    ]"
  >
    <img :src="url" class="zoom-img__img" />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    top: {
      type: Boolean,
      default: true,
    },
    left: {
      type: Boolean,
      default: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
