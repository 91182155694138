<template>
  <v-container>
    <v-app>
      <v-card elevation="2">
        <v-card-text>
          <v-alert v-if="isFailure && errorMessage" type="error">{{
            errorMessage
          }}</v-alert>

          <v-alert v-if="isFailureGet && errorMessageGet" type="error">{{
            errorMessageGet
          }}</v-alert>

          <v-row class="mb-4">
            <v-col>
              <v-btn @click="goBack">{{ $ml.get("button.back") }}</v-btn>
            </v-col>

            <v-spacer />

            <v-col v-if="form" cols="auto">
              <v-row>
                <v-col
                  v-if="
                    ($auth.user().type_user == 'admin' ||
                      $auth.user().roles.includes('reqconfirm')) &&
                    form.status === 0
                  "
                  cols="auto"
                >
                  <v-btn color="success" @click="confirmRequest">
                    {{ $ml.get("requests.confirmRequest") }}</v-btn
                  >
                </v-col>
                <v-col
                  v-if="
                    !form.guest_id &&
                    ($auth.user().type_user == 'admin' ||
                      $auth.user().roles.includes('reqcreateuser')) &&
                    form.status === 1
                  "
                  cols="auto"
                >
                  <v-btn color="success" @click="createUserByRequest">
                    {{ $ml.get("requests.createUserByRequest") }}</v-btn
                  >
                </v-col>
                <v-col
                  v-if="
                    ($auth.user().type_user === 'admin' ||
                      $auth.user().admin_id === form.owner_id ||
                      $auth.user().roles.includes('reqconfirm')) &&
                    form.status !== 3 &&
                    form.status !== 4
                  "
                  cols="auto"
                >
                  <v-btn color="error" @click="cancelRequest">{{
                    $ml.get("requests.cancelRequest")
                  }}</v-btn>
                </v-col>
                <v-col v-if="form.guest_id && form.status !== 4" cols="auto">
                  <v-btn
                    color="primary"
                    link
                    :to="{ name: 'showUser', params: { id: form.guest_id } }"
                    >{{ $ml.get("requests.toUserPage") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <Form
            v-if="form"
            ref="editForm"
            :id="id"
            :name="form.name"
            :description="form.description"
            :email="form.contacts.email"
            :phone="form.contacts.phone"
            :onepass="form.contacts.onepass"
            :onlyByQR="form.contacts.only_by_qr"
            :dueFrom="form.due_from"
            :dueTo="form.due_to"
            :subdivisions="form.subdivisions"
            :status="form.status"
            :requestType="form.request_type"
            :department="form.contacts.department"
            :purpose="form.contacts.purpose"
            :userType="form.contacts.user_type"
            :cards="form.contacts.cards"
            @change="onChange"
          />

          <v-row v-if="form" justify="end">
            <v-col cols="auto">
              <v-btn
                v-if="isResend"
                class="mr-3"
                color="primary"
                @click="resend"
              >
                {{ $ml.get("requests.resend") }}</v-btn
              >
              <v-btn
                v-if="form.identifier && isShowCopyPincode"
                v-clipboard:copy="form.identifier"
                v-clipboard:success="onCopyPincode"
                :color="isCopiedPincode ? 'success' : ''"
              >
                <v-icon v-if="isCopiedPincode" left> mdi-check </v-icon>
                {{ $ml.get("requests.copyPin") }}</v-btn
              >
              <v-btn
                v-if="form.link && isShowCopyUrl"
                class="ml-3"
                v-clipboard:copy="form.link"
                v-clipboard:success="onCopyUrl"
                :color="isCopiedUrl ? 'success' : ''"
              >
                <v-icon v-if="isCopiedUrl" left> mdi-check </v-icon>
                {{ $ml.get("requests.copyUrl") }}</v-btn
              >
              <v-btn
                v-if="form.status === 0"
                color="primary"
                class="ml-3"
                :loading="isPending"
                :disabled="isPending"
                @click="save"
                >{{ $ml.get("button.save") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-app>
  </v-container>
</template>

<script>
import Form from "./form";

export default {
  components: { Form },

  data() {
    return {
      isPendingGet: false,
      isSuccessGet: false,
      isFailureGet: false,
      errorMessageGet: "",

      isPending: false,
      isSuccess: false,
      isFailure: false,
      errorMessage: "",
      id: 0,
      form: null,

      isCopiedPincode: false,
      isCopiedUrl: false,
    };
  },

  watch: {
    $route: "getRequest",
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    onChange(form) {
      this.form = { ...this.form, ...form };
    },

    save(isRedirect = true) {
      const validate = this.$refs.editForm.$refs.form.validate();
      const validateSubdivisions = this.$refs.editForm.validateSubdivisions();
      const validateDepartments = this.$refs.editForm.validateDepartments();

      if (validate && validateSubdivisions && validateDepartments) {
        const { form } = this;

        this.isPending = true;
        this.isSuccess = false;
        this.isFailure = false;
        this.errorMessage = "";

        return this.axios
          .put(`request/${this.id}`, form)
          .then(() => {
            this.isPending = false;
            this.isSuccess = true;
            this.isFailure = false;

            if (isRedirect) {
              this.$router.push({
                name: "requests",
                query: this.$route.params.prevQuery,
              });
            }
          })
          .catch((error) => {
            this.isPending = false;
            this.isSuccess = false;
            this.isFailure = true;
            this.errorMessage = error.response.data.status.message;
          });
      }
    },

    getRequest() {
      this.id = this.$route.params.id;

      this.isPendingGet = true;
      this.isSuccessGet = false;
      this.isFailureGet = false;
      this.errorMessageGet = "";

      this.axios(`request/${this.id}`)
        .then((response) => {
          this.isPendingGet = false;
          this.isSuccessGet = true;
          this.isFailureGet = false;

          this.form = { ...response.data.data };
        })
        .catch((error) => {
          const message = error.response.data.status.message;

          this.isPendingGet = false;
          this.isSuccessGet = false;
          this.isFailureGet = true;
          this.errorMessageGet = message;

          if (message === "no permission read") {
            this.$router.push({
              name: "requests",
              query: this.$route.params.prevQuery,
            });
          }
        });
    },

    onCopyPincode() {
      this.isCopiedPincode = true;
    },

    onCopyUrl() {
      this.isCopiedUrl = true;
    },

    async confirmRequest() {
      await this.save(false);

      this.axios
        .put(`request/${this.id}/status`, {
          status: 1,
        })
        .then(() => {
          this.form.status = 1;

          this.getRequest();
        });
    },

    createUserByRequest() {
      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;
      this.errorMessage = "";

      this.axios
        .post(`request/${this.id}/create`)
        .then(() => {
          this.isPending = false;
          this.isSuccess = true;
          this.isFailure = false;

          this.getRequest();
        })
        .catch((error) => {
          this.isPending = false;
          this.isSuccess = false;
          this.isFailure = true;
          this.errorMessage = error.response.data.status.message;
        });
    },

    cancelRequest() {
      this.axios
        .put(`request/${this.id}/status`, {
          status: 3,
        })
        .then(() => {
          this.form.status = 3;

          this.getRequest();
        });
    },

    resend() {
      this.axios(`request/resend/${this.id}`)
        .then(() => {
          this.$notify({
            group: "info",
            type: "success",
            text: this.$ml.get("requests.resendSuccessMessage"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "info",
            type: "error",
            text: error.response.data.status.message,
          });
        });
    },
  },

  computed: {
    isShowCopyPincode() {
      let isShow = false;

      const requestTypeSplit = String(this.form.request_type)
        .split("")
        .map((num) => {
          return Number(num);
        });

      if (requestTypeSplit.includes(0)) {
        if (this.form.status === 1 || this.form.status === 2) {
          isShow = true;
        } else {
          isShow = false;
        }
      }

      return isShow;
    },

    isShowCopyUrl() {
      let isShow = false;

      const requestTypeSplit = String(this.form.request_type)
        .split("")
        .map((num) => {
          return Number(num);
        });

      if (requestTypeSplit.includes(1)) {
        if (this.form.status === 1 || this.form.status === 2) {
          isShow = true;
        } else {
          isShow = false;
        }
      }

      return isShow;
    },

    isResend() {
      return (
        !this.form.guest_id &&
        this.form.status === 1 &&
        (this.$auth.user().type_user == "admin" ||
          this.$auth.user().roles.includes("requests"))
      );
    },
  },

  created() {
    this.getRequest();
  },
};
</script>
