<template>
  <v-app>
    <transition name="fade">
      <div v-show="show" class="reload-app">
        <v-row align="center">
          <v-col cols="8">
            {{ $ml.get("message.reloadApp") }}
          </v-col>
          <v-col cols="auto">
            <v-btn color="success" small @click="reload">
              {{ $ml.get("button.reload") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </transition>
  </v-app>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    reload() {
      location.reload(true);
    },
  },
};
</script>
